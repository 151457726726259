const BASE_URL = "/user"

export const GetAllocationApi = BASE_URL + "/get-allocation";

export const CreateAllocationApi = BASE_URL + "/create-allocation";

export const EditAllocationApi = BASE_URL + "/edit-allocation";

export const CreateLaunchpadApi = BASE_URL + "/create-launchpad";


export const GetallLaunchpadApi = BASE_URL + "/get-allsale";

export const CreateNativeLaunchpadApi = BASE_URL + "/create-native-launchpad";

