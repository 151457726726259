import React, { useState } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import Sidebar from '../Components/Sidebar'
import Header from '../Components/Header'
import toast, { Toaster } from 'react-hot-toast';

//validations
import { validateProfileEmail,validateProfilePass } from '../Validations/LoginValidate'

//Actions
import { profileSettings } from '../Actions/LoginActions'

function ResetPassword() {

    const [oldPassword,setOldPassword] = useState('')
    const [newPassword,setNewPassword] = useState('')
    const [passwordeye,setPasswordeye] = useState(false)
    const [newpasswordeye,setNewPasswordeye] = useState(false)
    const [errors, setErrors] = useState('')
    const [newEmail,setNewEmail] = useState('')
    
  const handleEmail = async () => {
    let payload = {
      newEmail: newEmail,
    }

    let validate = validateProfileEmail(payload);
    if (validate.isValid) {
      let data = {
        newEmail:newEmail,
        password:newPassword
      }
      var settings = await profileSettings(data);
      console.log("settings", settings)
      if (settings?.data?.data?.status == "false" || !settings?.data?.data?.status) {
        setErrors(settings?.data?.data?.errors )
      } else {
        toast.success(settings?.data?.data?.message)
        console.log("settings_settings", settings);
        // this.props.history.push("/login")
        setErrors('')
        setNewEmail('')
        
      }
    }
    else {

        setErrors(validate?.errors)
    }
  }

  const handlePassword = async () => {
    let payload = {
      oldPassword:oldPassword,
      newPassword:newPassword
    }

    let validate = await validateProfilePass(payload);
    if (validate.isValid) {
      let data = {
        newEmail:newEmail,
        password:newPassword,
        oldPassword:oldPassword,

      }
      var settings = await profileSettings(data);
      console.log("settings", settings)
      if (settings?.data?.data?.status == "false" || !settings?.data?.data?.status) {
        setErrors(settings?.data?.data?.errors)
      } else {
        toast.success(settings?.data?.data?.message)
        console.log("settings_settings", settings);
        setOldPassword('')
        setNewPassword('')
        setErrors('')
        // this.props.history.push("/login")
      }

    }
    else {
        setErrors(validate?.errors)
    }
  }


  return (
    <>
    <Container fluid className='common_bg position-relative'>
    <div className='liner'></div>

    <Row>
        <Col xl={2} lg={0} className='p-0 d-none d-xl-block'>
            <Sidebar/>
        </Col>
        <Col xl={10} lg={12} className='pe-3 ps-lg-4'>
            <Header title={'Change Password'}/>
            <div className='mt-5 profile_holder'>
                <Row>

                <Col lg={7} md={8} sm={10} className='mb-4'>
                    <div className='rp_singleinput_holder'>
                    <p className='rp_label mb-2'>Email Address</p>
                    <div className='rp_input_holder rounded-2 py-2 px-3 d-flex justify-content-start align-items-center gap-2'>
                        <input type={'text'} placeholder='Enter Email Address' className='rp_singleInput flex-grow-1'
                          value={newEmail}
                          onChange={(e) => {
                            setNewEmail(e?.target?.value)
                          }} />

                    </div>
                    <span className="text-danger f-12 d-block text-left">
                                    {errors?.newEmail}
                                  </span>
                </div>
                    </Col>
                    <Col lg={7} md={8} sm={10} className='mb-4'>

                <button className='orange_small_primary' onClick={() => {handleEmail() }}>Submit</button>
                </Col>



                    <Col lg={7} md={8} sm={10} className='mb-4'>
                    <div className='rp_singleinput_holder'>
                    <p className='rp_label mb-2'>Old Password</p>
                    <div className='rp_input_holder rounded-2 py-2 px-3 d-flex justify-content-start align-items-center gap-2'>
                        <input type={!passwordeye ? "password" : "text"} placeholder='Enter Old Password' className='rp_singleInput flex-grow-1'
                         value={oldPassword}
                         onChange={(e) => {
                          setOldPassword(e?.target?.value)
                         }} 
                          />

                        <i class={!passwordeye ? 'fa-solid fa-eye-slash' : "fa-solid fa-eye" } onClick={()=> setPasswordeye(!passwordeye)}/>
                    </div>
                    <span className="text-danger f-12 d-block text-left">
                                    {errors?.oldPassword}
                                  </span>
                </div>
                    </Col>

                    <Col lg={7} md={8} sm={10} className='mb-4'>
                    <div className='rp_singleinput_holder'>
                    <p className='rp_label mb-2'>New Password</p>
                    <div className='rp_input_holder rounded-2 py-2 px-3 d-flex justify-content-start align-items-center gap-2'>
                        <input type={!newpasswordeye ? "password" : "text"} placeholder='Enter New Password' className='rp_singleInput flex-grow-1' 
                        value={newPassword}
                        onChange={(e) => {
                          setNewPassword(e?.target?.value)
                        }}
                        />

                        <i class={!newpasswordeye ? 'fa-solid fa-eye-slash' : "fa-solid fa-eye" } onClick={()=> setNewPasswordeye(!newpasswordeye)}/>
                    </div>
                    <span className="text-danger f-12 d-block text-left">
                                    {errors?.newPassword}
                                  </span>
                </div>
                    </Col>

                  
                </Row>

                <button className='orange_small_primary'  onClick={() => {handlePassword() }}>Submit</button>

                
                
            </div>
        </Col>
    </Row>

    </Container>
    </>
  )
}

export default ResetPassword