import React, { useEffect, useState } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import Header from '../Components/Header'
import Sidebar from '../Components/Sidebar'
import toast, { Toaster } from 'react-hot-toast';

//hooks
import { GetAdminfee, SetDeploymentfee } from '../hooks/useContract'


function LaunchpadSettings() {

    const [deployfee, setDeployfee] = useState(0)
    const [loading, setLoading] = useState(false)


    useEffect(() => {
        getfee()
    }, [])


    const getfee = async () => {
        var fee = await GetAdminfee();
        console.log("fee", fee, parseFloat(fee?.deploymentfee) / 10 ** 18);
        setDeployfee(parseFloat(fee?.deploymentfee) / 10 ** 18);

    }


    return (
        <>
            <Container fluid className='common_bg position-relative'>
                <div className='liner'></div>
                <Row>
                    <Col xl={2} lg={0} className='p-0 d-none d-xl-block'>
                        <Sidebar />
                    </Col>
                    <Col xl={10} lg={12}>
                        <Header title={'Launchpad Settings'} />
                        <div className='mt-5 profile_holder ps-lg-3'>
                            <Row className='align-items-center'>
                                <Col lg={7} className='mb-0 mb-lg-4'>
                                    <div className='rp_singleinput_holder'>
                                        <p className='rp_label mb-2'>Pool Creation Fee</p>
                                        <div className='rp_input_holder rounded-2 py-2 px-3 d-flex justify-content-start align-items-center gap-2'>
                                            <input type='text' placeholder='0' className='rp_singleInput flex-grow-1'
                                                onChange={(e) => {
                                                    var numbers = /^[+]?([0-9]+(?:[\.][0-9]*)?|\.[0-9]+)$/;
                                                    if (!numbers.test(e?.target?.value) && e?.target?.value !== "") {
                                                        return false
                                                    }
                                                    setDeployfee(e?.target?.value)
                                                }} value={deployfee} />

                                        </div>
                                    </div>
                                </Col>
                                <Col lg={5} className='mb-4'>
                                    <div className='rp_singleinput_holder'>

                                        <button className='orange_small_primary mt-4' onClick={async () => {
                                            setLoading(true)
                                            let setfee = await SetDeploymentfee(deployfee)
                                            if (setfee) {
                                                toast.success('Fee Updated Successfully');
                                                setLoading(false)
                                                getfee()
                                            } else {
                                                toast.error('Try again');
                                                setLoading(false)
                                            }
                                        }}>{loading ? 'Updating...' : 'Update'}</button>

                                    </div>
                                </Col>
                            </Row>
                            {/* <Row className='align-items-center'>
                    <Col lg={7} className='mb-0 mb-lg-4'>
                    <div className='rp_singleinput_holder'>
                    <p className='rp_label mb-2'>Liquidity Token Creation Fee</p>
                    <div className='rp_input_holder rounded-2 py-2 px-3 d-flex justify-content-start align-items-center gap-2'>
                        <input type='text' placeholder='0.01' className='rp_singleInput flex-grow-1' />

                    </div>
                </div>
                    </Col>
                    <Col lg={5} className='mb-4'>
                    <div className='rp_singleinput_holder'>
                   
                    <button className='orange_small_primary mt-4'>Update</button>
                   
                </div>
                    </Col>
                    </Row> */}

                            {/* <Row className='align-items-center'>
                    <Col lg={7} className='mb-0 mb-lg-4'>
                    <div className='rp_singleinput_holder'>
                    <p className='rp_label mb-2'>Token Creation Fee</p>
                    <div className='rp_input_holder rounded-2 py-2 px-3 d-flex justify-content-start align-items-center gap-2'>
                        <input type='text' placeholder='0' className='rp_singleInput flex-grow-1' />

                    </div>
                </div>
                    </Col>
                    <Col lg={5} className='mb-4'>
                    <div className='rp_singleinput_holder'>
                   
                    <button className='orange_small_primary mt-4'>Update</button>
                   
                </div>
                    </Col>
                    </Row> */}

                            {/* <Row className='align-items-center'>
                    <Col lg={7} className='mb-0 mb-lg-4'>
                    <div className='rp_singleinput_holder'>
                    <p className='rp_label mb-2'>Airdrop Creation Fee</p>
                    <div className='rp_input_holder rounded-2 py-2 px-3 d-flex justify-content-start align-items-center gap-2'>
                        <input type='text' placeholder='0' className='rp_singleInput flex-grow-1' />

                    </div>
                </div>
                    </Col>
                    <Col lg={5} className='mb-4'>
                    <div className='rp_singleinput_holder'>
                   
                    <button className='orange_small_primary mt-4'>Update</button>
                   
                </div>
                    </Col>
                    </Row> */}
                        </div>
                    </Col>
                </Row>
            </Container>
        </>
    )
}

export default LaunchpadSettings