import React,{useState,useEffect} from "react";
import {Modal} from 'react-bootstrap';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import { FaCopy } from "react-icons/fa";
import toast, { Toaster } from 'react-hot-toast';
import { iconTheme, position, style } from "../hooks/useToast";
import { GetRegisteredUsers } from "../hooks/useContract";


function Interestmodal(props) {

    const[intmodal,setIntmodal] = useState(true);
    const [interestusers , setInterestusers] = useState({});
    useEffect(() => {
      fetchdata();
    } , [])
    const fetchdata = async() => {
      const data = await GetRegisteredUsers(props?.interest?.saleAddress);
      setInterestusers(data);
    }

    return (
      <>
   <Modal centered className='cmn_modal'
        show={intmodal}
        backdrop="static"
        keyboard={false}
      >
   <Modal.Body>
            <div className='cmn_modal_header d-flex justify-content-between align-items-center'>
                <p className='cmn_modal_title mb-2'>Interest User</p>
                <button className='cmn_modal_closer rounded-5' onClick={()=>props.onDismiss()}><i class="fa-solid fa-xmark"/></button>
            </div>

            {interestusers && interestusers?.length > 0 ? interestusers?.map((data , i) => 
          <div onClick = {() => toast.success("Copied", {
            position:position.position,
            style:style,
            iconTheme: iconTheme,
        })}>
   
          <CopyToClipboard text={data} >
<div className="d-flex align-items-center justify-content-between mb-3 cursor"><p className="mb-0 whtclr">{data}</p><FaCopy className="copyimg"/></div>
</CopyToClipboard>
          </div>


          )           : <div className="py-5"> 
          <p className="whtclr text-center mb-0">No Data Found</p>
      </div>}

            

            

            </Modal.Body>

      </Modal>

      </>

)
}

export default Interestmodal;