import React, { useState } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import Sidebar from '../Components/Sidebar'
import Header from '../Components/Header'
import ReactDatatable from '@ashvin27/react-datatable';
import OpenTicket from '../Modals/OpenTicket';

function SupportTicket() {
    // edit modal
    const [showView, setShowView] = useState(false);

  const handleCloseView = () => setShowView(false);
  const handleShowView = () => setShowView(true);

    const state = {
        records: [
          {
            'title': <p className='exchange_table_values text-center'>1</p>,
            "description": <p className='exchange_table_values text-start'>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed</p>,
            "from": <p className='exchange_table_values text-center'>demo@gmail.com</p>,
            "time": <div className='d-flex justify-content-center align-items-center gap-2'>
                <img src={require('../assets/images/clock.svg').default} className='img-fluid' />
                <p className='exchange_table_values text-center'>23 Days</p></div>,
            "commentCount": <p className='exchange_table_values text-center'>0</p>,
    
          },
          {
            'title': <p className='exchange_table_values text-center'>2</p>,
            "description": <p className='exchange_table_values text-start'>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed </p>,
            "from": <p className='exchange_table_values text-center'>demo@gmail.com</p>,
            "time": <div className='d-flex justify-content-center align-items-center gap-2'>
                <img src={require('../assets/images/clock.svg').default} className='img-fluid' />
                <p className='exchange_table_values text-center'>23 Days</p></div>,
            "commentCount": <p className='exchange_table_values text-center'>0</p>,
    
          },
         
        ]
    }
    
    const columns = [
      
        {
            key: "title",
            text: "Title",
            className: "title",
            align: "center",
            sortable: false,
        },
        {
            key: "description",
            text: "Description",
            className: "description",
            align: "center",
            sortable: false,
        },
        {
            key: "from",
            text: "From",
            className: "from",
            align: "center",
            sortable: false,
            
        },
        {
            key: "time",
            text: "Time",
            className: "time",
            align: "center",
            sortable: false,
            
        },
        {
            key: "commentCount",
            text: "Comment Count",
            className: "commentCount",
            align: "center",
            sortable: false,
            
        },
       {
            key: "action",
            text: "Action",
            className: "activity",
            align: "center",
            sortable: false,
            cell : (style) => {
                return (
                    <p className='exchange_activity text-center'><i class="fa-solid fa-eye" onClick={handleShowView} /></p>
                )
            }
        },
    ];
    
    const config = {
        page_size: 10,
        length_menu: [10, 20, 50],
        filename: "Emailtemplates",
        no_data_text: "No Email Templates found!",
        language: {
            length_menu: "Show _MENU_ result per page",
            filter: "Filter in records...",
            info: "Showing _START_ to _END_ of _TOTAL_ records",
            pagination: {
                first: "First",
                previous: "Previous",
                next: "Next",
                last: "Last",
            },
        },
        show_length_menu: false,
        show_filter: false,
        show_pagination: false,
        show_info: false,
    };
    
    const extraButtons =[
        {
            className:"btn btn-primary buttons-pdf",
            title:"Export TEst",
            children:[
                <span>
                <i className="glyphicon glyphicon-print fa fa-print" aria-hidden="true"></i>
                </span>
            ],
            onClick:(event)=>{
                console.log(event);
            },
        },
        {
            className:"btn btn-primary buttons-pdf",
            title:"Export TEst",
            children:[
                <span>
                <i className="glyphicon glyphicon-print fa fa-print" aria-hidden="true"></i>
                </span>
            ],
            onClick:(event)=>{
                console.log(event);
            },
            onDoubleClick:(event)=>{
                console.log("doubleClick")
            }
        },
    ]
  return (
    <>
    <Container fluid className='common_bg position-relative'>
    <div className='liner'></div>
    <Row>
        <Col xl={2} lg={0} className='p-0 d-none d-xl-block'>
            <Sidebar/>
        </Col>
        <Col  xl={10} lg={12}>
            <Header title={'Support Ticket'}/>
            <div className='common_page_scroller mt-5 pe-2'>
            <div className='exchange_table_holder dashboard_box rounded-3'>
<ReactDatatable
                    config={config}
                    records={state.records}
                    columns={columns}
                    extraButtons={extraButtons}
                />
</div>
                </div>
        </Col>
    </Row>

    </Container>

    {/* modals */}
    <OpenTicket show={showView} handleClose={handleCloseView} />
    {/* end of modals */}
    </>
  )
}

export default SupportTicket