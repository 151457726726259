import React, { useEffect, useState } from 'react';
import { Col, Container, Row, InputGroup, FormControl, Form, Button } from 'react-bootstrap';
import Header from '../Components/Header';
import Sidebar from '../Components/Sidebar';
import { Approvetoken, GetAdminfee, UseTokenInfo, createPresale } from '../hooks/useContract';
import toast, { Toaster } from 'react-hot-toast';
import { useHistory } from "react-router-dom";
import DatePicker from "react-datepicker";
import { Edittokenrequesthooks } from '../hooks/Usebackendaxios';



function Tokenreqedit(props) {

    const history = useHistory();
    const [startDate, setStartDate] = useState(new Date());
    const [initialdata , setInitialdata] = useState({});
    // const navigate = useNavigage()

    useEffect(() => {
        fetchdata();
    } , [])

    const fetchdata = () => {
        let record = props?.location?.state;
        if(record) {
            setInitialdata(record);
        }
    }

    const HandleChange = (e) => {
        try{
        //   let init = initialdata;
        //   init[e?.target?.name] = e?.target?.value
        setInitialdata({...initialdata , [e?.target?.name] : e?.target?.value})
        //   let validate = ValidateIdverification(init , e?.target?.name , false);
        //   console.log("validate" , validate);
        //   setError(validate);
        }
        catch(e){
          console.log("Error on handlechange" , e);
        }
      }


    const handleSubmit = async() => {
        if(initialdata?.website && initialdata?.starttime && initialdata?.endtime && initialdata?.hardcap && initialdata?.softcap
            && initialdata?.lockingdays && initialdata?.totalsellingammount&& initialdata?.sellingamountpublicusers && initialdata?.sellingamountwhitelistusers&& initialdata?.explorelaunchpad 
            && initialdata?.vestingperioddays && initialdata?.rewardsvestingperiod && initialdata?.logourl && initialdata?.bannerurl && initialdata?.Twitter && initialdata?.Telegram 
            && initialdata?.Instagram && initialdata?.Facebook && initialdata?.Github && initialdata?.Discord && initialdata?.Description){
                let result = await Edittokenrequesthooks(initialdata);
                console.log("Result" , result);
                if(result?.data?.type == "Success"){
                    history.push("/tokenreqlist")
                }
            }
            else{
                toast.error("Fill all the fields!");
            }
    }

    return (

        <>

            <Container fluid className='common_bg position-relative'>
                <div className='liner'></div>
                <Row>
                    <Col xl={2} lg={0} className='p-0 d-none d-xl-block'>
                        <Sidebar />
                    </Col>
                    <Col xl={10} lg={12}>
                        <Header title={'Token request Edit'} />
                        <div className='mt-5 profile_holder ps-lg-3'>
                            <div className="px-1 containers detail_page">
                                <div className="row mt-5">
                                    <div className="col-12">
                                        <Button className='orange_small_primary mb-4' onClick={() => history.goBack()}> <span>Back</span> </Button>

                                        <div className="card_bg card  mb-4">
                                            <div className="card-body token_list tokenreq_list">

                                                <div className='row'>

                                                    <div className='col-md-6 col-lg-6 col-xl-6 mt-4'>
                                                        <div className='rp_singleinput_holder'>
                                                            <p className='rp_label mb-2'>IDO or/and incubation</p>
                                                            <div className='rp_input_holder rounded-2 py-2 px-3 d-flex justify-content-start align-items-center gap-2'>
                                                                <input type="text" placeholder='IDO or/and incubation' className='rp_singleInput flex-grow-1' 
                                                                value={initialdata?.incubation}
                                                                readOnly = {true}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='col-md-6 col-lg-6 col-xl-6 mt-4'>
                                                        <div className='rp_singleinput_holder'>
                                                            <p className='rp_label mb-2'>Token CreateSale Address</p>
                                                            <div className='rp_input_holder rounded-2 py-2 px-3 d-flex justify-content-start align-items-center gap-2'>
                                                                <input type="text" placeholder='Token CreateSale Address' className='rp_singleInput flex-grow-1'
                                                                value={initialdata?.tokencontractaddress}
                                                                readOnly = {true}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='col-md-6 col-lg-6 col-xl-6 mt-4'>
                                                        <div className='rp_singleinput_holder'>
                                                            <p className='rp_label mb-2'>Token Name</p>
                                                            <div className='rp_input_holder rounded-2 py-2 px-3 d-flex justify-content-start align-items-center gap-2'>
                                                                <input type="text" placeholder='Token Name' className='rp_singleInput flex-grow-1' 
                                                                value={initialdata?.tokenname}
                                                                readOnly = {true}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className='col-md-6 col-lg-6 col-xl-6 mt-4'>
                                                        <div className='rp_singleinput_holder'>
                                                            <p className='rp_label mb-2'>Token Symbol</p>
                                                            <div className='rp_input_holder rounded-2 py-2 px-3 d-flex justify-content-start align-items-center gap-2'>
                                                                <input type="text" placeholder='Token Symbol' className='rp_singleInput flex-grow-1' 
                                                                value={initialdata?.tokensymbol}
                                                                readOnly = {true}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className='col-md-6 col-lg-6 col-xl-6 mt-4'>
                                                        <div className='rp_singleinput_holder'>
                                                            <p className='rp_label mb-2'>Token Decimal</p>
                                                            <div className='rp_input_holder rounded-2 py-2 px-3 d-flex justify-content-start align-items-center gap-2'>
                                                                <input type="text" placeholder='Token Decimal' className='rp_singleInput flex-grow-1' 
                                                                value={initialdata?.tokendecimal}
                                                                readOnly = {true}/>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className='col-md-6 col-lg-6 col-xl-6 mt-4'>
                                                        <div className='rp_singleinput_holder'>
                                                            <p className='rp_label mb-2'>Start Date</p>                                                       
                                                            <DatePicker className='rp_input_holder date_h ps-3' selected={initialdata ?.starttime && new Date(initialdata?.starttime)} 
                                                            onChange={(date) => setInitialdata({...initialdata , ["starttime"] : date})} />
                                                        </div>
                                                    </div>
                                                    <div className='col-md-6 col-lg-6 col-xl-6 mt-4'>
                                                        <div className='rp_singleinput_holder'>
                                                            <p className='rp_label mb-2'>End Date</p>                                                       
                                                            <DatePicker className='rp_input_holder date_h ps-3' selected={initialdata ?.endtime  && new Date(initialdata?.endtime)} 
                                                            onChange={(date) => setInitialdata({...initialdata , ["endtime"] : date})} />
                                                        </div>
                                                    </div>
                                                    <div className='col-md-6 col-lg-6 col-xl-6 mt-4'>
                                                    <div className='rp_singleinput_holder'>
                                                            <p className='rp_label mb-2'>Hardcap </p>
                                                            <div className='rp_input_holder rounded-2 py-2 px-3 d-flex justify-content-start align-items-center gap-2'>
                                                                <input type="text" placeholder='Hardcap' className='rp_singleInput flex-grow-1' 
                                                                value={initialdata?.hardcap}
                                                                name = "hardcap"
                                                                onChange={HandleChange}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='col-md-6 col-lg-6 col-xl-6 mt-4'>
                                                    <div className='rp_singleinput_holder'>
                                                            <p className='rp_label mb-2'>Softcap  </p>
                                                            <div className='rp_input_holder rounded-2 py-2 px-3 d-flex justify-content-start align-items-center gap-2'>
                                                                <input type="text" placeholder='Softcap ' className='rp_singleInput flex-grow-1' 
                                                                value={initialdata?.softcap}
                                                                name = "softcap"
                                                                onChange={HandleChange}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='col-md-6 col-lg-6 col-xl-6 mt-4'>
                                                    <div className='rp_singleinput_holder'>
                                                            <p className='rp_label mb-2'>Locking Days  </p>
                                                            <div className='rp_input_holder rounded-2 py-2 px-3 d-flex justify-content-start align-items-center gap-2'>
                                                                <input type="text" placeholder='Locking Days ' className='rp_singleInput flex-grow-1'
                                                                value={initialdata?.lockingdays}
                                                                name = "lockingdays"
                                                                onChange={HandleChange}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='col-md-6 col-lg-6 col-xl-6 mt-4'>
                                                    <div className='rp_singleinput_holder'>
                                                            <p className='rp_label mb-2'>Total selling amount  </p>
                                                            <div className='rp_input_holder rounded-2 py-2 px-3 d-flex justify-content-start align-items-center gap-2'>
                                                                <input type="text" placeholder='Total selling amount ' className='rp_singleInput flex-grow-1' 
                                                                value={initialdata?.totalsellingammount}
                                                                name = "totalsellingammount"
                                                                onChange={HandleChange}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='col-md-6 col-lg-6 col-xl-6 mt-4'>
                                                    <div className='rp_singleinput_holder'>
                                                            <p className='rp_label mb-2'>Selling amount public users  </p>
                                                            <div className='rp_input_holder rounded-2 py-2 px-3 d-flex justify-content-start align-items-center gap-2'>
                                                                <input type="text" placeholder='Selling amount public users ' className='rp_singleInput flex-grow-1'
                                                                value={initialdata?.sellingamountpublicusers}
                                                                name = "sellingamountpublicusers"
                                                                onChange={HandleChange}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='col-md-6 col-lg-6 col-xl-6 mt-4'>
                                                    <div className='rp_singleinput_holder'>
                                                            <p className='rp_label mb-2'>Selling amount whitelist users  </p>
                                                            <div className='rp_input_holder rounded-2 py-2 px-3 d-flex justify-content-start align-items-center gap-2'>
                                                                <input type="text" placeholder='Selling amount whitelist users ' className='rp_singleInput flex-grow-1'
                                                                value={initialdata?.sellingamountwhitelistusers}
                                                                name = "sellingamountwhitelistusers"
                                                                onChange={HandleChange}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='col-md-6 col-lg-6 col-xl-6 mt-4'>
                                                    <div className='rp_singleinput_holder'>
                                                            <p className='rp_label mb-2'>Explore launchpad  </p>
                                                            <div className='rp_input_holder rounded-2 py-2 px-3 d-flex justify-content-start align-items-center gap-2'>
                                                                <input type="text" placeholder='Explore launchpad ' className='rp_singleInput flex-grow-1' 
                                                                value={initialdata?.explorelaunchpad}
                                                                name = "explorelaunchpad"
                                                                onChange={HandleChange}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='col-md-6 col-lg-6 col-xl-6 mt-4'>
                                                    <div className='rp_singleinput_holder'>
                                                            <p className='rp_label mb-2'>Vesting period days  </p>
                                                            <div className='rp_input_holder rounded-2 py-2 px-3 d-flex justify-content-start align-items-center gap-2'>
                                                                <input type="text" placeholder='Vesting period days ' className='rp_singleInput flex-grow-1' 
                                                                value={initialdata?.vestingperioddays}
                                                                name = "vestingperioddays"
                                                                onChange={HandleChange}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='col-md-6 col-lg-6 col-xl-6 mt-4'>
                                                    <div className='rp_singleinput_holder'>
                                                            <p className='rp_label mb-2'>Rewards vesting period  </p>
                                                            <div className='rp_input_holder rounded-2 py-2 px-3 d-flex justify-content-start align-items-center gap-2'>
                                                                <input type="text" placeholder='Rewards vesting period ' className='rp_singleInput flex-grow-1' 
                                                                value={initialdata?.rewardsvestingperiod}
                                                                name = "rewardsvestingperiod"
                                                                onChange={HandleChange}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='col-md-6 col-lg-6 col-xl-6 mt-4'>
                                                    <div className='rp_singleinput_holder'>
                                                            <p className='rp_label mb-2'>Logourl</p>
                                                            <div className='rp_input_holder rounded-2 py-2 px-3 d-flex justify-content-start align-items-center gap-2'>
                                                                <input type="text" placeholder='Logourl  ' className='rp_singleInput flex-grow-1' 
                                                                value={initialdata?.logourl}
                                                                name = "logourl"
                                                                onChange={HandleChange}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='col-md-6 col-lg-6 col-xl-6 mt-4'>
                                                    <div className='rp_singleinput_holder'>
                                                            <p className='rp_label mb-2'>Bannerurl </p>
                                                            <div className='rp_input_holder rounded-2 py-2 px-3 d-flex justify-content-start align-items-center gap-2'>
                                                                <input type="text" placeholder='Bannerurl   ' className='rp_singleInput flex-grow-1'
                                                                value={initialdata?.bannerurl}
                                                                name = "bannerurl"
                                                                onChange={HandleChange}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='col-md-6 col-lg-6 col-xl-6 mt-4'>
                                                    <div className='rp_singleinput_holder'>
                                                            <p className='rp_label mb-2'>Website </p>
                                                            <div className='rp_input_holder rounded-2 py-2 px-3 d-flex justify-content-start align-items-center gap-2'>
                                                                <input type="text" placeholder='Website' className='rp_singleInput flex-grow-1' 
                                                                value={initialdata?.website}
                                                                name = "website"
                                                                onChange={HandleChange}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='col-md-6 col-lg-6 col-xl-6 mt-4'>
                                                    <div className='rp_singleinput_holder'>
                                                            <p className='rp_label mb-2'>Twitter </p>
                                                            <div className='rp_input_holder rounded-2 py-2 px-3 d-flex justify-content-start align-items-center gap-2'>
                                                                <input type="text" placeholder='Twitter' className='rp_singleInput flex-grow-1' 
                                                                value={initialdata?.Twitter}
                                                                name = "Twitter"
                                                                onChange={HandleChange}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='col-md-6 col-lg-6 col-xl-6 mt-4'>
                                                    <div className='rp_singleinput_holder'>
                                                            <p className='rp_label mb-2'>Telegram </p>
                                                            <div className='rp_input_holder rounded-2 py-2 px-3 d-flex justify-content-start align-items-center gap-2'>
                                                                <input type="text" placeholder='Telegram' className='rp_singleInput flex-grow-1' 
                                                                value={initialdata?.Telegram}
                                                                name = "Telegram"
                                                                onChange={HandleChange}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='col-md-6 col-lg-6 col-xl-6 mt-4'>
                                                    <div className='rp_singleinput_holder'>
                                                            <p className='rp_label mb-2'>Instagram</p>
                                                            <div className='rp_input_holder rounded-2 py-2 px-3 d-flex justify-content-start align-items-center gap-2'>
                                                                <input type="text" placeholder='Instagram' className='rp_singleInput flex-grow-1' 
                                                                value={initialdata?.Instagram}
                                                                name = "Instagram"
                                                                onChange={HandleChange}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='col-md-6 col-lg-6 col-xl-6 mt-4'>
                                                    <div className='rp_singleinput_holder'>
                                                            <p className='rp_label mb-2'>Facebook</p>
                                                            <div className='rp_input_holder rounded-2 py-2 px-3 d-flex justify-content-start align-items-center gap-2'>
                                                                <input type="text" placeholder='Facebook' className='rp_singleInput flex-grow-1' 
                                                                value={initialdata?.Facebook}
                                                                name = "Facebook"
                                                                onChange={HandleChange}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='col-md-6 col-lg-6 col-xl-6 mt-4'>
                                                    <div className='rp_singleinput_holder'>
                                                            <p className='rp_label mb-2'>Github</p>
                                                            <div className='rp_input_holder rounded-2 py-2 px-3 d-flex justify-content-start align-items-center gap-2'>
                                                                <input type="text" placeholder='Github' className='rp_singleInput flex-grow-1' 
                                                                value={initialdata?.Github}
                                                                name = "Github"
                                                                onChange={HandleChange}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='col-md-6 col-lg-6 col-xl-6 mt-4'>
                                                    <div className='rp_singleinput_holder'>
                                                            <p className='rp_label mb-2'>Discord </p>
                                                            <div className='rp_input_holder rounded-2 py-2 px-3 d-flex justify-content-start align-items-center gap-2'>
                                                                <input type="text" placeholder='Discord' className='rp_singleInput flex-grow-1' 
                                                                value={initialdata?.Discord}
                                                                name = "Discord"
                                                                onChange={HandleChange}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='col-md-6 col-lg-6 col-xl-6 mt-4'>
                                                    <div className='rp_singleinput_holder'>
                                                            <p className='rp_label mb-2'>Reddit </p>
                                                            <div className='rp_input_holder rounded-2 py-2 px-3 d-flex justify-content-start align-items-center gap-2'>
                                                                <input type="text" placeholder='Reddit' className='rp_singleInput flex-grow-1' 
                                                                value={initialdata?.Reddit}
                                                                name = "Reddit"
                                                                onChange={HandleChange}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='col-md-6 col-lg-6 col-xl-6 mt-4'>
                                                    <div className='rp_singleinput_holder'>
                                                            <p className='rp_label mb-2'>youtube</p>
                                                            <div className='rp_input_holder rounded-2 py-2 px-3 d-flex justify-content-start align-items-center gap-2'>
                                                                <input type="text" placeholder='youtube' className='rp_singleInput flex-grow-1' 
                                                                value={initialdata?.youtube}
                                                                name = "youtube"
                                                                onChange={HandleChange}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='col-md-6 col-lg-6 col-xl-6 mt-4'>
                                                    <div className='rp_singleinput_holder'>
                                                            <p className='rp_label mb-2'>Description</p>
                                                            <div className='rp_input_holder rounded-2 py-2 px-3 d-flex justify-content-start align-items-center gap-2'>
                                                                <input type="text" placeholder='Description' className='rp_singleInput flex-grow-1' 
                                                                value={initialdata?.Description}
                                                                name = "Description"
                                                                onChange={HandleChange}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>


                                                <div className='text-center mt-5'>
                                                <Button className='orange_small_primary mb-4' onClick={handleSubmit}> <span>Submit</span> </Button>
                                                </div>

                                            </div>
                                        </div>


                                    </div>
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>




        </>

    )
}

export default Tokenreqedit;