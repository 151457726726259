import React from 'react'
import {Modal} from 'react-bootstrap';

function EditProfile({show,handleClose}) {
  return (
    <>
    <Modal centered className='cmn_modal'
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        
        <Modal.Body>
            <div className='cmn_modal_header d-flex justify-content-between align-items-center'>
                <p className='cmn_modal_title'>Edit List</p>
                <button className='cmn_modal_closer rounded-5' onClick={handleClose}><i class="fa-solid fa-xmark"/></button>
            </div>

            <div className='mt-4'>
            <div className='rp_singleinput_holder mb-3'>
                    <p className='rp_label mb-2'>Name</p>
                    <div className='rp_input_holder rounded-2 py-2 px-3 d-flex justify-content-start align-items-center gap-2'>
                        <input type='text' placeholder='Name' className='rp_singleInput flex-grow-1' />
                    </div>
                </div>

                <div className='rp_singleinput_holder mb-3'>
                    <p className='rp_label mb-2'>Email</p>
                    <div className='rp_input_holder rounded-2 py-2 px-3 d-flex justify-content-start align-items-center gap-2'>
                        <input type='email' placeholder='Email' className='rp_singleInput flex-grow-1' />
                    </div>
                </div>

                <button className='orange_small_primary' onClick={handleClose}>Save</button>
            </div>
         
        </Modal.Body>
       
      </Modal>
    </>
  )
}

export default EditProfile