import React, { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap';
import toast, { Toaster } from 'react-hot-toast';

//lib
import { getWalletAddress } from '../Lib/localStorage';

//hooks
import { IsValidAddress, addWhitelistMembers } from '../hooks/useContract'

//validations
import { addwhitelistvalidation } from '../Validations/LaunchpadValidate'

function WhitelistModal({ show, handleClose, saleAddress }) {

  const [count, setCount] = useState(1);

  const [whitelistModal, setWhitelistModal] = useState(true)
  const [isPending, setIsPending] = useState(false)
  const [errors, setErrors] = useState([])
  const [inputList, setInputList] = useState([{ user: "", bnbvalue: "" }])



  const handleInputChange = async (e, index) => {
    const { name, value } = e.target;
    const list = [...inputList];
    list[index][name] = value;
    console.log(list, "sdf");
    if (name == "user" && value.length == 42) {
      const valid = await IsValidAddress(value);
      if (!valid) {
        toast.error(`Non Valid Address (${value}) !`,
          // {
          //     style: {
          //     minWidth: '700px',
          //     minHeight: '55px'
          //     }
          // }
        );
      }
    }
  };


  const handleRemoveClick = index => {
    console.log("index", index);
    const list = [...inputList];
    list.splice(index, 1);
    setInputList(list);

  };

  const handleAddUserList = async () => {
    setIsPending(true);
    // setErrors([])
    let Errors = []
    for (var i = 0; i < inputList.length; i++) {
      var val = inputList[i]
      let valid = await addwhitelistvalidation(val);
      console.log("valid", valid, errors, valid.errors);
      if (!valid.isValid) {
        Errors = [...Errors, valid.errors]
        setErrors(Errors)
      }
    }
    if (Errors.length == 0) {
      console.log("empty", errors.length);
      setErrors([])
      await addWhitelistMembers(inputList, saleAddress, getWalletAddress(), "add");
      setIsPending(false);
      handleClose()
    }

  }

  // handle click event of the Add button
  const handleAddClick = () => {
    setInputList([...inputList, { user: "", bnbvalue: "" }]);
  };



  return (
    <>
      <Modal centered className='cmn_modal'
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >

        <Modal.Body>
          <div className='cmn_modal_header d-flex justify-content-between align-items-center'>
            <p className='cmn_modal_title'>Add Whitelist Users</p>
            <button className='cmn_modal_closer rounded-5' onClick={handleClose}><i class="fa-solid fa-xmark" /></button>
          </div>

          <div className='mt-4'>

            {[...Array(count)].map((val, i) =>
              <>
                <div className='rp_singleinput_holder tetxarea_heigth mb-3'>
                  <p className='rp_label mb-2'>Wallet Address</p>
                  <div className='rp_input_holder rounded-2 py-2 px-3 d-flex justify-content-start align-items-center gap-2'>
                    <input type='text' placeholder='Wallet Address' name='user' className='rp_singleInput flex-grow-1'
                      onChange={(e) => { handleInputChange(e, i) }}
                    />
                  </div>
                  <span className="text-danger f-12 d-block text-left">{errors[i]?.user}</span>
                </div>


                <div className='rp_singleinput_holder tetxarea_heigth mb-3'>
                  <p className='rp_label mb-2'>Allocated BNB Value</p>
                  <div className='rp_input_holder rounded-2 py-2 px-3 d-flex justify-content-start align-items-center gap-2'>
                    <input type='text' placeholder='Allocated BNB Value' name='bnbvalue' className='rp_singleInput flex-grow-1'
                      onChange={(e) => { handleInputChange(e, i) }}
                    />
                  </div>
                  <span className="text-danger f-12 d-block text-left">{errors[i]?.bnbvalue}</span>
                </div>


                <div className='text-end'>
                  {count >= 1 &&
                    <button className='orange_small_primary'
                      onClick={() => {
                        setCount(count + 1)
                        handleAddClick();
                      }}>
                      <i className='fa fa-plus' />
                    </button>}

                  {count > 1 &&
                    <button disabled={count > 1 ? "" : "disabled"} className='orange_small_secondary ms-3'
                      onClick={() => {
                        setCount(count - 1)
                        handleRemoveClick(i);
                      }}
                    >
                      <i className='fa fa-trash' />
                    </button>}
                </div>



              </>
              )}

            <div className='text-center mt-4 pb-3'>
              <button className='orange_small_primary' onClick={() => { handleAddUserList() }}>Submit</button>
            </div>
          </div>


        </Modal.Body>

      </Modal>
    </>
  )
}

export default WhitelistModal