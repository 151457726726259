const BASE_URL = "/admin";

const SETTING_URL = '/settings'

export const loginApi = BASE_URL + "/login"

export const forgetPassword = BASE_URL + "/forgetPassword";

export const resetPassword = BASE_URL + "/resetPassword";

export const profileSettings = BASE_URL + "/profileSettings";

export const sentVerifyLink = BASE_URL + "/sentVerifyLink";

export const verifyNewEmail = BASE_URL + "/verifyNewEmail";

export const getsettingApi = BASE_URL + SETTING_URL + "/getsetting";

export const addsettingApi = BASE_URL + SETTING_URL + "/addsetting";

export const updatesettingApi = BASE_URL + SETTING_URL + "/updatesetting";

export const launchpad = BASE_URL + '/launchpad';

export const addFee = BASE_URL +  SETTING_URL + "/addFee";

export const deleteFee = BASE_URL +  SETTING_URL + "/deleteFee";

export const getFee = BASE_URL +  SETTING_URL + "/getFee";

export const editFee = BASE_URL +  SETTING_URL + "/editFee";
