import React,{useEffect} from 'react'
import {Modal} from 'react-bootstrap';

function DeleteFeeModal({show,handleClose,OnDelete,deleteId}) {
  
  
  useEffect(()=>{
    console.log("OnDelete",OnDelete, deleteId)
},[show])

  return (
    <>
    <Modal centered className='cmn_modal'
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        
        <Modal.Body>
            <div className='cmn_modal_header d-flex justify-content-between align-items-center'>
                <p className='cmn_modal_title'>Delete Fee List</p>
                <button className='cmn_modal_closer rounded-5' onClick={handleClose}><i class="fa-solid fa-xmark"/></button>
            </div>

            <div className='mt-3'>
            
            <p className='dash_graymed_text'>Are you sure want to delete the fee list?</p>
                <button className='orange_small_primary mt-5' onClick={()=>{OnDelete(deleteId)}}>Done</button>
            </div>
         
        </Modal.Body>
       
      </Modal>
    </>
  )
}

export default DeleteFeeModal