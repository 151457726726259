import { isEmpty } from "../Lib/isEmpty";
import {IsValidAddress} from '../hooks/useContract'

export const urlvalidation = (data) => {
    try {
        const regex = new RegExp(/[(http(s)?):\/\/(www\.)?a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/);
        console.log("valid url ", regex.test(data));
        return regex.test(data);
    }
    catch (e) {
        console.log("urlvalidation", e);
    }
}



export const addwhitelistvalidation = async (val) => {
    try {
        let isValid = true;
        // var errors ={} ;
        let ob = {}
        console.log("val", val);
        // data.map(async(val , i)=>{
        if (isEmpty(val.user)) {
            isValid = false;
            ob.user = "Wallet Address field is required !";
        }
        else {
            let isvalid = await IsValidAddress(val.user);
            console.log("isvalid", isvalid);
            if (!isvalid) {
                isValid = false
                ob.user = "Invalid wallet address !"
            }
        }

        if (isNaN(parseFloat(val.bnbvalue)) || val.bnbvalue <= 0) {
            isValid = false
            ob.bnbvalue = "Invalid BNB value !"
        }
        
        console.log("length", ob);
        return {
            isValid: isEmpty(ob),
            errors: ob
        }
    }
    catch (e) {
        console.log("addwhitelistvalidation", e);
    }
}

export const removewhitelistvalidation = async (val) => {
    try {
        let isValid = true;
        let ob = {}
        console.log("val", val);

        if (isEmpty(val.user)) {
            isValid = false;
            ob.user = "Wallet Address field is required !";
        }
        else {
            let isvalid = await IsValidAddress(val.user);
            console.log("isvalid", isvalid);
            if (!isvalid) {
                isValid = false
                ob.user = "Invalid wallet address !"
            }
        }

        console.log("length", ob);
        return {
            isValid: isEmpty(ob),
            errors: ob
        }
    }
    catch (e) {
        console.log("removewhitelistvalidation", e);
    }
}