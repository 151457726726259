import {isEmpty} from './Lib/isEmpty'
import React from "react";
import { Route, Redirect } from "react-router-dom";
import { getAuthToken } from './Lib/localStorage';

// import isLogin from '../src/components/lib/isLogin';


const ConditionRoute = ({ component: Component, layout: Layout, auth, type, ...rest }) => {
    console.log(Component, Layout, "Component")
    return (

        <Route
            {...rest}
            render={props => {
                
                // console.log("ConditionRoute", type, type == 'auth' && isLogin() == true, type == 'private' && isLogin() != true)
                let auth = getAuthToken()
                console.log(Component, Layout,auth, "Component")
                if (type == 'auth' && auth) {
                    return <Redirect to="/launchpadlist" />
                } else if (type == 'private' && isEmpty(auth)) {
                    return <Redirect to="/login" />
                }

                return <Component {...props} />
            }}
        />
    )

};

export default ConditionRoute;