import axios from "axios";
import { CreateLaunchpadApi, CreateNativeLaunchpadApi, EditAllocationApi, GetAllocationApi, GetallLaunchpadApi } from "../Routes/Userroute";
import { CreateAllocationApi } from "../Routes/Userroute";
import { API_URL } from "../Config/env";
import { edittokenadminApi } from "../Routes/cmsRoutes";

export const GetAllocationHooks = async() => {
    try{console.log();
        let result = await axios.get(API_URL+GetAllocationApi);
        console.log("result" , result);
        return result;
    }
    catch(e){
        console.log("Error on get allocation" , e , API_URL+GetAllocationApi);
    }
}

export const CreateAllocationHooks = async(data) => {
    try{
        
        let result = await axios.post(API_URL + CreateAllocationApi , data , {headers: {
            'Content-Type': 'multipart/form-data',
           
        }});
        return result;
    }
    catch(e){
        console.log("Error on create allocation Hooks" , e);
    }
}

export const EditAllocationHooks = async(data) => {
    try{
        let result = await axios.post(API_URL + EditAllocationApi , data , {headers: {
            'Content-Type': 'multipart/form-data',
        }});
        return result;
    }
    catch(e){
        console.log("Error on edit allocation" , e);
    }
}

export const CreateLaunchpadHooks = async(data) => {
    try{
        let result = await axios.post(API_URL + CreateLaunchpadApi , data );
        return result;
    }
    catch(e){
        console.log("Error on create launchpad hooks" , e);
    }
}


export const Edittokenrequesthooks = async(data) => {
    try{
        
        let result = await axios.post(API_URL + edittokenadminApi , data );
        return result;
    }
    catch(e){
        console.log("Error on edit token request hooks" , e);
    }
}

export const GetallLaunchpadHooks = async(data) => {
    try{console.log();
        let result = await axios.get(API_URL+GetallLaunchpadApi , {params : data});
        console.log("result" , result);
        return result;
    }
    catch(e){
        console.log("Error on get allocation" , e , API_URL+GetallLaunchpadApi);
    }
}

export const CreateNativeLaunchpadHooks = async(data) => {
    try{
        let result = await axios.post(API_URL + CreateNativeLaunchpadApi , data );
        return result;
    }
    catch(e){
        console.log("Error on create launchpad hooks" , e);
    }
}