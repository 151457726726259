import React, { useRef, useState, useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import Sidebar from "../Components/Sidebar";
import Header from "../Components/Header";
import Select from "react-select";
import ReactDatatable from "@ashvin27/react-datatable";

//hooks
import { getTotalSalesInfo, getlaunchpaddatalimit, isSaleLive, isUpcoming, DeletePresale } from '../hooks/useContract'
import { getChainId } from '../hooks/useWeb3'

//lib
import { padToDigits } from '../Lib/DateTimeHelper'
import { getWalletAddress } from "../Lib/localStorage";

//Actions 
import { launchpadhook } from "../Actions/LaunchActions";

import { CHAINS } from '../Config/env'

function Launchpadlist() {


  const state = [
    {
      address: "yu676869669869",
      salestype: "PrivateSale",
      symbol: "Test",
      status: "Sale Ended",
      softcap: "2",
      hardcap: "3",
      percentage: "5%",
      startdate: "09/01/2024 13:00:00",
      enddate: "03/02/2024 13:00:00",
      action: "Enable",
      view: "/launchpadDetail"
    },
    {
      address: "yu676869669869",
      salestype: "PrivateSale",
      symbol: "Test",
      status: "Sale Ended",
      softcap: "4",
      hardcap: "5",
      percentage: "10%",
      startdate: "09/01/2024 13:00:00",
      enddate: "03/02/2024 13:00:00",
      action: "Disable",
      view: "/launchpadDetail"

    },
    {
      address: "yu676869669869",
      salestype: "PrivateSale",
      symbol: "Test",
      status: "Sale Ended",
      softcap: "2",
      hardcap: "3",
      percentage: "5%",
      startdate: "09/01/2024 13:00:00",
      enddate: "03/02/2024 13:00:00",
      action: "Enable",
      view: "/launchpadDetail"

    },

  ];


  // console.log(state.records[0].status.props,"asdfasfasdfasdf");

  const columns = [
    {
      key: "saleAddress",
      text: "Address",
      className: "address",
      align: "center",
      sortable: false,
    },
    // {
    //   key: "isWhitelisted",
    //   text: "Sales Type",
    //   className: "salestype",
    //   align: "center",
    //   sortable: false,
    //   cell: (record) => {
    //     console.log("RECORDS", record)
    //     return (
    //       <p>{record?.isWhitelisted ? "PrivateSale" : record?.LaunchpadType ? "Launchpad" : "Fairlaunch"}</p>
    //     )
    //   }
    // },
    {
      key: "symbol",
      text: "Symbol",
      className: "symbol",
      align: "center",
      sortable: false,
    },
    {
      key: "status",
      text: "Status",
      className: "status",
      align: "center",
      sortable: false,
      cell: (record) => {
        console.log("RECORDS", record)
        return (
          <p>{record && isSaleLive(parseFloat(record.startTime), parseFloat(record.endTime), record.isPresaleOpen) ? 'Live' :
            isUpcoming(parseFloat(record.startTime)) ?
              'Upcoming' :
              'Closed'
          }</p>
        )
      }
    },
    // {
    //   key: "softCap",
    //   text: "Softcap",
    //   className: "softcap",
    //   align: "center",
    //   sortable: false,
    //   cell: (record) => {
    //     console.log("RECORDS", record)
    //     return (
    //       <p>{parseFloat(record?.softCap) / 10 ** 18}</p>
    //     )
    //   }

    // },
    // {
    //   key: "hardcap",
    //   text: "Hardcap",
    //   className: "hardcap",
    //   align: "center",
    //   sortable: false,
    // },
    // {
    //   key: "percentage",
    //   text: "Percentage",
    //   className: "percentage",
    //   align: "center",
    //   sortable: false,
    //   cell: (record) => {
    //     return (
    //       <>
    //         <p>{record && parseInt(parseFloat(record.earnedCap) / parseFloat(record.softCap) * 100)} % </p>
    //         <p>{record && (parseFloat(record.earnedCap) / 10 ** 18).toFixed(3)} / {(parseFloat(record?.softCap) / 10 ** 18).toFixed(2)}</p>
    //       </>
    //     )
    //   }

    // },
    {
      key: "startdate",
      text: "Start Date",
      className: "startdate",
      align: "center",
      sortable: false,
      cell: (record) => {
        console.log("RECORDS", record)
        return (
          <p>{(padToDigits(new Date(parseFloat(record?.startTime) * 1000).toLocaleDateString()) + "  "
            + padToDigits(new Date(parseFloat(record?.startTime) * 1000).getHours()) + ":" + padToDigits(new Date(parseFloat(record?.startTime) * 1000).getMinutes()) + ":"
            + padToDigits(new Date(parseFloat(record?.startTime) * 1000).getSeconds()) + "(GMT)")}</p>
        )
      }

    },
    {
      key: "enddate",
      text: "End Date",
      className: "enddate",
      align: "center",
      sortable: false,
      cell: (record) => {
        console.log("RECORDS", record)
        return (
          <p>{(padToDigits(new Date(parseFloat(record?.endTime) * 1000).toLocaleDateString()) + "  "
            + padToDigits(new Date(parseFloat(record?.endTime) * 1000).getHours()) + ":" + padToDigits(new Date(parseFloat(record?.endTime) * 1000).getMinutes()) + ":"
            + padToDigits(new Date(parseFloat(record?.endTime) * 1000).getSeconds()) + "(GMT)")}</p>
        )
      }

    },
    {
      key: "action",
      text: "Action",
      className: "action",
      align: "center",
      sortable: false,
      cell: (style) => {
        return (
          <div>
            {console.log(style, "style")}
            <button className="table_btn_theme" onClick={() => DeletePresale(style?.saleAddress, getWalletAddress())}>Disable</button>
          </div>
        )
      }
    },
    {
      key: "view",
      text: "View",
      className: "view",
      align: "center",
      sortable: false,
      cell: (style, record) => {
        return (
          <div>
            {console.log(style, "style")}
            <Link to={{ pathname: `/launchpadDetail/${style?.saleAddress}`, state: style }} className="table_btn_theme_inverse">View</Link>
          </div>
        )
      }
    },
  ];

  const config = {
    page_size: 3,
    length_menu: [10, 20, 50],
    filename: "Emailtemplates",

    language: {
      length_menu: "Show _MENU_ result per page",
      filter: "Filter in records...",
      info: "Showing _START_ to _END_ of _TOTAL_ records",
      pagination: {
        first: "First",
        previous: "Previous",
        next: "Next",
        last: "Last",
      },
      // loading:true,
      // no_data_text: loading ? 'Loading...' : "No data found!",
      // loading_text: loading ? 'Loading...': 
    },
    show_length_menu: false,
    show_filter: false,
    show_pagination: true,
    show_info: false,
  };

  const extraButtons = [
    {
      className: "btn btn-primary buttons-pdf",
      title: "Export TEst",
      children: [
        <span>
          <i
            className="glyphicon glyphicon-print fa fa-print"
            aria-hidden="true"
          ></i>
        </span>,
      ],
      onClick: (event) => {
        console.log(event);
      },
    },
    {
      className: "btn btn-primary buttons-pdf",
      title: "Export TEst",
      children: [
        <span>
          <i
            className="glyphicon glyphicon-print fa fa-print"
            aria-hidden="true"
          ></i>
        </span>,
      ],
      onClick: (event) => {
        console.log(event);
      },
      onDoubleClick: (event) => {
        console.log("doubleClick");
      },
    },
  ];



  const [trending, setTrending] = useState([])
  const [proxy, setProxy] = useState([])
  const [original, setOriginal] = useState([])
  const [loadinterval, setLoadinterval] = useState(3)
  const [totalSales, setTotalSales] = useState([])
  const [loading, setLoading] = useState(false)




  useEffect(() => {
    totalSaleInfo()
    // console.log(" CHAINS[getChainId()].CHAIN_ID,", CHAINS[getChainId()].CHAIN_ID,)
    // let payload = {
    //   chain: CHAINS[getChainId()].CHAIN_ID,
    //   walletaddress: getWalletAddress(),
    //   saleaddress: '0xe90d8Eb0352C61dD7F240b5b7d6959afb47D98C3',
    //   whitelist: false,
    // }
    // launchpadhook(payload);
  }, [])

  const totalSaleInfo = async () => {
    setLoading(true)
    console.log('totalSaleInfo')
    const Proxydata = await getTotalSalesInfo();
    console.log("Proxydata", Proxydata);
    // const totalproxydata = Proxydata.filter(data => data._isWhitelisted == true)
    // console.log("totalproxydata", totalproxydata);

    // let trenddata = await gettrendingdisplayhook();
    // console.log("dataa trending", trenddata?.data?.data);
    // if (trenddata?.data?.data?.length > 0)
    // setTrending(trenddata?.data?.data)
    // var trending = trenddata?.data?.data
    // console.log("trendingdataaaaaaaaaaaaaa", trending);

    let newtotalproxy = [];
    Proxydata?.map((val, i) => {
      let singledata = Proxydata?.find(e => e?._sale == val?.saleaddress);
      if (singledata) {
        newtotalproxy.push(singledata)
      }
    })

    Proxydata?.map((datas, i) => {
      let singlesale = newtotalproxy?.find(e => e._sale == datas?._sale);
      if (!singlesale) {
        newtotalproxy.push(datas)
      }
    })


    setProxy(newtotalproxy)
    console.log("trendingdataaaaaaaaaaaaaa", newtotalproxy);
    console.log("total proxy data", Proxydata);
    setOriginal(newtotalproxy)
    const total = await getlaunchpaddatalimit(newtotalproxy, 0, loadinterval);
    console.log("total>>>", total);

    setTotalSales(total);
    setInterval(loadinterval);
    setLoading(false)
  }


  const handlePagination = async (index) => {
    let skip = (index.page_number - 1) * index.page_size
    let loadinterval = (index.page_number) * index.page_size
    const total = await getlaunchpaddatalimit(proxy, skip, loadinterval);
    setTotalSales(total);
    setInterval(loadinterval);
  }




  return (
    <>
      <Container fluid className="common_bg position-relative">
        <div className="liner"></div>
        <Row>
          <Col xl={2} lg={0} className='p-0 d-none d-xl-block'>
            <Sidebar />
          </Col>
          <Col xl={10} lg={12}>
            <Header title={'Launchpad List'} />
            <div className="page_position">
              <div className="common_page_scroller mt-5 pe-2">
                <div className="exchange_table_holder launchpad_table dashboard_box rounded-3 mt-4">
                  <ReactDatatable
                    config={config}
                    records={totalSales}
                    columns={columns}
                    extraButtons={extraButtons}
                    loading={loading ? true : false}
                    onChange={(e) => { handlePagination(e) }}
                    dynamic={true}
                    total_record={proxy?.length ? proxy?.length : 0}
                  />
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default Launchpadlist;
