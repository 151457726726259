const BASE_URL = "/cms";


export const RegisterAccountadd = BASE_URL + "/RegisterAccountadd"
export const RegisterAccountList = BASE_URL + "/RegisterAccountList"
export const RegisterAccounthide = BASE_URL + "/RegisterAccounthide"
export const RegisterAccountedit = BASE_URL + "/RegisterAccountedit"
//
export const cmsdetails = BASE_URL + "/cmsdetails"
export const cmsupdate = BASE_URL + "/cmsupdate"
// sprofeatureAdd
export const sprofeatureAdd = BASE_URL + "/sprofeatureAdd"
export const sprofeatureEdit = BASE_URL + "/sprofeatureEdit"
export const sprofeaturehide = BASE_URL + "/sprofeaturehide"

export const sprofeatureList = BASE_URL + "/sprofeatureList"
// Faqadd
export const Faqadd = BASE_URL + "/Faqadd"

export const Faqtablist = BASE_URL + "/Faqtablist"
export const Faqupdate = BASE_URL + "/Faqupdate"
export const FaqhideList = BASE_URL + "/FaqhideList"
//sociallink

export const addsociallinks = BASE_URL + "/addsociallinks"

export const changesocialstate = BASE_URL + "/changesocialstate"

export const getsociallinks = BASE_URL + "/getsociallinks"
// addtokenrequest
// export const addtokenrequest = BASE_URL + "/addtokenrequest"
// gettokenrequest
export const gettokenrequest = BASE_URL + "/gettokenrequest"


export const tokennrequestApprove = BASE_URL + "/tokennrequestApprove"

export const edittokenadminApi = BASE_URL + "/edit-token";
