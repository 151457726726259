import React, { useState } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import Sidebar from '../Components/Sidebar'
import Header from '../Components/Header'
import toast, { Toaster } from 'react-hot-toast';
import { Link , useHistory} from 'react-router-dom'
import {useNavigate} from 'react-router'
//validations
import { validatelogin } from '../Validations/LoginValidate'

//Actions
import { login } from '../Actions/LoginActions'
import { useEffect } from 'react';

const initialFormValue = {
    email: "",
    password: "",
};


function Login() {

    const history = useHistory()
    const [formValue, setFormValue] = useState({ initialFormValue });

    const { email, password } = formValue;

    const [passwordeye, setPasswordeye] = useState(false)
    const [errors, setErrors] = useState('')

    const showPassword = () => {
        setPasswordeye(!passwordeye)
        // var e = document.getElementsByName("password");
        // console.log('showPassword', e.type)
        // if (e.type === "password") {
        //     console.log('showPassword1')
        //     setPasswordeye(true)
        //     e.type = "text";
        // } else {
        //     console.log('showPassword2')
        //     setPasswordeye(false)
        //     e.type = "password";
        // }
    };

    const handlelogin = async () => {
        let payload = {
            email: email,
            password: password
        }

        let validate = validatelogin(payload);
        if (validate.isValid) {
            let data = {
                email: email,
                password: password,
                //   ipAddress:this.state.ipAddress
            }
            var loged = await login(data);
            console.log("loged", loged)
            if (loged?.data?.data?.success == false || !loged?.data?.data?.success) {
                toast.error(loged?.data?.data?.data)
            } else {
                console.log("loginhook", loged);
                history.push('/launchpadlist')
                // window.location.href = window.location.origin + "/launchpadlist"
            }

        }
        else {
            setErrors(validate?.errors)
        }
    }

    const handleChange = (e) => {
        e.preventDefault();

        const { name, value } = e.target;
        console.log("handleChange", value)
        let error = { ...errors, ...{ [name]: "" } };
        setErrors(error);
        let formData = { ...formValue, ...{ [name]: value } };
        setFormValue(formData);
    };

    useEffect(()=>{
        console.log(localStorage.getItem('auth'),localStorage.getItem('CHAIN'),'useEffect')
    },[])
    return (
        <>
            <Container fluid className='common_bg login_bg position-relative'>
                <div className='logo_sec'>
                    <img src={require('../assets/images/headerlogo.png')} className="img-fluid main_logo" />

                </div>

                <Row className='justify-content-center align-items-center row_card'>

                    <Col xl={12} lg={12} md={12} sm={12}>
                        <div className='dashboard_box p-4'>
                            <div className='profile_holder'>
                                <p className='header_title_big'>Login</p>
                                <hr className='grey_hr' />
                            </div>

                            <div className='profile_holder'>
                                <Row>
                                    <Col lg={12} md={12} sm={12} className='mb-4'>
                                        <div className='rp_singleinput_holder'>
                                            <p className='rp_label mb-2'>Email Address</p>
                                            <div className='rp_input_holder rounded-2 py-2 px-3 d-flex justify-content-start align-items-center gap-2'>
                                                <input type="text" placeholder='Enter Email Address' className='rp_singleInput flex-grow-1'
                                                    name="email" onChange={(e) => { handleChange(e) }} />
                                            </div>
                                            <span className="text-danger f-12 d-block text-left">{errors?.email}</span>

                                        </div>
                                    </Col>

                                    <Col lg={12} md={12} sm={12} className='mb-4'>
                                        <div className='rp_singleinput_holder'>
                                            <p className='rp_label mb-2'>Password</p>
                                            <div className='rp_input_holder rounded-2 py-2 px-3 d-flex justify-content-start align-items-center gap-2'>
                                                <input type={passwordeye ? 'text' : 'password'} placeholder='Enter Password' className='rp_singleInput flex-grow-1'
                                                    name="password" onChange={(e) => { handleChange(e) }} />
                                                {/* <input type={'password'} placeholder='Enter Password' className='rp_singleInput flex-grow-1'
                                                    name="password" onChange={(e) => { handleChange(e) }} /> */}
                                                <i class={!passwordeye ? 'fa-solid fa-eye-slash' : "fa-solid fa-eye"} onClick={showPassword} />
                                            </div>
                                            <span className="text-danger f-12 d-block text-left">{errors?.password}</span>

                                        </div>
                                        <div className='mt-3 text-end'>
                                            <Link to="/forgotpassword" className='link_theme'>Forgot Password?</Link>
                                        </div>
                                    </Col>


                                </Row>
                                <div className='text-center'>
                                    <button className='orange_small_primary' onClick={() => { handlelogin() }}>Submit</button>

                                    {/* <Link to="/launchpadlist" className='orange_small_primary'>Submit</Link> */}

                                </div>

                            </div>
                        </div>
                    </Col>
                </Row>

            </Container>
        </>
    )
}

export default Login