import { STAKING_MASTERCHEF_ABI } from "../Config/abi/stakeAbi";
import { CHAINS } from "../Config/env";
import { getWalletAddress } from "../Lib/localStorage";
import { EditAllocationHooks } from "./Usebackendaxios";
import { GetChainIndex, useWeb3 } from "./useWeb3";

export const UseStakingAddress = async () => {
    try {
        const web3 = await useWeb3();
        const contract = new web3.eth.Contract(STAKING_MASTERCHEF_ABI, getStakingAddress());

        return contract;
    } catch (e) {

    }
}


export const getStakingAddress = () => {
    try {
        const index = GetChainIndex();
        const ContractAddress = CHAINS[index].LOCKEDSTAKING;

        return ContractAddress;
    } catch (e) {

    }
}


export const AddTier = async(data , formdata) => {
    try{console.log("Addtier" , data);
        let stakecontract = await UseStakingAddress();
        let addtier = await stakecontract?.methods?.setTier(data?.pid?.toString() ,(parseFloat(data?.min)*10**18?.toString()) ,( parseFloat(data?.max)*10**18)?.toString() , (parseFloat(data?.percentage)*10**18 )?.toString(), data?.name).send({ from: getWalletAddress() });
        let result = await EditAllocationHooks(formdata);
        if(result?.data?.type == "Success"){
            // navigate.push("/allocationlist");
            window.location.href = window.location.origin + "/allocationlist"
        }
        return result;
    }
    catch(e){
        console.log("Error on add tier" , e);
    }
}