import {isEmpty} from '../Lib/isEmpty';
var numbers = /^[+]?([0-9]+(?:[\.][0-9]*)?|\.[0-9]+)$/;

export const settingvalidate = (data) => {
    try {
        let errors = {};
        let isValid = true;
        if (isEmpty(data?.settingname)) {
            isValid = false;
            errors.settingname = "Setting name field is required";
        }
        if (isEmpty(data?.settingvalue)) {
            isValid = false;
            errors.settingvalue = "Setting Value field is required !";
        }
        return {
            isValid: isEmpty(errors),
            errors: errors
        }
    }
    catch (err) {
        console.log("settingvalidate", err)
    }
}


export const addFeevalidation = (data) => {
    try {
        console.log(data?.currencyvalue==="","data?.currencyvalue==")
        let errors = {};
        let isValid = true;
        if (isEmpty(data?.feeName)) {
            isValid = false;
            errors.feeName = "Fee Name field is required";
        }
        if (data?.currencyvalue==="") {
            isValid = false;
            errors.currencyvalue = "Currency value field is required";
        }
        else if (!numbers.test(data?.currencyvalue)) {
            isValid = false;
            errors.currencyvalue = "Currency value only contains numbers";
        }
        if (data?.tokenvalue==="") {
            isValid = false;
            errors.tokenvalue = "Token value field is required";
        }
        else if (!numbers.test(data?.tokenvalue)) {
            isValid = false;
            errors.tokenvalue = "Token value only contains numbers";
        }
      
        return {
            isValid: isEmpty(errors),
            errors: errors
        }
    }
    catch (err) {
        console.log("addFeevalidation", err)
    }
}

export const editFeevalidation = (data) => {
    try {
        let errors = {};
        let isValid = true;
       
        if (data?.currencyvalue==="") {
            isValid = false;
            errors.currencyvalue = "Currency value field is required";
        }

        else if (!numbers.test(data?.currencyvalue)) {
            isValid = false;
            errors.currencyvalue = "Currency value only contains numbers";
        }

        if (data?.tokenvalue==="") {
            isValid = false;
            errors.tokenvalue = "Token value field is required";
        }
        else if (!numbers.test(data?.tokenvalue)) {
            isValid = false;
            errors.tokenvalue = "Token value only contains numbers";
        }
      
        return {
            isValid: isEmpty(errors),
            errors: errors
        }
    }
    catch (err) {
        console.log("addFeevalidation", err)
    }
}
