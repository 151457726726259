import axios from 'axios';
import { API_URL } from '../Config/env';
import * as cms from "../Routes/cmsRoutes";



export const addRegisteraccount = async (data) => {
    console.log("addIpAddress", data);
    try {

        let respdata = await axios({
          'method': 'POST',
          'url': API_URL + cms.RegisterAccountadd,
          data,
        });
        console.log("response", respdata);
        return {
          data: respdata
        }
   
    }
    catch (e) {
      console.log("error", e);
    }
  }

  export const GetRegisteraccount = async (data) => {
    console.log("addIpAddress", data);
    try {

        let respdata = await axios({
          'method': 'POST',
          'url': API_URL + cms.RegisterAccountList,
          data,
        });
        console.log("response", respdata);
        return {
          data: respdata
        }
   
    }
    catch (e) {
      console.log("error", e);
    }
  }
  

  export const Hideregisteraccount = async (data) => {
    console.log("addIpAddress", data);
    try {

        let respdata = await axios({
          'method': 'POST',
          'url': API_URL + cms.RegisterAccounthide,
          data,
        });
        console.log("response", respdata);
        return {
          data: respdata
        }
   
    }
    catch (e) {
      console.log("error", e);
    }
  }
  
  export const Registeraccountedit = async (data) => {
    console.log("addIpAddress", data);
    try {

        let respdata = await axios({
          'method': 'POST',
          'url': API_URL + cms.RegisterAccountedit,
          data,
        });
        console.log("response", respdata);
        return {
          data: respdata
        }
   
    }
    catch (e) {
      console.log("error", e);
    }
  }
  
  

  export const fetchCmsContent = async (data) => {
    console.log("addIpAddress", data);
    try {

        let respdata = await axios({
          'method': 'get',
          'url': API_URL + cms.cmsdetails,
          "params":data,
        });
        console.log("response", respdata);
        return {
          data: respdata
        }
   
    }
    catch (e) {
      console.log("error", e);
    }
  }


  export const fetcheditdata = async (data) => {
    console.log("addIpAddress", data);
    try {

        let respdata = await axios({
          'method': 'POST',
          'url': API_URL + cms.cmsupdate,
          data,
        });
        console.log("response", respdata);
        return {
          data: respdata
        }
   
    }
    catch (e) {
      console.log("error", e);
    }
  }


  export const sprofeatureAdd = async (data) => {
    console.log("addIpAddress", data);
    try {

        let respdata = await axios({
          'method': 'POST',
          'url': API_URL + cms.sprofeatureAdd,
          data,
        });
        console.log("response", respdata);
        return {
          data: respdata
        }
   
    }
    catch (e) {
      console.log("error", e);
    }
  }

  export const sprofeatureEdit = async (data) => {
    console.log("addIpAddress", data);
    try {

        let respdata = await axios({
          'method': 'POST',
          'url': API_URL + cms.sprofeatureEdit,
          data,
        });
        console.log("response", respdata);
        return {
          data: respdata
        }
   
    }
    catch (e) {
      console.log("error", e);
    }
  }

  export const sprofeaturehide = async (data) => {
    console.log("addIpAddress", data);
    try {

        let respdata = await axios({
          'method': 'POST',
          'url': API_URL + cms.sprofeaturehide,
          data,
        });
        console.log("response", respdata);
        return {
          data: respdata
        }
   
    }
    catch (e) {
      console.log("error", e);
    }
  }

  export const sprofeatureList = async (data) => {
    console.log("addIpAddress", data);
    try {

        let respdata = await axios({
          'method': 'POST',
          'url': API_URL + cms.sprofeatureList,
          data,
        });
        console.log("response", respdata);
        return {
          data: respdata
        }
   
    }
    catch (e) {
      console.log("error", e);
    }
  }

  // Faqadd

  export const Faqadd = async (data) => {
    console.log("addIpAddress", data);
    try {

        let respdata = await axios({
          'method': 'POST',
          'url': API_URL + cms.Faqadd,
          data,
        });
        console.log("response", respdata);
        return {
          data: respdata
        }
   
    }
    catch (e) {
      console.log("error", e);
    }
  }

  export const Faqtablist = async (data) => {
    console.log("addIpAddress", data);
    try {

        let respdata = await axios({
          'method': 'get',
          'url': API_URL + cms.Faqtablist,
          "params":data,
        });
        console.log("response", respdata);
        return {
          data: respdata
        }
   
    }
    catch (e) {
      console.log("error", e);
    }
  }

  
  export const Faqupdate = async (data) => {
    console.log("addIpAddress", data);
    try {

        let respdata = await axios({
          'method': 'POST',
          'url': API_URL + cms.Faqupdate,
          data,
        });
        console.log("response", respdata);
        return {
          data: respdata
        }
   
    }
    catch (e) {
      console.log("error", e);
    }
  }

  export const FaqhideList = async (data) => {
    console.log("addIpAddress", data);
    try {

        let respdata = await axios({
          'method': 'POST',
          'url': API_URL + cms.FaqhideList,
          data,
        });
        console.log("response", respdata);
        return {
          data: respdata
        }
   
    }
    catch (e) {
      console.log("error", e);
    }
  }
  //socaillink
  export const addsociallinks = async (data) => {
    console.log("addsociallinks", data);
    try {

        let respdata = await axios({
          'method': 'POST',
          'url': API_URL + cms.addsociallinks,
          data,
        });
        console.log("response", respdata);
        return {
          data: respdata
        }
   
    }
    catch (e) {
      console.log("error", e);
    }
  }

  export const changesocialstate = async (data) => {
    console.log("addsociallinks", data);
    try {

        let respdata = await axios({
          'method': 'POST',
          'url': API_URL + cms.changesocialstate,
          data,
        });
        console.log("response", respdata);
        return {
          data: respdata
        }
   
    }
    catch (e) {
      console.log("error", e);
    }
  }


  export const getsociallinks = async (data) => {
    console.log("getsociallinks", data);
    try {

        let respdata = await axios({
          'method': 'get',
          'url': API_URL + cms.getsociallinks,
          "params":data,
        });
        console.log("response", respdata);
        return {
          data: respdata
        }
   
    }
    catch (e) {
      console.log("error", e);
    }
  }

  // export const addtokenrequest = async (data) => {
  //   console.log("addtokenrequest", data);
  //   try {

  //       let respdata = await axios({
  //         'method': 'POST',
  //         'url': API_URL + cms.addtokenrequest,
  //         data,
  //       });
  //       console.log("response", respdata);
  //       return {
  //         data: respdata
  //       }
   
  //   }
  //   catch (e) {
  //     console.log("error", e);
  //   }
  // }

  //getTokenrequest
  export const getTokenrequest = async (data) => {
    console.log("addsociallinks", data);
    try {

        let respdata = await axios({
          'method': 'POST',
          'url': API_URL + cms.gettokenrequest,
          data,
        });
        console.log("response", respdata);
        return {
          data: respdata
        }
   
    }
    catch (e) {
      console.log("error", e);
    }
  }
  // tokennrequestApprove
  export const tokennrequestApprove = async (data) => {
    console.log("addsociallinks", data);
    try {

        let respdata = await axios({
          'method': 'POST',
          'url': API_URL + cms.tokennrequestApprove,
          data,
        });
        console.log("response", respdata);
        return {
          data: respdata
        }
   
    }
    catch (e) {
      console.log("error", e);
    }
  }