import React, { useState } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import Header from '../Components/Header'
import Sidebar from '../Components/Sidebar'
import { addsettinghook } from '../Actions/SettingsActions'
import { isEmpty } from '../Lib/isEmpty'
import { settingvalidate } from '../Validations/SettingsValidate'
import { useLocation,useHistory } from 'react-router-dom'
import toast, { Toaster } from 'react-hot-toast';

function AddSettings() {

    const [settingname, setSettingname] = useState('')
    const [settingvalue, setSettingvalue] = useState('')
    const [errors, setErrors] = useState('')


    const location = useLocation()
    console.log("location", location)
    const history = useHistory()

    const handleadd = async () => {
        console.log("handleadd");
        let payload = {
            settingname: settingname,
            settingvalue: settingvalue
        };
        console.log("payload", payload);
        let validate = settingvalidate(payload);
        if (validate.isValid) {
            let settingresult = await addsettinghook(payload);
            console.log("ssetting result", settingresult);
            toast.success(settingresult?.data?.data)

            // this.props.onDismiss();
            // this.props.getset();
            history.push('/settings')
        }
        else {
            setErrors(validate.errors)
        }

    }


    return (
        <>
            <Container fluid className='common_bg position-relative'>
                <div className='liner'></div>
                <Row>
                    <Col xl={2} lg={0} className='p-0 d-none d-xl-block'>
                        <Sidebar />
                    </Col>
                    <Col xl={10} lg={12}>
                        <Header title={'Add Settings'} />
                        <div className='mt-5 profile_holder ps-lg-3'>
                            <Row>
                                <Col lg={7} xl={6} xxl={5} className='mb-4'>
                                    <div className='rp_singleinput_holder'>
                                        <p className='rp_label mb-2'>Settings Name</p>
                                        <div className='rp_input_holder rounded-2 py-2 px-3 d-flex justify-content-start align-items-center gap-2'>
                                            <input type='text' placeholder='Settings Name' className='rp_singleInput flex-grow-1' value={settingname}
                                                onChange={(e) => {
                                                    setSettingname(e?.target?.value)
                                                }} />

                                        </div>
                                        <span className="text-danger f-12 d-block text-left">{errors?.settingname}</span>

                                    </div>
                                </Col>

                                <Col lg={7} xl={6} xxl={5} className='mb-4'>
                                    <div className='rp_singleinput_holder'>
                                        <p className='rp_label mb-2'>Settings Value</p>
                                        <div className='rp_input_holder rounded-2 py-2 px-3 d-flex justify-content-start align-items-center gap-2'>
                                            <input type='text' placeholder='Settings Value' className='rp_singleInput flex-grow-1' value={settingvalue}
                                                onChange={(e) => {
                                                    setSettingvalue(e?.target?.value)
                                                }} />

                                        </div>
                                        <span className="text-danger f-12 d-block text-left">{errors?.settingvalue}</span>

                                    </div>
                                </Col>
                            </Row>
                            <button className='orange_small_primary' onClick={() => { handleadd() }}>Submit</button>
                        </div>
                    </Col>
                </Row>
            </Container>
        </>
    )
}

export default AddSettings