import React, { useState } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import Sidebar from '../../Components/Sidebar'
import Header from '../../Components/Header'
import ReactDatatable from '@ashvin27/react-datatable';
// import EditFeeModal from '../Modals/EditFeeModal';
// import DeleteFeeModal from '../Modals/DeleteFeeModal';
import { Link, NavLink } from 'react-router-dom';
import toast, { Toaster } from 'react-hot-toast';


//Actions
import { getFees, deleteFee } from '../../Actions/FeeSettingsActions';
import { useEffect } from 'react';
import { GetRegisteraccount, Hideregisteraccount, sprofeatureList, sprofeaturehide } from '../../Actions/cms';

function ListSprofeature() {
    // edit modal
    const [showEdit, setShowEdit] = useState(false);

    const handleCloseEdit = () => setShowEdit(false);
    const handleShowEdit = () => setShowEdit(true);

    //   delete modal

    const [showDelete, setShowDelete] = useState(false);

    const [getFee, setGetFee] = useState([])
    console.log("getfeeessss",getFee);
    const [deleteId, setDeleteId] = useState('')
    const [editData, setEditData] = useState({})
    const [hidestatus, setHidestatus] = useState('')

    const handleCloseDelete = () => setShowDelete(false);
    const handleShowDelete = () => setShowDelete(true);


    const columns = [
        {
            key: "step",
            text: "step",
            className: "step",
            align: "center",
            sortable: false,
            cell: records =>
                records?.step
        },
        {
            key: "question",
            text: "question",
            className: "question",
            align: "center",
            sortable: false,
            cell: records =>
                records?.question?.length > 15 ? <>{records?.question.slice(0, 10)}...</> : records?.question
        },
        {
            key: "answer",
            text: "answer",
            className: "answer",
            align: "center",
            sortable: false,
            cell: records => records.answer.length > 50 ? <div dangerouslySetInnerHTML={{ __html: records.answer.slice(0, 45)+'...' }}></div> : <div dangerouslySetInnerHTML={{ __html: records.answer }}></div>
        },

        {
            key: "Edit",
            text: "Edit register",
            className: "NFT IDT",
            align: "left",

            cell: records =>
                <div><Link to={{ pathname: '/editSprofeature', state: records }}><button className='orange_small_primary' type='button'>Edit</button></Link></div>

        },

        {
            key: "Hide/Show",
            text: "Hide/Show",
            className: "NFT IDT",
            align: "left",

            cell: records =>
                <div>{console.log("recereecat", records)}<button className='orange_small_primary' onClick={() => hideShowCategory(records.hideshow == "visible" ? "hidden" : "visible", records._id)}>{records.hideshow == "hidden" ? "SHOW" : "HIDE"}</button></div>

        },
        //    {
        //         key: "action",
        //         text: "Action",
        //         className: "activity",
        //         align: "center",
        //         sortable: false,
        //         cell : (record) => {
        //             return (
        //                <div className='d-flex justify-content-center align-items-center gap-2'>
        //                 <button className='cmn_plain_btn' onClick={()=>{setEditData(record);handleShowEdit()}}><img src={require('../../assets/images/editer.svg').default} 
        //                 className='img-fluid table_activity_img' /> </button>
        //                 <button className='cmn_plain_btn' onClick={()=>{setDeleteId(record._id);handleShowDelete();}}>
        //                     <img src={require('../../assets/images/trash.svg').default} className='img-fluid table_activity_img' /> </button>
        //                </div>
        //             )
        //         }
        //     },
    ];

    const config = {
        page_size: 10,
        length_menu: [10, 20, 50],
        filename: "Emailtemplates",
        no_data_text: "No Email Templates found!",
        language: {
            length_menu: "Show _MENU_ result per page",
            filter: "Filter in records...",
            info: "Showing _START_ to _END_ of _TOTAL_ records",
            pagination: {
                first: "First",
                previous: "Previous",
                next: "Next",
                last: "Last",
            },
        },
        show_length_menu: false,
        show_filter: false,
        show_pagination: false,
        show_info: false,
    };

    const extraButtons = [
        {
            className: "btn btn-primary buttons-pdf",
            title: "Export TEst",
            children: [
                <span>
                    <i className="glyphicon glyphicon-print fa fa-print" aria-hidden="true"></i>
                </span>
            ],
            onClick: (event) => {
                console.log(event);
            },
        },
        {
            className: "btn btn-primary buttons-pdf",
            title: "Export TEst",
            children: [
                <span>
                    <i className="glyphicon glyphicon-print fa fa-print" aria-hidden="true"></i>
                </span>
            ],
            onClick: (event) => {
                console.log(event);
            },
            onDoubleClick: (event) => {
                console.log("doubleClick")
            }
        },
    ]


    useEffect(() => {
        // getFeeSettings()
        getRegisteraccount()
    }, [])



    const getRegisteraccount = async () => {

        let get = await sprofeatureList();
        console.log("getFeespro", get);
        if (get?.data?.data?.status == true) {
            setGetFee(get?.data?.data?.data);
            console.log("getFee_getFee", get?.data?.data?.data)
        }
    }
    const hideShowCategory = async (data, _id) => {
        console.log("datasddtaat", data, _id);
        let payload = { hideshow: data, id: _id }
        let resp = await sprofeaturehide(payload);
        console.log("respresp", resp);
        if (resp?.data?.status) {
            toast.success(resp.data.data.message)
            setTimeout(() => {
                window.location.reload();
            }, 2000);
        }
        else toast.error(resp.data.data.message)

    }

    return (
        <>
            <Container fluid className='common_bg position-relative'>
                <div className='liner'></div>
                <Row>
                    <Col xl={2} lg={0} className='p-0 d-none d-xl-block'>
                        <Sidebar />
                    </Col>
                    <Col xl={10} lg={12}>
                        <Header title={'Fee Settings'} />
                        <div className='setting_tab_holder mt-5 pe-2'>
                            <Row>
                                <Col sm={6} className='d-flex gap-3 mb-3 mb-sm-0'>

                                </Col>
                                <Col sm={6} className='text-end'>
                                    <NavLink to='/addSprofeature' className='sidebar_links'>
                                         { getFee.length < 5 &&
                                        <button className='orange_small_primary'>Add</button>
                                       
                                        }
                                    </NavLink>
                                </Col>
                            </Row>
                        </div>

                        <div className='common_page_scroller mt-4'>
                            <div className='exchange_table_holder dashboard_box rounded-3'>
                                <ReactDatatable
                                    config={config}
                                    records={getFee}
                                    columns={columns}
                                    extraButtons={extraButtons}
                                />
                            </div>
                        </div>
                    </Col>
                </Row>

            </Container>

            {/* modals */}
            {/* 
  {showEdit && <EditFeeModal show={showEdit} handleClose = {()=>{handleCloseEdit()}} getSettings={()=>{getFeeSettings()}} editData={editData}/>}

   <DeleteFeeModal show={showDelete} handleClose = {handleCloseDelete} OnDelete={(deleteId) => { deleteFeeSettings(deleteId) }}  deleteId={deleteId}   /> */}
        </>
    )
}

export default ListSprofeature