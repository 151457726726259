import React, { Component, useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';

import toast, { Toaster } from 'react-hot-toast';
import { useHistory } from "react-router-dom";
import { Col, Container, Row,Button } from 'react-bootstrap'
import Header from '../../Components/Header'
import Sidebar from '../../Components/Sidebar'
import { IMG_URL } from '../../Config/env.js'
import { addsociallinks } from '../../Actions/cms.js';



export function Editsocial(props) {
    console.log("props", props)

    const { state } = props.location;

    const history = useHistory();


    useEffect(() => {
        setFormData(state)
    }, [props])



    const initData = {
        "website": "",
        "link": "",
        "files": "",
        "date": ""
    }

    const [formData, setFormData] = useState(initData)
    console.log("formdata", formData);
    const [status, setStatus] = useState(false)
    const [dataurl, setDataurl] = useState("")




    const handlechange = async (e) => {
        e.preventDefault();
        const { id } = e.target;
        if (id == "file") {
            const reader = new FileReader(e.target.files[0]);
            setDataurl(window.URL.createObjectURL(e.target.files[0]))

            var files = e.target.files[0]
            console.log("files in cms", files, reader)
            let formdata = { ...formData, ...{ "files": files } }
            setFormData(formdata)
            setStatus(true)
        } else {
            const { id, value } = e.target
            console.log("value", value)
            let formdata = { ...formData, ...{ [id]: value } }
            setFormData(formdata)
            console.log("formval", formData)
        }

    }

    const handleSubmit = async () => {
        var errors = {};


        if (!formData.link) {
            errors.link = "website link cannot be empty"
            return toast.error("website link cannot be empty")
        }


        if (!formData.website) {
            errors.website = "website cannot be empty"
            return toast.error("website cannot be empty")
        }

        if (Object.keys(errors).length == 0) {
            if (status) {
                var FormDatas = new FormData()
                FormDatas.append("website", formData?.website)
                FormDatas.append("id", formData._id)
                FormDatas.append("link", formData?.link)
                // FormDatas.append("img", formData?.img)
                FormDatas.append("files", formData.files)
                FormDatas.append("action", "edit")

                var resp = await addsociallinks(FormDatas);
                console.log("sociadata", resp);
                if (resp?.data?.data?.status) {
                    toast.success(resp?.data?.data?.msg)
                    setTimeout(function () {
                        history.push("/listSocial")
                    }, 1000);


                }
                else return toast.error(resp.msg)
            }
            else {
                var payload = {
                    id: formData?._id,
                    website: formData?.website,
                    link: formData?.link,
                    action: "edit"
                }

                console.log("payload", payload)

                var resp = await addsociallinks(payload);
                console.log("sociadataaswdas", resp);

                if (resp?.data?.data?.status) {
                    toast.success("Social link updated successfully")
                    setTimeout(function () {
                        history.push("/listSocial")
                    }, 1000);

                }
                else return toast.error(resp?.data?.data?.msg)

            }
        }
    }






    return (

        <Container fluid className='common_bg position-relative'>
            <div className='liner'></div>
            <Row>
                <Col xl={2} lg={0} className='p-0 d-none d-xl-block'>
                    <Sidebar />
                </Col>
                <Col xxl={7} xl={8} lg={12}>
                    <Header title={'Add Fee'} />
                    <div className='mt-5 profile_holder ps-lg-3'>
            <Button className='orange_small_primary mb-4' onClick={()=>history.goBack()}> <span>Back</span> </Button>

                        <Row>
                            <Col lg={7} className='mb-4'>
                                <div className='rp_singleinput_holder'>
                                    <p className='rp_label mb-2'>Title</p>
                                    <div className='rp_input_holder rounded-2 py-2 px-3 d-flex justify-content-start align-items-center gap-2'>
                                        <input type='text' className='rp_singleInput flex-grow-1'
                                            id="website" placeholder="topic" value={formData.website} onChange={(e) => handlechange(e)} />

                                    </div><br/>
                                    <div className='rp_input_holder rounded-2 py-2 px-3 d-flex justify-content-start align-items-center gap-2'>
                                        <input type='text' className='rp_singleInput flex-grow-1'
                                            id="link" placeholder="content" value={formData.link} onChange={(e) => handlechange(e)} />

                                    </div>
                                </div><br/>
                                {formData?.img && <img className='mb-2' src={status ? window?.URL?.createObjectURL(formData?.files) : `${IMG_URL}/socialimg/${formData?.img}`} height={100} width={100} />}
                                
                                <br />
                                <input type="file" id="file" onChange={(e) => handlechange(e)} />
                            </Col>


                        </Row>
                        <button className='orange_small_primary' onClick={() => handleSubmit()}>Submit</button>
                    </div>
                </Col>
            </Row>
        </Container>
    )

}

export default Editsocial
