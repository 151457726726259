import React, { useRef, useState, useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import Sidebar from "../Components/Sidebar";
import Header from "../Components/Header";
import Select from "react-select";
import ReactDatatable from "@ashvin27/react-datatable";
import { FiArrowUpRight } from "react-icons/fi";
import { useHistory, useParams } from "react-router-dom";
import { CKEditor } from 'ckeditor4-react';
import { fetchCmsContent, fetcheditdata } from "../Actions/cms";
import { useCallback } from "react";
import { isEmpty } from "../common/common";
import toast, { Toaster } from 'react-hot-toast';
import { IMG_URL } from "../Config/env";
function Editcms(props) {
    const history = useHistory();


    const { data } = useParams()
    console.log("data", data)
    const initData = {
        "description": "",
        "heading": "",
        'slug': "",

    }

    const [formData, setFormData] = useState(initData)
    console.log("slugsluformDatag", formData);

    const [heading, setheading] = useState('')
    const [slug, setSlug] = useState("")
    console.log("slugslug", slug);

    const [status, setStatus] = useState(false)
    const [dataurl, setDataurl] = useState("")
    const [description, setdescription] = useState('')
    const [images, setimage] = useState()
    const [errors, seterrors] = useState("")
    const [ValidateError, SetValidateError] = useState('')

    console.log("sdfgdsgdfbgh", images, slug, formData, description);
    console.log("gggghhr4trt", images);
    useEffect(() => {
        FetchCms()
        redirectpage()
    }, [])


    const FetchCms = async () => {
        let Resp = await fetchCmsContent({ data })
        console.log("sdfsdgdddd", Resp.data.data.data);
        if (Resp?.data?.status) {
            setFormData({ ...formData, ...Resp?.data?.data?.data })
            setSlug(Resp?.data?.data?.data?.slug)
            setheading(Resp?.data?.data?.data?.heading)
            setdescription(Resp?.data?.data?.data?.description)
            // setimage(Resp?.data?.image)
        }

    }


    const redirectpage = async () => {
        if (data == "registeraccount") {
            history.push("/listRegisteraccount")
        }
        else if (data == "spropadfeatures") {
            history.push("/listSprofeature")

        }
    }

    const handlechange = async (e, index) => {
        e.preventDefault();
        const { id } = e.target;
        if (id === "file") {
            setStatus(true)
            var files = e.target.files[0]
            var formdata = formData
            formdata.file = files
            console.log("files", files)
            setFormData({ ...formdata })
        }
        else {

            const { id, value } = e.target

            let formdata = { ...formData, ...{ [id]: value } }
            setFormData(formdata)
        }




    }

    console.log(description)
    const onEditorChange = (evt) => {
        const description_text = evt.editor.getData()


        setdescription(description_text)

    }


    const Validation = useCallback(() => {

        let ValidateError = {}

        if (slug == "launch_spropad") {
            if (!formData.heading) {
                ValidateError.heading = "Heading is required";
            }
            if (!formData.answer1) {
                console.log("fdsgdfgdfgdfgdfg");
                ValidateError.answer1 = "answer1 is required";
            }
            if (!formData.answer2) {
                console.log("fdsgdfgdfgdfgdfg");
                ValidateError.answer2 = "answer2 is required";
            }
            if (!formData.answer3) {
                console.log("fdsgdfgdfgdfgdfg");
                ValidateError.answer3 = "answer3 is required";
            }
        }
        else if (slug == "registeraccountimg" || slug == "roadmap") {

            if (!formData.file) {
                console.log("fdsgdfgdfgdfgdfg");
                ValidateError.file = "file is required";
            }
        }
        else {
            if (!formData.heading) {
                ValidateError.heading = "Heading is required";
            }
            if (!description) {
                console.log("fdsgdfgdfgdfgdfg");
                ValidateError.description = "description is required";
            }
        }


        SetValidateError(ValidateError)
        return ValidateError
    })

    const handleSubmit = async () => {
        var errors = {};
        // if (images.length > 4) {
        //     errors.file = "only choose 4 images"
        //     return toast.error("only choose 4 images")
        // }
        seterrors(errors)
        var value = Validation();

        if (!isEmpty(value)) {
            console.log("value", value);
            SetValidateError(value);
            toast.error("fix all validation");
        }
        else {

            if (slug == "launch_spropad") {
                let payload = {

                    heading: formData?.heading,
                    answer1: formData?.answer1,
                    answer2: formData?.answer2,
                    answer3: formData?.answer3,
                    slug: data
                }

                console.log("paylaodsdasdasd", payload)

                const res = await fetcheditdata(payload, true)
                console.log("sdfgsfd", res);
                if (res.data.status) {
                    toast.success(res.data.data.message)
                    setTimeout(() => {
                        history.push("/cmslist")
                    }, 1000);
                }
                else toast.error(res.data.data.message)
            }
            else if (slug == "homepage_box1") {
                const formDatas = new FormData();

                formDatas.append("heading", formData.heading)
                formDatas.append("id", formData._id)

                formDatas.append("description", description);
                formDatas.append("img", formData.file ?? "")
                formDatas.append("slug", data)


                console.log("paylaodsdasdasd", formDatas)

                const res = await fetcheditdata(formDatas, true)
                console.log("sdfgsfd", res);
                if (res?.data?.status) {
                    toast.success(res?.data?.data?.message)
                    setTimeout(() => {
                        history.push("/cmslist")
                    }, 1000);
                }
                else toast.error(res?.data?.data?.message)
            }
            else if (slug == "registeraccountimg") {
                const formDatas = new FormData();

                formDatas.append("id", formData._id)

                formDatas.append("regimg", formData.file ?? "")
                formDatas.append("slug", data)


                console.log("paylaodsdasdasd", formDatas)

                const res = await fetcheditdata(formDatas, true)
                console.log("sdfgsfddfdf", res);
                if (res.data.status) {
                    toast.success(res.data.data.message)
                    setTimeout(() => {
                        history.push("/cmslist")
                    }, 1000);
                }
                else toast.error(res.data.data.message)
            }
            else if (slug == "roadmap") {
                const formDatas = new FormData();

                formDatas.append("id", formData._id)

                formDatas.append("mapimg", formData.file ?? "")
                formDatas.append("slug", data)


                console.log("paylaodsdasdasd", formDatas)

                const res = await fetcheditdata(formDatas, true)
                console.log("sdfgsfddfdf", res);
                if (res.data.status) {
                    toast.success(res.data.data.message)
                    setTimeout(() => {
                        history.push("/cmslist")
                    }, 1000);
                }
                else toast.error(res.data.data.message)
            }
            else {

                let payload = {
                    id: formData?._id,
                    heading: formData?.heading,
                    description: description,

                }
                console.log("paylaodsdasdasd", payload)

                const res = await fetcheditdata(payload, true)
                console.log("sdfgsfd", res);
                if (res.data.status) {
                    toast.success(res.data.data.message)
                    setTimeout(() => {
                        history.push("/cmslist")
                    }, 1000);
                }
                else toast.error(res.data.data.message)
            }



        }

    }
    // function handleChange(e) {
    //     console.log(e.target.files);
    //     setFile(URL.createObjectURL(e.target.files[0]));
    // }

    return (
        <>
            <Container fluid className="common_bg position-relative">
                <div className="liner"></div>
                <Row>
                    <Col xl={2} lg={0} className='p-0 d-none d-xl-block'>
                        <Sidebar />
                    </Col>
                    <Col xl={10} lg={12} >
                        <Header title={'Cms List'} />
                        <div className="pt-4">
                            <div><button className='orange_small_primary mb-4' type='button' onClick={() => history.goBack()}>Back</button></div>

                            <Row>
                                {console.log('dljsfkdnflkanf', slug, (slug == "roadmap"))}
                                <Col md={10} lg={7} xxl={6} className='mb-4'>

                                    {((slug == "homepage_box2") || (slug == "homepage_box3") || (slug == "homepage_box4") || (slug == "notes") || (slug == "faqtop")) ?
                                        <>
                                            <div className='rp_singleinput_holder mb-3'>
                                                <p className='rp_label mb-2'>Sub Title</p>
                                                <div className='rp_input_holder rounded-2 py-2 px-3 d-flex justify-content-start align-items-center gap-2'>
                                                    <input type='text' placeholder='heading' className='rp_singleInput flex-grow-1' value={formData?.heading} onChange={(e) => handlechange(e)} id='heading' />
                                                </div>

                                            </div>
                                            <div className='rp_singleinput_holder mb-3'>
                                                <p className='rp_label mb-2'>Description</p>
                                                <CKEditor initData={formData.description}
                                                    onChange={onEditorChange} />
                                            </div>

                                        </>
                                        : ((slug == "launch_spropad") ?
                                            <div className='rp_singleinput_holder mb-3'>
                                                <p className='rp_label mb-2'>Title</p>
                                                <div className='rp_input_holder rounded-2 py-2 px-3 d-flex justify-content-start align-items-center gap-2'>
                                                    <input type='text' placeholder='title' className='rp_singleInput flex-grow-1' value={formData?.heading} onChange={(e) => handlechange(e)} id='heading' />
                                                </div>
                                                <p className='rp_label mb-2'>Content1</p>
                                                <div className='rp_input_holder rounded-2 py-2 px-3 d-flex justify-content-start align-items-center gap-2'>
                                                    <input type='text' placeholder='Content1' className='rp_singleInput flex-grow-1' value={formData?.answer1} onChange={(e) => handlechange(e)} id='answer1' />
                                                </div>
                                                <p className='rp_label mb-2'>Content2</p>
                                                <div className='rp_input_holder rounded-2 py-2 px-3 d-flex justify-content-start align-items-center gap-2'>
                                                    <input type='text' placeholder='Content2' className='rp_singleInput flex-grow-1' value={formData?.answer2} onChange={(e) => handlechange(e)} id='answer2' />
                                                </div>
                                                <p className='rp_label mb-2'>Content3</p>
                                                <div className='rp_input_holder rounded-2 py-2 px-3 d-flex justify-content-start align-items-center gap-2'>
                                                    <input type='text' placeholder='Content3' className='rp_singleInput flex-grow-1' value={formData?.answer3} onChange={(e) => handlechange(e)} id='answer3' />
                                                </div>
                                            </div>

                                            : ((slug == "homepage_box1") ?
                                                <>
                                                    <div className='rp_singleinput_holder mb-3'>
                                                        <p className='rp_label mb-2'>Sub Title</p>
                                                        <div className='rp_input_holder rounded-2 py-2 px-3 d-flex justify-content-start align-items-center gap-2'>
                                                            <input type='text' placeholder='heading' className='rp_singleInput flex-grow-1' value={formData?.heading} onChange={(e) => handlechange(e)} id='heading' />
                                                        </div>

                                                    </div>
                                                    <div className='rp_singleinput_holder mb-3'>
                                                        <p className='rp_label mb-2'>Description</p>
                                                        <CKEditor initData={formData.description}
                                                            onChange={onEditorChange} />
                                                    </div>
                                                    {

                                                        <img
                                                            className='mt-3 objectimg'
                                                            src={formData?.file
                                                                ? typeof formData?.file == "object"
                                                                    ? window.URL.createObjectURL(formData?.file)
                                                                    : `${IMG_URL}/Hometopimg/${formData?.Hometopimage}`
                                                                : `${IMG_URL}/Hometopimg/${formData?.Hometopimage}`
                                                            }
                                                            width={100}
                                                        />





                                                    }


                                                    <div><input type="file" id="file" onChange={(e) => handlechange(e)} /></div></>


                                                : ((slug == "registeraccountimg") ? <>
                                                    <p className='rp_label mb-2'>Register your Account</p>

                                                    {<img
                                                        className='mt-3 objectimg'
                                                        src={formData?.file
                                                            ? typeof formData?.file == "object"
                                                                ? window.URL.createObjectURL(formData?.file)
                                                                : `${IMG_URL}/Registerimage/${formData?.Registerimage}`
                                                            : `${IMG_URL}/Registerimage/${formData?.Registerimage}`
                                                        }
                                                        width={100}
                                                    />}
                                                    <br />
                                                    <div><input type="file" id="file" onChange={(e) => handlechange(e)} /></div>
                                                </>

                                                    : ((slug == "roadmap") ? <>
                                                        <p className='rp_label mb-2'>Smart Pro Pad Features</p>

                                                        {<img
                                                            className='mt-3 objectimg'
                                                            src={formData?.file
                                                                ? typeof formData?.file == "object"
                                                                    ? window.URL.createObjectURL(formData?.file)
                                                                    : `${IMG_URL}/Raodmap/${formData?.roadmapimage}`
                                                                : `${IMG_URL}/Raodmap/${formData?.roadmapimage}`
                                                            }
                                                            width={100}
                                                        />}
                                                        <br />
                                                        <br />
                                                        <div><input type="file" id="file" onChange={(e) => handlechange(e)} /></div>
                                                    </> : ""
                                                    ))))

                                      
                                    }
                                              {(slug == "termsandcondition") || (slug == "privacypolicy") ?
                                        <>
                                            (<div className='rp_singleinput_holder mb-3'>
                                                <p className='rp_label mb-2'>Description</p>
                                                <CKEditor initData={formData.description}
                                                    onChange={onEditorChange} />
                                            </div>)
                                        </>
                                        : ""
                                    }

                                    <div className="pt-3">
                                        <button className='orange_small_primary' onClick={() => handleSubmit()}>Submit</button>
                                    </div>
                                </Col>
                            </Row>

                        </div>
                    </Col>
                </Row>
            </Container>
        </>
    );
}

export default Editcms;