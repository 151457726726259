import React, { useRef, useState, useEffect, useCallback } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import Sidebar from "../../Components/Sidebar";
import Header from "../../Components/Header";
import Select from "react-select";
import ReactDatatable from "@ashvin27/react-datatable";
import { FiArrowUpRight } from "react-icons/fi";
import { useHistory, useParams,useLocation } from "react-router-dom";
import { CKEditor } from 'ckeditor4-react';
import toast, { Toaster } from 'react-hot-toast';
import { isEmpty } from "../../common/common";
import { Faqupdate } from "../../Actions/cms";

function Faqlist(props) {
    console.log("props_spro",props);
    const history = useHistory();
const location = useLocation()
console.log("location",location);

const detail = props?.location.state
console.log("detail",detail);
    // useEffect(() => {
    //   bsCustomFileInput.init()
  
    // }, [])
  
    const [ans, setAns] = useState(detail?.answer)
  console.log("ansans",ans);

    const initData = {
      "question": "",
      "answer": ""
    }
  
    const [formData, setFormData] = useState(initData)
  console.log("formmdata",formData);
    useEffect(() => {
      setFormData(detail)
    //   setAns(detail?.answer)
    }, [props])
  
  
    const onEditorChange = (e) => {
      var content_text = e.editor.getData()
  console.log("content_text",content_text);

      setAns(content_text)

    }
  
    const [status, setStatus] = useState(false)
    const [ValidateError, SetValidateError] = useState('')
  
  
  
  
  
  
    const handlechange = async (e) => {
  
      e.preventDefault();
 
       
        const { value,id } = e.target;
        var formdata = formData
        formdata[id] = value
  console.log("formmdata",formdata,value,id);

        // console.log("files",files)
        setFormData({ ...formdata })
        // setFormData({...formData,'heading':value})
      
      console.log("formdata", formData)
    }
  
    const Validation = () => {
  
      let ValidateError = {}
  
  
      if (!formData.question) {
        ValidateError.question = "question is required";
      }
      if (!ans) {
        console.log("fdsgdfgdfgdfgdfg");
        ValidateError.answer = "answer is required";
      }

      SetValidateError(ValidateError)
      return ValidateError
    }
  
    const handleSubmit = async () => {
      var errors = {};
      var value = Validation();
  
      if (!isEmpty(value)) {
        console.log("value", value);
        SetValidateError(value);
        toast.error("fix all validation");
      }
      else {
        if (Object.keys(errors).length == 0) {
       
            console.log("erro length", Object.keys(errors).length, formData)
  
            var payload = {
              "id": formData?._id,
              "question": formData.question,
              "type":formData.type,
              "answer": ans,
            //   "uniqid":formData.uniqid

            }
  
            console.log("payload", payload)
  
            let resp = await Faqupdate(payload);
            if (resp?.data?.status) {
              toast.success(resp.data.data.message)
              setTimeout(() => {
                // history.push("/faqtable")
              history.push(`/faqtable/${formData.type}`)

              }, 1000);
  
            }
            else return toast.error(resp.data.data.message)
          
        //   else {
        //     // const formDatas = new FormData();
  
        //     // formDatas.append("heading",Encryptdata(formData.heading))
        //     // formDatas.append("id",Encryptdata(formData._id))
  
        //     // formDatas.append("content",Encryptdata(ans));
        //     // formDatas.append("img",formData.file)
        //     // formDatas.append("action",Encryptdata("edit"))
  
        //     // console.log("payload",formDatas.get('heading'),formDatas.get('img'))
        //     var datas = {
        //       "heading": formData.heading,
        //       "id": formData._id,
        //       "content": ans,
        //       "img": formData.file,
  
        //       "action": "edit"
        //     }
        //     var resp = await addpolynyx(datas);
        //     console.log("updataedsfasdfasdfa", resp)
        //     if (resp?.status) {
        //       toast.success("suucessfully updated")
        //       setTimeout(() => {
  
        //         history.push("/listpolynyx")
        //       }, 1000);
  
        //     }
        //     else return toast.error(resp.msg)
        //   }
  
  
  
        }
      }
  
  
    }

    return (
        <>
            <Container fluid className="common_bg position-relative">
                <div className="liner"></div>
                <Row>
                    <Col xl={2} lg={0} className='p-0 d-none d-xl-block'>
                        <Sidebar />
                    </Col>
                    <Col xl={10} lg={12} >
                        <Header title={'Faq List'} />
                        <div className="pt-4">
                            <div className="ms-3"><button className='orange_small_primary mb-4' type='button' onClick={() => history.goBack()}>Back</button></div>

                            <Row>
                                <Col md={10} lg={7} xxl={6} className='mb-4 ms-3'>
                                    <div className='rp_singleinput_holder mb-3'>
                                        <p className='rp_label mb-2'>Question</p>
                                        <div className='rp_input_holder rounded-2 py-2 px-3 d-flex justify-content-start align-items-center gap-2'>
                                            <input type='text' placeholder='title' className='rp_singleInput flex-grow-1' id='question' value={formData?.question} onChange={(e) => handlechange(e)}/>
                                        </div>
                                       
                                    </div>
                                    <div className='rp_singleinput_holder mb-3'>
                                        <p className='rp_label mb-2'>Answer</p>
                                        <CKEditor initData={ans}  onChange={(e) => onEditorChange(e)}/>
                                    </div>
                                  
                                    <div className="pt-3">
                                    <button className='orange_small_primary'  onClick={() => handleSubmit()}>Submit</button>
                                    </div>
                                </Col>
                            </Row>

                        </div>
                    </Col>
                </Row>
            </Container>
        </>
    );
}

export default Faqlist;