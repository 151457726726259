import React,{useEffect, useState} from 'react'
import { Modal } from 'react-bootstrap';
//lib
import { getWalletAddress } from '../Lib/localStorage';
import { isEmpty } from '../Lib/isEmpty';

//hooks
import {editDescription} from '../hooks/useContract'



function EditDescriptionModal({ show, handleClose, SaleInfo, saleData }) {

  const [saleInfo,setSaleInfo] = useState('')
  const [errors , setErrors] = useState('')


  useEffect(()=>{
    setSaleInfo(SaleInfo)
    console.log("sale",SaleInfo)
  },[])

  const updateDescription = async () => {
    const account = getWalletAddress();

    if (isEmpty(saleInfo.description)) {
      console.log("if")
      const Des = { ...errors, ["description"]: "Invalid Description!" };
      setErrors(Des);
      return
    }
    else if (saleInfo.owner !== account) {
      const Des = { ...errors, ["description"]: "Only sale owner can edit the description of the sale." };
      setErrors(Des);
      return
    }
    else {
      console.log("else")
      const Des = { ...errors, ["description"]: "" };
      setErrors(Des)
    }
    var data = []
    data.push(saleInfo.description)
    data.push(saleInfo.social[0])
    data.push(saleInfo.social[1])
    data.push(saleInfo.social[2])
    data.push(saleInfo.logo)
    console.log("data")
    var update = await editDescription(saleInfo?.saleAddress, data)
    console.log("update", update)
    saleData()
    handleClose()
    
  }

  const handleChange = (e) => {

    const { name, value } = e.target;
    let Data = { [name]: value };
    let formData = { ...saleInfo, ...Data }
    setSaleInfo(formData);
    console.log("formValue", saleInfo)
  };


  return (
    <>
      <Modal centered className='cmn_modal'
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >

        <Modal.Body>
          <div className='cmn_modal_header d-flex justify-content-between align-items-center'>
            <p className='cmn_modal_title'>Edit Description</p>
            <button className='cmn_modal_closer rounded-5' onClick={handleClose}><i class="fa-solid fa-xmark" /></button>
          </div>

          <div className='mt-4'>
            <div className='rp_singleinput_holder tetxarea_heigth mb-3'>
              <p className='rp_label mb-2'>Description</p>
              <div className='rp_input_holder rounded-2 py-2 px-3 d-flex justify-content-start align-items-center gap-2'>
                <textarea type='text' placeholder='Description' name='description' className='rp_singleInput flex-grow-1' value={saleInfo.description}
                onChange={(e) => { handleChange(e) }}
                 />
              </div>
              <span className="text-danger f-12 d-block text-left">{errors.description}</span>
            </div>


            <div className='text-end mt-4 pb-3'>
              <button className='orange_small_primary' onClick={async () => await updateDescription()}>Submit</button>
            </div>
          </div>

        </Modal.Body>

      </Modal>
    </>
  )
}

export default EditDescriptionModal