import React, { useState } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import Sidebar from '../Components/Sidebar'
import Header from '../Components/Header'
import { Link } from 'react-router-dom'
import toast, { Toaster } from 'react-hot-toast';

//Actions
import { forgetPassword } from "../Actions/LoginActions";

//validations
import { validateemail } from '../Validations/LoginValidate'


function Forgotpassword() {

    const [email, setEmail] = useState('')
    const [errors, setErrors] = useState('')

    const handleSubmit = async()=>{
        let payload = {
          email : email,
        }
      
        let validate = await validateemail(payload);
        if(validate.isValid){
          var forPass = await forgetPassword(payload);
          console.log("forPass",forPass)
          if(forPass?.data?.data?.status == "false" || !forPass?.data?.data?.status){
            setErrors(forPass?.data?.data?.errors)
          }else {
            toast.success(forPass?.data?.data?.message)
            console.log("forPass_forPass" , forPass);
            setEmail('')
          }
         
        }
        else{
            setErrors(validate?.errors)
        }
      }
    
   
  return (
    <>
    <Container fluid className='common_bg login_bg position-relative'>
        <div className='logo_sec'>
        <img src={require('../assets/images/headerlogo.png')} className="img-fluid main_logo" />

        </div>

    <Row className='justify-content-center align-items-center row_card'>
        
        <Col xl={12} lg={12} md={12} sm={12}>
            <div className='dashboard_box p-4'>
            <div className='profile_holder'>
                <p className='header_title_big'>Forgot Password</p>
                <hr className='grey_hr'/>
                </div>
           
            <div className='profile_holder'>
                <Row>
                    <Col lg={12} md={12} sm={12} className='mb-4'>
                    <div className='rp_singleinput_holder'>
                    <p className='rp_label mb-2'>Email Address</p>
                    <div className='rp_input_holder rounded-2 py-2 px-3 d-flex justify-content-start align-items-center gap-2'>
                        <input type="text" placeholder='Enter Email Address' className='rp_singleInput flex-grow-1'  value={email}
                        onChange={(e) => {
                            setEmail(e?.target?.value )}}/>

                    </div>
                    <span className="text-danger f-12 d-block text-left">{errors?.email}</span>

                </div>
                <div className='mt-3 text-end'>
                <Link to="/login" className='link_theme'>Back to Login?</Link>
                </div>
                    </Col>

                   

                    
                </Row>
                    <div className='text-center'>
                <button className='orange_small_primary'onClick={()=>{handleSubmit()}}>Submit</button>
                </div>
                
            </div>
            </div>
        </Col>
    </Row>

    </Container>
    </>
  )
}

export default Forgotpassword