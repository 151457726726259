import {WALLET_STATUS} from "../constants"

export const getAuthToken = () => {
    if (localStorage.getItem('user_token')) {
        return localStorage.getItem('user_token')
    }
    return '';
}
export const setRefferAddress = (address)=>{
    localStorage.setItem('refferaddress',address)
}

export const removeRefferAddress = ()=>{
    localStorage.removeItem('refferaddress')
}

export const getRefferAddress = ()=>{
    let refferAddress = localStorage.getItem('refferaddress')
    if(refferAddress){
        return refferAddress
    }
    return false
}
export const SetWallAddress = (account,wallet)=>{
    sessionStorage.setItem('accountInfo',account)
    if(wallet !=""){
        sessionStorage.setItem('wallet',wallet)
    }
}
export const getWalletAddress = () => {
    if (sessionStorage.getItem('accountInfo')) {
        return sessionStorage.getItem('accountInfo')
    }
    return '';
}
export const removeWallteAddress = () => {
    if (sessionStorage.getItem('accountInfo')) {
        sessionStorage.removeItem('accountInfo')
        sessionStorage.removeItem('wallet')
    }
    return '';
}
export const removeAuthToken = () => {
    localStorage.removeItem('user_token');
}

export const setAuthToken = (token) => {
    localStorage.setItem('user_token', token);
    return true
}

export const setTheme = async (theme) => {
    if (theme == 'dark') {
        document.body.classList.add('light_theme');
    } else {
        document.body.classList.add('light_theme');
    }
    localStorage.setItem('theme', theme);
    return theme
}

export const getTheme = () => {
    let theme = localStorage.getItem('theme');
    if (theme) {
        return theme
    }
    return 'dark'
}

export const changeTheme = (theme) => {
    if (theme == 'dark') {
        document.body.classList.add('light_theme');
    } else if (theme == 'light') {
        document.body.classList.add('light_theme');
    }
    return true;
}

export const setTradeTheme = async (theme) => {
    localStorage.setItem('tradeTheme', theme);
    if (theme == 'dark') {
        document.body.classList.add('light_theme');
    } else if (theme == 'light') {
        document.body.classList.add('light_theme');
    }
    return true;
}

export const changeTradeTheme = (theme) => {
    if (theme == 'dark') {
        document.body.classList.add('light_theme');
    } else if (theme == 'light') {
        document.body.classList.add('light_theme');
    }
    return true;
}

export const getTradeTheme = () => {
    let theme = localStorage.getItem('tradeTheme');
    if (theme) {
        return theme
    }
    return 'dark'
}

export const setLang = async (value) => {
    localStorage.setItem('lang', value);
    return true
}

export const getLang = () => {
    if (localStorage.getItem('lang')) {
        return localStorage.getItem('lang')
    }
    return '';
}

export const CleargetProfitCalculation = () => {
    let oldintervalId = localStorage.getItem('getProfitCalculation')
    if (oldintervalId) {
        clearInterval(oldintervalId);
        localStorage.removeItem('getProfitCalculation')
    }
}
export const ClearActiveCheck = () => {
    let oldintervalId = localStorage.getItem('ActiveCheck')
    if (oldintervalId) {
        clearInterval(oldintervalId);
        localStorage.removeItem('ActiveCheck')
    }
}

export const walletControl = (data) => {
    return{
        type:WALLET_STATUS,
        payload:data
    }
  
}

export const addLiquiditylocal = (data) => {
    localStorage.setItem('addLiquidity',data);
}

export const addLiquidityETHlocal = (data) => {
    localStorage.setItem('addLiquidity',data);
}

export const removeLiquiditylocal = (data) => {
    localStorage.setItem('removeLiquidity',data);
}

export const removeLiquidityETHlocal = (data) => {
    localStorage.setItem('removeLiquidity',data);
}

export const swaplocal = (data) => {
    localStorage.setItem('swap',data);
}

export const approvelocal = (data) => {
    console.log("approvelocal",data)
    localStorage.setItem('approve',data);
}

export const setSlippage = (data)=>{
    localStorage.setItem('Slippage',data)
}

export const getSlippage = ()=>{
   let Slippage = localStorage.getItem('Slippage')
    if(Slippage){
        return Slippage
    }
    return false
}

export const removeSlippage = ()=>{
    let Slippage = localStorage.getItem('Slippage')
    if(Slippage){
        localStorage.removeItem('Slippage')
    }
}

export const setMultiHops = (data)=>{
    localStorage.setItem('multihops',data)
}

export const getMultiHops = ()=>{
    let multihops = localStorage.getItem('multihops')
    if(multihops != undefined || multihops != null){
        return multihops
    }
    return undefined
}

export const removeMultiHops = ()=>{
    localStorage.removeItem('multihops')
}

export const setDeadLine_local = (data)=>{
    localStorage.setItem('deadline',data)
}

export const getDeadLine = ()=>{
    let deadline = localStorage.getItem('deadline')
    if(deadline){
        return deadline
    }
    return false
}

export const removeDeadLine = ()=>{
    localStorage.removeItem('deadline')
}


export const setExpertMode =(data)=>{
    localStorage.setItem('expertmode',data)
}

export const  getExpertMode= ()=>{
    let deadline = localStorage.getItem('expertmode')
    console.log(deadline,'getExpertMode')
    if(deadline != undefined || deadline != null){
        return deadline
    }
    return undefined
}

export const removeExpertMode = ()=>{
    localStorage.removeItem('expertmode')
}