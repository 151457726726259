import React, { useRef, useState, useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import Sidebar from "../Components/Sidebar";
import Header from "../Components/Header";
import Select from "react-select";
import ReactDatatable from "@ashvin27/react-datatable";

//hooks
import { getTotalSalesInfo, getlaunchpaddatalimit, isSaleLive, isUpcoming, DeletePresale } from '../hooks/useContract'
import { getChainId } from '../hooks/useWeb3'

//lib
import { padToDigits } from '../Lib/DateTimeHelper'
import { getWalletAddress } from "../Lib/localStorage";

//Actions 
import { launchpadhook } from "../Actions/LaunchActions";

import { CHAINS } from '../Config/env'
import { GetAllocationHooks } from "../hooks/Usebackendaxios";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

function Allocationlist() {
const navigate = useHistory();

  const state = [
    {
      address: "yu676869669869",
      salestype: "PrivateSale",
      symbol: "Test",
      status: "Sale Ended",
      softcap: "2",
      hardcap: "3",
      percentage: "5%",
      startdate: "09/01/2024 13:00:00",
      enddate: "03/02/2024 13:00:00",
      action: "Enable",
      view: "/launchpadDetail"
    },
    {
      address: "yu676869669869",
      salestype: "PrivateSale",
      symbol: "Test",
      status: "Sale Ended",
      softcap: "4",
      hardcap: "5",
      percentage: "10%",
      startdate: "09/01/2024 13:00:00",
      enddate: "03/02/2024 13:00:00",
      action: "Disable",
      view: "/launchpadDetail"

    },
    {
      address: "yu676869669869",
      salestype: "PrivateSale",
      symbol: "Test",
      status: "Sale Ended",
      softcap: "2",
      hardcap: "3",
      percentage: "5%",
      startdate: "09/01/2024 13:00:00",
      enddate: "03/02/2024 13:00:00",
      action: "Enable",
      view: "/launchpadDetail"

    },

  ];


  // console.log(state.records[0].status.props,"asdfasfasdfasdf");

  const columns = [
    {
      key: "name",
      text: "Name",
      className: "address",
      align: "center",
      sortable: false,
    },
    {
      key: "multiplier",
      text: "Multiplier",
      className: "salestype",
      align: "center",
      sortable: false,
    },
    {
      key: "allocation",
      text: "Allocation",
      className: "symbol",
      align: "center",
      sortable: false,
    },
    {
      key: "min",
      text: "Min",
      className: "symbol",
      align: "center",
      sortable: false,
    },
    {
      key: "max",
      text: "Max",
      className: "symbol",
      align: "center",
      sortable: false,
    },
    {
      text: "Action",
      className: "symbol",
      align: "center",
      sortable: false,
      cell: (record) => {
        return (
          <div>
            <Link to={{pathname:"/editallocation" , state : record}} className="table_btn_theme_inverse">Edit</Link>
          </div>
        )
      },
    }
  ];

  const config = {
    page_size: 3,
    length_menu: [10, 20, 50],
    filename: "Emailtemplates",

    language: {
      length_menu: "Show _MENU_ result per page",
      filter: "Filter in records...",
      info: "Showing _START_ to _END_ of _TOTAL_ records",
      pagination: {
        first: "First",
        previous: "Previous",
        next: "Next",
        last: "Last",
      },
      // loading:true,
      // no_data_text: loading ? 'Loading...' : "No data found!",
      // loading_text: loading ? 'Loading...': 
    },
    show_length_menu: false,
    show_filter: false,
    show_pagination: true,
    show_info: false,
  };

  const extraButtons = [
    {
      className: "btn btn-primary buttons-pdf",
      title: "Export TEst",
      children: [
        <span>
          <i
            className="glyphicon glyphicon-print fa fa-print"
            aria-hidden="true"
          ></i>
        </span>,
      ],
      onClick: (event) => {
        console.log(event);
      },
    },
    {
      className: "btn btn-primary buttons-pdf",
      title: "Export TEst",
      children: [
        <span>
          <i
            className="glyphicon glyphicon-print fa fa-print"
            aria-hidden="true"
          ></i>
        </span>,
      ],
      onClick: (event) => {
        console.log(event);
      },
      onDoubleClick: (event) => {
        console.log("doubleClick");
      },
    },
  ];



  const [trending, setTrending] = useState([])
  const [proxy, setProxy] = useState([])
  const [original, setOriginal] = useState([])
  const [loadinterval, setLoadinterval] = useState(3)
  const [totalSales, setTotalSales] = useState([])
  const [loading, setLoading] = useState(false)




  useEffect(() => {
    totalSaleInfo()
  }, [])

  const totalSaleInfo = async () => {
    setLoading(true)
    const Proxydata = await GetAllocationHooks();
    console.log("Proxydata", Proxydata);
    setTotalSales(Proxydata?.data?.record);
    setInterval(loadinterval);
    setLoading(false)
  }


  const handlePagination = async (index) => {
    let skip = (index.page_number - 1) * index.page_size
    let loadinterval = (index.page_number) * index.page_size
    const total = await getlaunchpaddatalimit(proxy, skip, loadinterval);
    setTotalSales(total);
    setInterval(loadinterval);
  }




  return (
    <>
      <Container fluid className="common_bg position-relative">
        <div className="liner"></div>
        <Row>
          <Col xl={2} lg={0} className='p-0 d-none d-xl-block'>
            <Sidebar />
          </Col>
          <Col xl={10} lg={12}>
            <Header title={'Tier List'} />
            <div className="page_position">
              <div className="common_page_scroller mt-5 pe-2">
                {/* <button onClick={() => navigate.push("/addallocation")}> Add </button> */}
                {/* <div className="text-end"><button className='orange_small_primary mb-1' onClick={() => navigate.push("/addallocation")} type='button'>Add</button></div> */}

                <div className="exchange_table_holder launchpad_table dashboard_box rounded-3 mt-4">
                  <ReactDatatable
                    config={config}
                    records={totalSales}
                    columns={columns}
                    extraButtons={extraButtons}
                    loading={loading ? true : false}
                    onChange={(e) => { handlePagination(e) }}
                    dynamic={true}
                    total_record={proxy.length}
                  />
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default Allocationlist;
