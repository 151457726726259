import React, { useEffect, useState } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import Sidebar from '../Components/Sidebar'
import Header from '../Components/Header'
import ReactDatatable from '@ashvin27/react-datatable';
import EditSettingModal from '../Modals/EditSettingModal';
import DeleteSettingModal from '../Modals/DeleteSettingModal';
import { NavLink } from 'react-router-dom';


//Actions
import { getsettinghook } from '../Actions/SettingsActions'

function Settings() {
    // edit modal
    const [showEdit, setShowEdit] = useState(false);

    const handleCloseEdit = () => setShowEdit(false);
    const handleShowEdit = (data) => {
        setsinglesetting(data)
        setShowEdit(true)
    };

    //   delete modal

    const [showDelete, setShowDelete] = useState(false);
    const [settings, setSettings] = useState('');


    const handleCloseDelete = () => setShowDelete(false);
    const handleShowDelete = () => setShowDelete(true);
    const [active, setActive] = useState('buy')
    const [singlesetting,setsinglesetting] = useState({})
    const state = {
        records: [
            {
                'settingsname': "Instagram",
                "settingsvalue": "https://www.instagram.com",

            },
            {
                'settingsname': "Twitter",
                "settingsvalue": "https://www.twitter.com",

            },
            {
                'settingsname': "Telegram",
                "settingsvalue": "https://www.telegram.com",

            },
            {
                'settingsname': "Discord",
                "settingsvalue": "https://www.discord.com",

            }

        ]
    }

    const columns = [
        {
            key: "settingname",
            text: "Settings Name",
            className: "settingsname",
            align: "center",
            sortable: false,
        },
        {
            key: "settingvalue",
            text: "Settings Value",
            className: "settingsvalue",
            align: "center",
            sortable: false,

        },
        {
            key: "action",
            text: "Action",
            className: "activity",
            align: "center",
            sortable: false,
            cell: (record) => {
                return (
                    <div className='d-flex justify-content-center align-items-center gap-2'>
                        <button className='cmn_plain_btn' onClick={()=>{handleShowEdit(record)}}><img src={require('../assets/images/editer.svg').default} className='img-fluid table_activity_img' /> </button>
                        {/* <button className='cmn_plain_btn' onClick={handleShowDelete}><img src={require('../assets/images/trash.svg').default} className='img-fluid table_activity_img' /> </button> */}
                    </div>
                )
            }
        },
    ];

    const config = {
        page_size: 10,
        length_menu: [10, 20, 50],
        filename: "Emailtemplates",
        no_data_text: "No Email Templates found!",
        language: {
            length_menu: "Show _MENU_ result per page",
            filter: "Filter in records...",
            info: "Showing _START_ to _END_ of _TOTAL_ records",
            pagination: {
                first: "First",
                previous: "Previous",
                next: "Next",
                last: "Last",
            },
        },
        show_length_menu: false,
        show_filter: false,
        show_pagination: false,
        show_info: false,
    };

    const extraButtons = [
        {
            className: "btn btn-primary buttons-pdf",
            title: "Export TEst",
            children: [
                <span>
                    <i className="glyphicon glyphicon-print fa fa-print" aria-hidden="true"></i>
                </span>
            ],
            onClick: (event) => {
                console.log(event);
            },
        },
        {
            className: "btn btn-primary buttons-pdf",
            title: "Export TEst",
            children: [
                <span>
                    <i className="glyphicon glyphicon-print fa fa-print" aria-hidden="true"></i>
                </span>
            ],
            onClick: (event) => {
                console.log(event);
            },
            onDoubleClick: (event) => {
                console.log("doubleClick")
            }
        },
    ]



    const getsetting = async () => {

        let setting = await getsettinghook();
        console.log("setting", setting?.data?.data);
        if (setting?.data?.status == true) {
            setSettings(setting?.data?.data);
        }

    }

    useEffect(() => {
        getsetting()
    }, [])

    const refetch= ()=>{
        getsetting()
    }


    return (
        <>
            <Container fluid className='common_bg position-relative'>
                <div className='liner'></div>
                <Row>
                    <Col xl={2} lg={0} className='p-0 d-none d-xl-block'>
                        <Sidebar />
                    </Col>
                    <Col xl={10} lg={12}>
                        <Header title={'Settings'} />
                        <div className='setting_tab_holder mt-5 pe-2'>
                            <Row>
                                <Col sm={6} className='d-flex gap-3 mb-3 mb-sm-0'>

                                </Col>
                                <Col sm={6} className='text-end'>
     

                                    <NavLink to='/addSettings'  className='sidebar_links'>
                                        <button className='orange_small_primary'>Add</button>
                                    </NavLink>
                                </Col>
                            </Row>
                        </div>
                        {/* {active == 'buy' &&  */}
                        <div className='common_page_scroller mt-4'>
                            <div className='exchange_table_holder dashboard_box rounded-3'>
                                <ReactDatatable
                                    config={config}
                                    records={settings}
                                    columns={columns}
                                    extraButtons={extraButtons}
                                />
                            </div>
                        </div>
                        {/* } */}
                        {/* {active == 'sell' && 
            <div className='common_page_scroller mt-4'>
            <div className='exchange_table_holder dashboard_box rounded-3'>
            <ReactDatatable
                    config={config}
                    records={state.records}
                    columns={columns}
                    extraButtons={extraButtons}
                />
                </div>
            </div> } */}
                    </Col>
                </Row>

            </Container>

            {/* modals */}

            {showEdit && <EditSettingModal show={showEdit} handleClose={handleCloseEdit} data = {singlesetting} getset = {()=>getsetting()}/>}

            <DeleteSettingModal show={showDelete} handleClose={handleCloseDelete} />
        </>
    )
}

export default Settings