import React, { useState } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import Sidebar from '../Components/Sidebar'
import Header from '../Components/Header'
import toast, { Toaster } from 'react-hot-toast';

//validations
import { validateProfileEmail,validateProfilePass } from '../Validations/LoginValidate'

//Actions
import { profileSettings } from '../Actions/LoginActions'
import { CreateAllocationHooks } from '../hooks/Usebackendaxios';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

function Addallocation() {
    const navigate = useHistory();
    const [oldPassword,setOldPassword] = useState('')
    const [newPassword,setNewPassword] = useState('')
    const [passwordeye,setPasswordeye] = useState(false)
    const [newpasswordeye,setNewPasswordeye] = useState(false)
    const [errors, setErrors] = useState('')
    const [newEmail,setNewEmail] = useState('');

    //dev-muruga
    const [Type , setType] = useState("");
    const [multiplier , setMultiplier] = useState(0);
    const [allocation , setAllocation] = useState(0);
    const [fileerror , setFileerror] = useState("");
    
    const [file, setFile] = useState();
    const [image , setImage] = useState();
    const [min , setMin] = useState(0);
    const [max , setMax] = useState(0);

     const ValidateFile = (data) => {
        try{
            var fileName = data.name;
            var idxDot = fileName.lastIndexOf(".") + 1;
            var extFile = fileName.substr(idxDot, fileName.length).toLowerCase();
            if(extFile=="jpg" || extFile=="jpeg" || extFile=="png" || extFile=="webp" || extFile=="svg"){
                return ""
            }
            else{
                return "Invalid file format"
            }
        }
        catch(e){
            console.log("Error on validate filer" , e);
        }
    }
    function handleChange(e) {
        let validate = ValidateFile(e?.target?.files[0])
        if(validate){
            setFile();
            setFileerror(validate);
            setImage()
        }
        else {
            console.log(e.target.files);
            setFile(URL.createObjectURL(e.target.files[0]));
            setFileerror("");
            setImage(e?.target?.files[0]);
        }
    }

    const handleallocation = async() => {
        // debugger
        let formdata = new FormData();
        formdata.append("name" , Type);
        formdata.append("multiplier" , multiplier);
        formdata.append("allocation" , allocation);
        formdata.append("image" , image)
        formdata.append("min" ,  min);
        formdata.append("max" , max);
        let result = await CreateAllocationHooks(formdata);
        console.log("result" , result);
        if(result?.data?.type == "Success"){
            navigate("/allocationlist")
        }
    }

  return (
    <>
    <Container fluid className='common_bg position-relative'>
    <div className='liner'></div>

    <Row>
        <Col xl={2} lg={0} className='p-0 d-none d-xl-block'>
            <Sidebar/>
        </Col>
        <Col xl={10} lg={12} className='pe-3 ps-lg-4'>
            <Header title={'Change Password'}/>
            <div className='mt-5 profile_holder'>
                <Row>

                <Col lg={7} md={8} sm={10} className='mb-4'>
                    <div className='rp_singleinput_holder'>
                    <p className='rp_label mb-2'>Type</p>
                    <div className='rp_input_holder rounded-2 py-2 px-3 d-flex justify-content-start align-items-center gap-2'>
                        <input type={'text'} placeholder='Enter Type' className='rp_singleInput flex-grow-1'
                          value={Type}
                          onChange={(e) => {
                            setType(e?.target?.value)
                          }} />

                    </div>
                    <span className="text-danger f-12 d-block text-left">
                                    {errors?.newEmail}
                                  </span>
                </div>
                    </Col>

                    <Col lg={7} md={8} sm={10} className='mb-4'>
                    <div className='rp_singleinput_holder'>
                    <p className='rp_label mb-2'>Multiplier Count</p>
                    <div className='rp_input_holder rounded-2 py-2 px-3 d-flex justify-content-start align-items-center gap-2'>
                        <input type="number" placeholder='Enter Multiplier Count' className='rp_singleInput flex-grow-1'
                         value={multiplier}
                         onChange={(e) => {
                          setMultiplier(e?.target?.value)
                         }} 
                          />

                        {/* <i class={!passwordeye ? 'fa-solid fa-eye-slash' : "fa-solid fa-eye" } onClick={()=> setPasswordeye(!passwordeye)}/> */}
                    </div>
                    <span className="text-danger f-12 d-block text-left">
                                    {errors?.oldPassword}
                                  </span>
                </div>
                    </Col>

                    <Col lg={7} md={8} sm={10} className='mb-4'>
                    <div className='rp_singleinput_holder'>
                    <p className='rp_label mb-2'>Allocation</p>
                    <div className='rp_input_holder rounded-2 py-2 px-3 d-flex justify-content-start align-items-center gap-2'>
                        <input type="number" placeholder='Enter New Password' className='rp_singleInput flex-grow-1' 
                        value={allocation}
                        onChange={(e) => {
                          setAllocation(e?.target?.value)
                        }}
                        />

                        {/* <i class={!newpasswordeye ? 'fa-solid fa-eye-slash' : "fa-solid fa-eye" } onClick={()=> setNewPasswordeye(!newpasswordeye)}/> */}
                    </div>
                    <span className="text-danger f-12 d-block text-left">
                                    {errors?.newPassword}
                                  </span>
                </div>
                    </Col>


                    <Col lg={7} md={8} sm={10} className='mb-4'>
                    <div className='rp_singleinput_holder'>
                    <p className='rp_label mb-2'>Minimum Buy</p>
                    <div className='rp_input_holder rounded-2 py-2 px-3 d-flex justify-content-start align-items-center gap-2'>
                        <input type="number" placeholder='Enter New Password' className='rp_singleInput flex-grow-1' 
                        value={min}
                        onChange={(e) => {
                          setMin(e?.target?.value)
                        }}
                        />

                        {/* <i class={!newpasswordeye ? 'fa-solid fa-eye-slash' : "fa-solid fa-eye" } onClick={()=> setNewPasswordeye(!newpasswordeye)}/> */}
                    </div>
                    <span className="text-danger f-12 d-block text-left">
                                    {errors?.newPassword}
                                  </span>
                </div>
                    </Col>


                    <Col lg={7} md={8} sm={10} className='mb-4'>
                    <div className='rp_singleinput_holder'>
                    <p className='rp_label mb-2'>Maximum Buy</p>
                    <div className='rp_input_holder rounded-2 py-2 px-3 d-flex justify-content-start align-items-center gap-2'>
                        <input type="number" placeholder='Enter New Password' className='rp_singleInput flex-grow-1' 
                        value={max}
                        onChange={(e) => {
                          setMax(e?.target?.value)
                        }}
                        />

                        {/* <i class={!newpasswordeye ? 'fa-solid fa-eye-slash' : "fa-solid fa-eye" } onClick={()=> setNewPasswordeye(!newpasswordeye)}/> */}
                    </div>
                    <span className="text-danger f-12 d-block text-left">
                                    {errors?.newPassword}
                                  </span>
                </div>
                    </Col>



                    <Col lg={7} md={8} sm={10} className='mb-4'>
                    <div className='rp_singleinput_holder mb-3'>
                                        <p className='rp_label mb-2'>Image</p>
                                        <div className="poss">
                                        <button className='orange_small_primary uploadimg mb-4' type='button'>Upload Image</button>
                                        <input type="file" className="browseinput" onChange={handleChange} />
                                        </div>
                                        <img src={file} className="img-fluid mt-3 hw" />
                                    </div>
                                    <p className='text-danger'>{fileerror}</p>
                                    </Col>
                                    <div className="pt-3">
                                        <button className='orange_small_primary' onClick={handleallocation}>Submit</button>
                                    </div>
                  
                </Row>

                {/* <button className='orange_small_primary'  onClick={() => {handlePassword() }}>Submit</button> */}

                
                
            </div>
        </Col>
    </Row>

    </Container>
    </>
  )
}

export default Addallocation;