import React from 'react'
import {Modal} from 'react-bootstrap';

function OpenTicket({show,handleClose}) {
  return (
    <>
     <Modal centered className='cmn_modal'
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        
        <Modal.Body>
            <div className='cmn_modal_header d-flex justify-content-between align-items-center'>
                <p className='cmn_modal_title'>Open Tickets</p>
                <button className='cmn_modal_closer rounded-5' onClick={handleClose}><i class="fa-solid fa-xmark"/></button>
            </div>

            <div className='mt-4'>
            <div className='rp_singleinput_holder mb-3'>
                    <p className='rp_label mb-2'>Title</p>
                    <div className='rp_input_holder rounded-2 py-2 px-3 d-flex justify-content-start align-items-center gap-2'>
                        <input type='text' placeholder='Demo' className='rp_singleInput flex-grow-1' />
                    </div>
                </div>

                <div className='rp_singleinput_holder mb-3'>
                    <p className='rp_label mb-2'>Description</p>
                    
                        <textarea  rows="4" className='w-100 ot_textarea rounded-3 py-2 px-3' cols="50">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book</textarea>
                </div>

                <button className='orange_small_primary' onClick={handleClose}>Delete</button>
            </div>
         
        </Modal.Body>
       
      </Modal>
    </>
  )
}

export default OpenTicket