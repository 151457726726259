import { isEmpty } from '../src/Lib/isEmpty';
import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { WALLET_STATUS, WALLET_ADDRESS,NETWORK_SWITCH, GAS_PRICE,  } from "../src/constants"
import {  getWalletAddress } from "../src/Lib/localStorage"
import { consolelog } from './Lib/consolelog';
import { CHAINS } from './Config/env';

//contract 
// import { BalanceNative } from './hooks/useNativeToken'
// import { XBalance } from './hooks/useXtoken';

//hooks
// import { getFarmsInfo } from './hooks/useFarms';
import { GetChainIndex } from './hooks/useWeb3';

const HelperRoute = () => {
    let dispatch = useDispatch()

    useEffect(() => {
        // if (!getSlippage()) {
        //     setSlippage(0.5)
        //     dispatch({
        //         type: SLIPPAGE,
        //         payload: 0.5
        //     })
        // } else if (getSlippage()) {
        //     dispatch({
        //         type: SLIPPAGE,
        //         payload: getSlippage()
        //     })
        // }

        // if (getMultiHops() == undefined) {
        //     setMultiHops(true)
        //     dispatch({
        //         type: MULTI_HOPS,
        //         payload: true
        //     })
        // } else {
        //     dispatch({
        //         type: MULTI_HOPS,
        //         payload: JSON.parse(getMultiHops())
        //     })
        // }

        // if (!getDeadLine()) {
        //     setDeadLine_local(10)
        //     dispatch({
        //         type: DEADLINE,
        //         payload: 10
        //     })
        // } else if (getDeadLine()) {
        //     dispatch({
        //         type: DEADLINE,
        //         payload: getDeadLine()
        //     })
        // }

        // if (getExpertMode() == undefined) {
        //     setExpertMode(false)
        //     dispatch({
        //         type: EXPERT_MODE,
        //         payload: false
        //     })
        // } else {
        //     dispatch({
        //         type: EXPERT_MODE,
        //         payload: getExpertMode()
        //     })
        // }

        if (getWalletAddress()) {
            dispatch({
                type: WALLET_ADDRESS,
                payload: getWalletAddress()
            })
            // getNativebaln()
            // getXtoken()
        }
        if (!getWalletAddress()) {
            sessionStorage.setItem('walletStatus', 'disconnect')
        }
        console.log(getWalletAddress() === undefined ? 'disconnect' : sessionStorage.getItem('walletStatus'),'walletStatus_use')
        dispatch({
            type: WALLET_STATUS,
            payload: getWalletAddress() === undefined ? 'disconnect' : sessionStorage.getItem('walletStatus')
        })

        dispatch({
            type: NETWORK_SWITCH,
            payload: GetChainIndex()
        })
        localStorage.setItem("CHAIN",GetChainIndex())
        let data = {
            gasPrice: '5000000000',
            type: 'standard'
        }
        dispatch({
            type: GAS_PRICE,
            payload: data
        })
    }, [])

    // useEffect(() => {
    //     if (localStorage.getItem('FarmInterval')) {
    //         let oldInterval = localStorage.getItem('FarmInterval')
    //         clearInterval(oldInterval);
    //     }
    //     const interval = setInterval(async () => {
    //         GetFarmInfo()
    //     }, 3000)
    //     localStorage.setItem('FarmInterval', interval)
    // }, [])

    // const GetFarmInfo = async () => {
    //     try {
    //         console.log("GetFarmInfo_")
    //         let Farms = await getFarmsInfo()
    //         console.log(Farms, "GetFarmInfo_")
    //         dispatch({
    //             type: FARMS_DATA,
    //             payload: Farms
    //         })
    //     } catch (err) {
    //         console.log(err, "GetFarmInfo__Err")
    //     }
    // }

    // const getNativebaln = async () => {
    //     try {
    //         let balance = await BalanceNative(getWalletAddress())
    //         balance = parseFloat(balance) / 10 ** 18
    //         dispatch({
    //             type: NATIVE_BALANCE,
    //             payload: balance
    //         })
    //     } catch (err) {
    //         consolelog('getNativebaln__err', err, true)
    //     }
    // }
    // const getXtoken = async () => {
    //     try {
    //         let balance = await XBalance(getWalletAddress())
    //         balance = parseFloat(balance) / 10 ** 18
    //         dispatch({
    //             type: XTOKEN_BALANCE,
    //             payload: balance
    //         })
    //     } catch (err) {
    //         consolelog('getNativebaln__err', err, true)
    //     }
    // }

}



export default HelperRoute;