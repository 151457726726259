import React, { useEffect, useState } from 'react'
import { Col, Container, Row, InputGroup, FormControl, Form } from 'react-bootstrap'
import Header from '../Components/Header'
import Sidebar from '../Components/Sidebar'
import EditDescriptionModal from '../Modals/EditDescriptionModal'
import { Link, useNavigate, useLocation, useParams } from 'react-router-dom';

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

//lib
import { padToDigits } from '../Lib/DateTimeHelper'
import { getWalletAddress } from '../Lib/localStorage'

//hooks
import { getSaleInfoCard, checkbalnceTokens, UseTokenInfo, Getliquiditytokenamount, Getunsoldtoken, isSaleLive, isUpcoming, GetSalePerAccount, getAllocationInfo, getAffliateInfo, UsegetChildrenOf, checkIsApproved, SetWhitelisted, updateStopEvent, getUnsold, withdrawBNB, Finalise } from '../hooks/useContract'
import { getChainId } from '../hooks/useWeb3'

//config
import { CHAINS } from '../Config/env'

//modals
import WhitelistModal from '../Modals/WhitelistModal'
import RemoveWhitelistModal from '../Modals/RemoveWhitelistModal'

function LaunchpadDetail() {
    const [currentStep, setCurrentstep] = useState(1);
    const [isPancake, setIsPancake] = useState(false);
    const [isVested, setIsVested] = useState(false);
    const [startDate, setStartDate] = useState(new Date());
    const [currency, setCurrency] = useState("BUSD");
    const [showDescription, setShowDescription] = useState(false);
    const [showWhitelist, setShowWhitelist] = useState(false);
    const [showRemovewhitelist, setShowRemovewhitelist] = useState(false);

    const [AffliateInfo, setAffliateInfo] = useState({})
    const [saleInfo, setSaleInfo] = useState({})
    const [userData, setUserData] = useState({})
    const [getChildren, setGetChildren] = useState([])
    const [checkbalance, setCheckbalance] = useState(0)
    const [charttokendecimal, setCharttokendecimal] = useState(18)
    const [isSaleEnd, setIsSaleEnd] = useState(false);
    const [chartstatus, setChartstatus] = useState(false);
    const [allocations, setAllocations] = useState([])
    const [approvebutton, setApprovebutton] = useState(false);
    const [buyTokenDecimal, setBuyTokenDecimal] = useState(18)
    const [checkdeposittoken, setCheckdeposittoken] = useState(0)
    const [Avgcontribution, setAvgcontribution] = useState('')
    const [buyToken, setBuyToken] = useState('')


    const handleCloseDescription = () => setShowDescription(false);
    const handleShowDescription = () => setShowDescription(true);


    const handleCloseWhitelist = () => setShowWhitelist(false);
    const handleShowWhitelist = () => setShowWhitelist(true);

    const handleCloseRemovewhitelist = () => setShowRemovewhitelist(false);
    const handleShowRemovewhitelist = () => setShowRemovewhitelist(true);

    const state = useParams()
    console.log("LaunchpadDetail_state", state)


    const lockDaysCal = () => {
        if (parseFloat(state?.lpUnlockon) == 0) {
            return 0
        }
        let diffTime = Math.abs(new Date(parseFloat(state?.endTime) * 1000).getTime() - new Date(parseFloat(state?.lpUnlockon) * 1000).getTime());
        let days = diffTime / (24 * 60 * 60 * 1000);
        console.log("days", days)
        return isNaN(days) ? 0 : days.toFixed(0)
    }


    useEffect(() => {
        refreshData()
    }, [])

    const refreshData = async () => {

        try {
            console.log('refreshData')
            let saleDetail = await getSaleInfoCard(state.saleAddress);
            var saleData = { ...saleDetail }
            saleData.presaleRate = parseFloat(saleData.presaleRate) / 10 ** 18
            saleData.pancakeRate = parseFloat(saleData.pancakeRate) / 10 ** 18
            setSaleInfo(saleData);
            console.log("notdb", saleData);



            const AffliateInfo = await getAffliateInfo(state.saleAddress);
            console.log("AffliateInfo", AffliateInfo)
            setAffliateInfo(AffliateInfo)


            const getChildrendata = await UsegetChildrenOf(state.saleAddress, getWalletAddress());
            setGetChildren(getChildrendata)
            console.log("getChildrengetChildren", getChildrendata, getChildrendata.length);


            const balance = await checkbalnceTokens(saleDetail?.tokenAddress, state.saleAddress);
            console.log(" >>>>", parseFloat(balance) / 10 ** parseInt(saleDetail?.decimals));
            setCheckbalance(parseFloat(balance) / 10 ** parseInt(saleDetail?.decimals))
            console.log("saleDetail", saleDetail);
            const token = await UseTokenInfo(saleDetail?.tokenAddress);
            let decimal;
            console.log("tokentokentoken", token);
            setCharttokendecimal(18)


            let liqtoken = await Getliquiditytokenamount(state.saleAddress);
            console.log("liqtoken", liqtoken);
            console.log(localStorage.getItem("accountInfo"), "MaxVC balance : 2 ")
            //   setLiqtoken(liqtoken / 10 ** 18)
            let unsoldtoken = await Getunsoldtoken(state.saleAddress);
            console.log("liqtoken1", liqtoken);

            //   setUnsoldtoken(unsoldtoken / 10 ** 18);
            var saleData = { ...saleDetail }
            saleData.presaleRate = parseFloat(saleData.presaleRate) / 10 ** 18
            saleData.pancakeRate = parseFloat(saleData.pancakeRate) / 10 ** 18

            setSaleInfo(saleData);
            let saleEnd = isSaleLive(parseFloat(saleDetail.startTime), parseFloat(saleDetail.endTime), parseFloat(saleDetail.isPresaleOpen))
            console.log("saleEnd", saleEnd)
            setIsSaleEnd(saleEnd);
            setChartstatus(true);
            GetBuytokeninfo(saleDetail?.useWithToken)
            console.log(localStorage.getItem("accountInfo"), "MaxVC balance : 2 ")
            if (localStorage.getItem("accountInfo")) {
                const user = await GetSalePerAccount(localStorage.getItem("accountInfo"), state?.saleAddress);
                console.log("MaxVC balance : 2 ", user)
                setUserData(user);
                if (saleDetail?.isClaimable) {
                    const allocated = await getAllocationInfo(user.actualBalance, saleDetail.vestingInterval, saleDetail.vestingPercent, user.initialClaim);
                    setAllocations(allocated);
                }

                if (!saleDetail?.buytype) {
                    approveFunction(localStorage.getItem("accountInfo"), saleDetail?.useWithToken, saleDetail?.saleAddress)
                }
                if (!saleDetail?.LaunchpadType) {
                    handlecheckfinalize()
                }

            }
            avgcontribution();
        }
        catch (e) {
            console.log("error on detail page", e);
        }
    }

    const approveFunction = async (accountInfo, tokenAddress, saleAddress) => {
        const approval = await checkIsApproved(accountInfo, tokenAddress, saleAddress);
        console.log("approval", approval);
        setApprovebutton(approval)
    }

    const handlecheckfinalize = async () => {
        let tokenAmount = 0;
        let amountineth = parseFloat(state?.earnedCap / 10 ** 18) * parseFloat(state?.liquidityPercent) / 100;
        var pricePerToken = parseFloat((state?.earnedCap / 10 ** 18) * 10 ** 8) / parseFloat(state?.hardCap / 10 ** 18); // pricePerToken
        var valuePerBNB = 1 * 10 ** 8 / pricePerToken; // valuePerBNB

        tokenAmount = valuePerBNB;

        if (state?.decimals != "18") {
            var price = (tokenAmount * 1) / 10 ** (18 - parseInt(state?.decimals) + 0);
            tokenAmount = price;
        }

        let total = amountineth * tokenAmount;
        setCheckdeposittoken(total)

    }



    const avgcontribution = async () => {
        console.log("avgcontribution", saleInfo, parseFloat(saleInfo.presaleRate), parseFloat(saleInfo.earnedCap) / 10 ** buyTokenDecimal, parseFloat(saleInfo.participants))
        var contribution = parseFloat(saleInfo.participants) > 0 ? (parseFloat(saleInfo.presaleRate) * (parseFloat(saleInfo.earnedCap )/ 10 ** buyTokenDecimal) / parseFloat(saleInfo.participants)).toFixed(2) : ""
        console.log("contribution", contribution, parseFloat(saleInfo.presaleRate), (parseFloat(saleInfo.earnedCap) / 10 ** buyTokenDecimal), parseFloat(saleInfo.participants), (parseFloat(saleInfo.earnedCap) / 10 ** buyTokenDecimal) / parseFloat(saleInfo.participants))
        setAvgcontribution(contribution)
    }

    const GetBuytokeninfo = async (value) => {
        try {
            if (value === "0x0000000000000000000000000000000000000000") {

                setBuyToken("BNB");

                setBuyTokenDecimal(18)
            } else {

                const token = await UseTokenInfo(saleInfo && saleInfo?.useWithToken);
                setBuyToken(token?.symbol);
                setBuyTokenDecimal(Number(token?.decimals))

            }
        }
        catch (err) {
            console.log("err", err)
        }
    }

    const Handlewhitelist = async (value) => {
        await SetWhitelisted(state.saleAddress, value, getWalletAddress());
        await refreshData()
    }


    const stopSaleEvent = async () => {
        await updateStopEvent(state.saleAddress, getWalletAddress());
        await refreshData()

    }



    const withdrawToken = async () => {
        await getUnsold(state.saleAddress, getWalletAddress());
        await refreshData()
            
    }

    const BNBwithdraw = async () => {
        await withdrawBNB(state.saleAddress, getWalletAddress());
        await refreshData()

    }

    const Finalisesale = async () => {
        await Finalise(state.saleAddress, getWalletAddress());
        await refreshData()

    }

    return (
        <>
            <Container fluid className='common_bg position-relative'>
                <div className='liner'></div>
                <Row>
                    <Col xl={2} lg={0} className='p-0 d-none d-xl-block'>
                        <Sidebar />
                    </Col>
                    <Col xl={10} lg={12}>
                        <Header title={'Create Launchpad'} />
                        <div className='mt-5 profile_holder ps-lg-3'>

                            <div className="px-1 container detail_page">
                                <div className="row mt-5">
                                    <div className="col-12 col-md-6 col-lg-6 mb-4">
                                        <div className="card_bg card h-100">
                                            <div className="card-body">
                                                <div className="row align-items-center pb-4 row_left_res">
                                                    <div className="col-12 col-sm-12 col-md-12 col-xl-12 mb-0">
                                                        <p className="text-end mb-2">
                                                            <span className="badge badge-green-rect mt-0"><span className="blk_txt">{saleInfo && isSaleLive(parseFloat(saleInfo.startTime), parseFloat(saleInfo.endTime), saleInfo.isPresaleOpen) ?
                                                                'Live' : isUpcoming(parseFloat(saleInfo.startTime)) ? 'Upcoming' : 'Closed'}</span></span>
                                                        </p>
                                                    </div>
                                                    <div className="col-12 col-sm-12 col-md-12 col-xl-12 mb-3 mb-md-0">
                                                        <div className="d-flex align-items-center flex_mob_blk_div">
                                                            <div className="d-flex align-items-center flex-column">

                                                                <div className="profimg">
                                                                    <img src={saleInfo?.logo} className="img_ind_circle" />
                                                                    
                                                                </div>
                                                                 <p className="desc_grey_txt mb-1 text-center">{saleInfo?.name}</p>

                                                            </div>

                                                            <div className="flex_badges_dv flex_badges_dv_new">
                                                                <div className="inside_soc_f">
                                                                    <a target="_blank" className="btn_social_new btn_social_new_a_new ms-1" href={saleInfo && saleInfo?.social && saleInfo?.social[5]}><i className="fa fa-globe" aria-hidden="true"></i></a>
                                                                    <a target="_blank" className="btn_social_new btn_social_new_a_new ms-1" href={saleInfo && saleInfo?.social && saleInfo?.social[1]}><i className="fa-brands fa-twitter" aria-hidden="true"></i></a>
                                                                    <a target="_blank" href={saleInfo && saleInfo?.social && saleInfo?.social[2]} className="btn_social_new btn_social_new_a_new ms-1"><i className="fa fa-paper-plane" aria-hidden="true"></i></a>
                                                                    <a target="_blank" href={saleInfo && saleInfo?.social && saleInfo?.social[6]} className="btn_social_new btn_social_new_a_new ms-1"><i className="fa-brands fa-instagram" aria-hidden="true"></i></a>
                                                                </div>
                                                            </div>
                                                        </div>
                                                       

                                                    </div>

                                                </div>
                                                <div className="mt-1"></div>
                                                <div className="row">
                                                    <div className="col-lg-10 col-md-10 col-sm-10 col-10"><p className="desc_grey_txt mb-1">{saleInfo.description}</p></div>
                                                    <div className="col-lg-2 col-md-2 col-sm-2 col-2 d-flex justify-content-end icon_btn_edit" onClick={handleShowDescription}><i className="fa fa-pencil text-white" aria-hidden="true"></i></div>
                                                </div>
                                                <div>
                                                    <p className="white_txt_sm d-flex justify-content-between mt-3 mb-1"><span className="desc_grey_txt">Softcap </span><span className="desc_grey_txt_white font_12">{saleInfo &&  isNaN(parseFloat(saleInfo.softCap)) ? 0 : parseFloat(saleInfo.softCap) / 10 ** 18} </span></p>
                                                    <p className="white_txt_sm d-flex justify-content-between mt-0 mb-1"><span className="desc_grey_txt">Start Time </span><span className="desc_grey_txt_white font_12">
                                                        {isNaN(parseFloat(saleInfo?.startTime)) ? 0 :(padToDigits(new Date(parseFloat(saleInfo?.startTime) * 1000).toLocaleDateString()) + "  "
                                                            + padToDigits(new Date(parseFloat(saleInfo?.startTime) * 1000).getHours()) + ":" + padToDigits(new Date(parseFloat(saleInfo?.startTime) * 1000).getMinutes()) + ":"
                                                            + padToDigits(new Date(parseFloat(saleInfo?.startTime) * 1000).getSeconds()) + "(GMT)")}</span></p>
                                                    <p className="white_txt_sm d-flex justify-content-between mt-0 mb-1"><span className="desc_grey_txt">End Time </span><span className="desc_grey_txt_white font_12">
                                                        {isNaN(parseFloat(saleInfo?.endTime)) ? 0 :(padToDigits(new Date(parseFloat(saleInfo?.endTime) * 1000).toLocaleDateString()) + "  "
                                                            + padToDigits(new Date(parseFloat(saleInfo?.endTime) * 1000).getHours()) + ":" + padToDigits(new Date(parseFloat(saleInfo?.endTime) * 1000).getMinutes()) + ":"
                                                            + padToDigits(new Date(parseFloat(saleInfo?.endTime) * 1000).getSeconds()) + "(GMT)")}
                                                    </span></p>
                                                    <p className="white_txt_sm d-flex justify-content-between mt-0 mb-1"><span className="desc_grey_txt">Vesting Claim</span><span className="desc_grey_txt_white font_12"> {saleInfo && saleInfo?.isVested ? 'Enabled' : 'Disabled'}</span></p>
                                                    <p className="white_txt_sm d-flex justify-content-between mt-0 mb-1"><span className="desc_grey_txt">Listing</span><span className="desc_grey_txt_white font_12">  {saleInfo && saleInfo?.isPancake ? 'Enabled' : 'Disabled'}</span></p>
                                                    <p className="white_txt_sm d-flex justify-content-between mt-0 mb-1"><span className="desc_grey_txt">Liquidity Lockup Time</span><span className="desc_grey_txt_white font_12">{isSaleEnd == true || saleInfo?.isClaimable == false ? isNaN(parseFloat(saleInfo?.lpUnlockon)) ?  0 : (parseFloat(saleInfo?.lpUnlockon) * 1000) / (1000 * 60 * 60 * 24) : lockDaysCal()} Days</span></p>
                                                    <p className="white_txt_sm d-flex justify-content-between mt-0 mb-1"><span className="desc_grey_txt">Liquidity Percentage</span><span className="desc_grey_txt_white font_12">{saleInfo &&  isNaN(parseFloat(saleInfo.liquidityPercent)) ? 0 : parseFloat(saleInfo.liquidityPercent)}%</span></p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-12 col-md-6 col-lg-6 mb-4">
                                        <div className="card_bg card h-100">
                                            <div className="card-body">
                                                <p className="white_txt_sm d-flex justify-content-between mt-0 mb-1">
                                                    <span className="desc_grey_txt">Token Address </span>
                                                    <span className='text-end'><a href={`${CHAINS[getChainId()].Testnet}/${saleInfo && saleInfo.tokenAddress}`} target="_blank" className="link_grn_new word_brsk_tect_new">{saleInfo && saleInfo?.tokenAddress}</a></span>
                                                </p>
                                                <p className="white_txt_sm d-flex justify-content-between mt-0 mb-1"><span className="desc_grey_txt">Access Type</span>
                                                    {saleInfo && saleInfo.isWhitelisted ?
                                                        <span className="desc_grey_txt_white font_12">Public</span> :
                                                        <span className="desc_grey_txt_white font_12">Public</span>}
                                                </p>
                                                <p className="white_txt_sm d-flex justify-content-between mt-0 mb-1"><span className="desc_grey_txt">Status </span>
                                                    {saleInfo && isSaleLive(parseFloat(saleInfo.startTime), parseFloat(saleInfo.endTime), saleInfo.isPresaleOpen) ?
                                                        <span className="desc_grey_txt_white font_12">Live</span> :
                                                        isUpcoming(parseFloat(saleInfo.startTime)) ?
                                                            <span className="desc_grey_txt_white font_12">Upcoming</span> :
                                                            <span className="desc_grey_txt_white font_12">Closed</span>
                                                    }
                                                </p>
                                                {/* <p className="white_txt_sm d-flex justify-content-between mt-0 mb-1"><span className="desc_grey_txt">Affiliate Percentage </span><span className="desc_grey_txt_white font_12">{AffliateInfo[0] == undefined ? 0 : parseFloat(AffliateInfo[0]) / 10 ** 18} %</span></p> */}

                                                {/* <p className="white_txt_sm d-flex justify-content-between mt-0 mb-1"><span className="desc_grey_txt">Minimum Buy </span><span className="desc_grey_txt_white font_12">0.000 </span></p>
                    <p className="white_txt_sm d-flex justify-content-between mt-0 mb-1"><span className="desc_grey_txt">Maximum Buy </span><span className="desc_grey_txt_white font_12">1.000 </span></p> */}

                                                {/* <p className="white_txt_sm d-flex justify-content-between mt-0 mb-1"><span className="desc_grey_txt">You Purchased </span>
                                                    <span className="desc_grey_txt_white font_12"> {userData && userData?.userInvested == undefined ? 0 : (parseInt(userData?.userInvested) / 10 ** parseInt(saleInfo.decimals)).toFixed(3)} {saleInfo && saleInfo.symbol}</span></p> */}
                                            </div>
                                        </div>
                                    </div>


                                    <div className={saleInfo?.owner == getWalletAddress() ? 'col-12 col-md-6 col-lg-6 mb-4' : "d-none"}>
                                        <div className='card_bg card h-100'>
                                            <div className='card-body'>
                                                <p className='text-white'>Owner Zone</p>
                                                <hr className='hr_green' />
                                                {/* <div className='col-12 col-md-12 mb-3 px-0'>
                                                    <p className='input_desc_sm'>Sale Type</p>

                                                    <div className="custom-control custom-radio mb-2">
                                                        <input type="checkbox" id="customRadio1" name="customRadio" className="custom-control-input" checked={saleInfo && saleInfo?.isWhitelisted ? false : true} />
                                                        <label className="custom-control-label" for="customRadio1">Public</label>
                                                    </div>

                                                    <div className="custom-control custom-radio mb-2">
                                                        <input type="checkbox" id="customRadio2" name="customRadio" className="custom-control-input" checked={saleInfo && saleInfo?.isWhitelisted ? true : false} />
                                                        <label className="custom-control-label" for="customRadio2">Whitelist</label>
                                                    </div>


                                                </div> */}


                                                {/* <div className='whitelist_div'>



                                                    {saleInfo && saleInfo?.isWhitelisted ?
                                                        <div className='whitelist_div'>
                                                            <button className="orange_small_primary w-100 mb-2"
                                                                onClick={handleShowWhitelist}
                                                            >
                                                                Add users to whitelist
                                                            </button>
                                                            <button className="orange_small_secondary w-100 mb-2"
                                                                onClick={handleShowRemovewhitelist}>
                                                                Remove users from whitelist
                                                            </button>
                                                            
                                                        </div> : ""}


                                                    {saleInfo && saleInfo?.isWhitelisted ?
                                                        <button onClick={() => Handlewhitelist(false)} className="orange_small_primary w-100 mb-2">
                                                            Disable whitelist
                                                        </button> :
                                                        <button onClick={() => Handlewhitelist(true)} className="orange_small_primary w-100 mb-2">
                                                            Enable whitelist
                                                        </button>}
                                                </div> */}

                                                {console.log("issalelive", parseFloat(saleInfo.startTime), parseFloat(saleInfo.endTime), (saleInfo.isPresaleOpen), isSaleLive(parseFloat(saleInfo.startTime), parseFloat(saleInfo.endTime), parseFloat(saleInfo.isPresaleOpen)))}
                                                <div className='col-12 col-md-12 mb-3 px-0'>
                                                    <p className='input_desc_sm'>Pool Actions</p>
                                                    <button className="orange_small_secondary w-100 mb-2"
                                                        onClick={() => stopSaleEvent()}
                                                        // disabled={!isSaleLive(parseFloat(saleInfo.startTime), parseFloat(saleInfo.endTime), (saleInfo.isPresaleOpen))}
                                                    // disabled = {!saleInfo.isPresaleOpen}
                                                    disabled = {!saleInfo.isPresaleOpen}
                                                    >
                                                        Stop Sale
                                                    </button>

                                                    {saleInfo && saleInfo.LaunchpadType ? <button className="orange_small_primary w-100 mb-2"
                                                        onClick={() => Finalisesale()} disabled={checkbalance < checkdeposittoken}
                                                    >
                                                        Finalize
                                                    </button> :

                                                        <button onClick={() => Finalisesale()} className="orange_small_primary w-100 mb-2" 
                                                        // disabled={checkbalance < checkdeposittoken}
                                                        disabled={saleInfo.isPresaleOpen || saleInfo?.isClaimable}
                                                        >
                                                            Finalize
                                                        </button>}

                                                    {checkbalance < checkdeposittoken ?
                                                        <p className='bottom_text mb-2'>Please deposit this much of token manually through metamask  </p> : <></>}
                                                    <button className="orange_small_secondary w-100 mb-2" onClick={() => withdrawToken()}
                                                    // disabled={!(((saleInfo?.hardCap / 10 ** this.state.charttokendecimal) * saleInfo?.presaleRate) >
                                                    //   ((saleInfo?.hardCap / 10 ** this.state.charttokendecimal) * saleInfo?.presaleRate) - (this.state.unsoldtoken))}
                                                    >
                                                        Get Unsold {saleInfo?.symbol}
                                                    </button>
                                                    <button className="orange_small_primary w-100 mb-2" onClick={() => BNBwithdraw()}
                                                    // disabled={this.hidewithdraw}
                                                    >
                                                        Withdraw {buyToken}
                                                    </button>


                                                </div>
                                            </div>
                                        </div>
                                    </div>


                                </div>

                            </div>

                        </div>
                    </Col>
                </Row>
            </Container>
            {showDescription && <EditDescriptionModal show={showDescription} handleClose={handleCloseDescription} saleData={() => { refreshData() }} SaleInfo={saleInfo} />}
            {showWhitelist && <WhitelistModal show={showWhitelist} handleClose={handleCloseWhitelist} saleAddress={saleInfo.saleAddress} buyToken={buyToken} />}
            {showRemovewhitelist && <RemoveWhitelistModal show={showRemovewhitelist} saleAddress={saleInfo.saleAddress} handleClose={handleCloseRemovewhitelist} />}


            {/* {whitelistModal && <WhitelistModal  onDismiss={() => this.setState({ whitelistModal: false })} modalShowHide={() => { this.Showmodal() }} />}
          {removewhitelistModal && <RemoveWhitelistmodal  onDismiss={() => this.setState({ removewhitelistModal: false })} />}
        */}
        </>
    )
}

export default LaunchpadDetail