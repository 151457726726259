import React, { useRef, useState, useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import Sidebar from "../../Components/Sidebar";
import Header from "../../Components/Header";
import Select from "react-select";
import ReactDatatable from "@ashvin27/react-datatable";
import { FiArrowUpRight } from "react-icons/fi";



function Cmslist() {

    return (
        <>
            <Container fluid className="common_bg position-relative">
                <div className="liner"></div>
                <Row>
                    <Col xl={2} lg={0} className='p-0 d-none d-xl-block'>
                        <Sidebar />
                    </Col>
                    <Col xl={10} lg={12} >
                        <Header title={'Faq List'} />
                        <div className="pt-4">
                            <Row>
                                {['Basic', 'IDO Allocation','Tiers & Staking', 'For Projects'].map((item) => {
                                    return (
                                        <Col xl={10} xxl={3}>
                                            <Link className="tnone"to={"/faqtable/" + (item).toString().replace(/\s/g, '').toLowerCase()}>

                                            {/* <Link className="tnone" to="/faqtable"> */}
                                            <div className="card mt-4 py-2">
                                                    <div className="card-body ml-2 mr-2">
                                                        <h3 className="mb-0">{(item)}
                                                            {console.log("datavalue", item)}
                                                            <FiArrowUpRight />
                                                        </h3>
                                                    </div>
                                                </div></Link>
                                          
                                        
                                        </Col>
                                    )
                                })};
                            </Row>
                        </div>
                    </Col>
                </Row>
            </Container>
        </>
    );
}

export default Cmslist;