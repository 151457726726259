import axios from 'axios';
import Cookies from 'universal-cookie';
import { API_URL } from '../Config/env';
import * as admin from "../Routes/AdminRoutes";



export const getsettinghook = async (data) => {
    // console.log("data" , data);
    try {
        // console.log("headers" , headers);
        let respData = await axios.get(API_URL + admin.getsettingApi
            // { params: { skip: data?.skip, limit: data?.limit } }
        )
        console.log("respdata", respData);
        return respData
    }
    catch (err) {
        console.log("err",err)
        return {
            error: err
        }
    }
}

export const addsettinghook = async (data) => {
    // console.log("login data" , data);

    try {
 
        let respdata = await axios({
            'method': 'POST',
            'url': API_URL + admin.addsettingApi,
            data,
        });
        console.log("response", respdata);
        return {
            data: respdata.data
        }
    }
    catch (e) {
        console.log("error", e);
    }
}

export const updatesettinghook = async (data) => {
    // console.log("login data" , data);
    try {

        let respdata = await axios({
            'method': 'POST',
            'url': API_URL + admin.updatesettingApi,
            data
        });
        console.log("response", respdata);
        return {
            data: respdata.data
        }
    }
    catch (e) {
        console.log("error", e);
    }
}