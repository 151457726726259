import React, { useState, useEffect } from 'react'
import { Modal } from 'react-bootstrap';

import { updatesettinghook } from '../Actions/SettingsActions'


import { settingvalidate } from '../Validations/SettingsValidate'

import toast, { Toaster } from 'react-hot-toast';

function EditSettingModal({ show, handleClose, data,getset }) {

  const [settingname, setSettingname] = useState('')
  const [settingvalue, setSettingvalue] = useState('')
  const [errors, setErrors] = useState('')



  useEffect(() => {
    if (!settingname && !settingvalue) {
      setSettingname(data.settingname);
      setSettingvalue(data.settingvalue)
    }
  }, [])


  const handleedit = async () => {
    let payload = {
      id: data?._id,
      settingname: settingname,
      settingvalue: settingvalue
    }
    let validate = settingvalidate(payload);
    if (validate?.isValid) {
      let result = await updatesettinghook(payload);
      console.log("result",result)
      toast.success(result?.data?.data)

      handleClose();
      getset()
    }
    else {
      setErrors(validate.errors)
    }
  }



  return (
    <>
      <Modal centered className='cmn_modal'
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >

        <Modal.Body>
          <div className='cmn_modal_header d-flex justify-content-between align-items-center'>
            <p className='cmn_modal_title'>Edit Settings</p>
            <button className='cmn_modal_closer rounded-5' onClick={handleClose}><i class="fa-solid fa-xmark" /></button>
          </div>

          <div className='mt-4'>
            <div className='rp_singleinput_holder mb-3'>
              <p className='rp_label mb-2'>Settings Name</p>
              <div className='rp_input_holder rounded-2 py-2 px-3 d-flex justify-content-start align-items-center gap-2'>
                <input type='text' placeholder='Settings Name' className='rp_singleInput flex-grow-1' 
                value={settingname}  onChange={(e) => { setSettingname(e?.target?.value) }} />
              </div>
              <span className="text-danger f-12 d-block text-left">{errors?.settingname}</span>

            </div>

            <div className='rp_singleinput_holder mb-3'>
              <p className='rp_label mb-2'>Settings Value</p>
              <div className='rp_input_holder rounded-2 py-2 px-3 d-flex justify-content-start align-items-center gap-2'>
                <input type='text' placeholder='Settings Value' className='rp_singleInput flex-grow-1' value={settingvalue}
                                                onChange={(e) => {
                                                    setSettingvalue(e?.target?.value)
                                                }} />
              </div>
              <span className="text-danger f-12 d-block text-left">{errors?.settingvalue}</span>

            </div>
            <div className='text-end mt-4 pb-3'>
              <button className='orange_small_primary' onClick={handleedit}>Submit</button>
            </div>
          </div>

        </Modal.Body>

      </Modal>
    </>
  )
}

export default EditSettingModal