import React, { useState } from 'react'
import { Col, Container, Row , Button } from 'react-bootstrap'
import Header from '../../Components/Header'
import Sidebar from '../../Components/Sidebar'
import toast, { Toaster } from 'react-hot-toast';
import { useLocation, useHistory } from 'react-router-dom'
import { CKEditor } from 'ckeditor4-react';
import { isEmpty } from '../../common/common';
import { useEffect } from 'react';
import { Registeraccountedit, addRegisteraccount } from '../../Actions/cms';
import {IMG_URL} from '../../Config/env'
//Actions
// import { addFee } from '../Actions/FeeSettingsActions';

//validations
// import { addFeevalidation } from '../Validations/SettingsValidate';

function EditRegisteraccount(props) {
  console.log("props", props);
  const history = useHistory();
  const location = useLocation()
  const detail = location?.state
  console.log("detail", detail);
  // useEffect(() => {
  //   bsCustomFileInput.init()

  // }, [])

  const [ans, setAns] = useState(detail?.answer)
  console.log("ansans", ans);

  const initData = {
    "question": "",
    "answer": ""
  }

  const [formData, setFormData] = useState(initData)
  console.log("formmdata", formData);
  useEffect(() => {
    setFormData(detail)
    //   setAns(detail?.answer)
  }, [props])


  const onEditorChange = (e) => {
    var content_text = e.editor.getData()
    console.log("content_text", content_text);

    setAns(content_text)

  }

  const [status, setStatus] = useState(false)
  const [ValidateError, SetValidateError] = useState('')






  const handlechange = async (e) => {

    e.preventDefault();

    const { id } = e.target
    console.log('dkfjkgjkdjjg', id)
    if (id === "file") {
      setStatus(true)
      var files = e.target.files[0]
      var formdata = formData
      formdata.file = files
      console.log("files", files)
      setFormData({ ...formdata })
    }
    else{
      const { value, id } = e.target;
      var formdata = formData
      formdata[id] = value
      console.log("formmdata", formdata, value, id);
  
      // console.log("files",files)
      setFormData({ ...formdata })
      // setFormData({...formData,'heading':value})
  
      console.log("formdata", formData)
    }

  }

  const Validation = () => {

    let ValidateError = {}


    if (!formData.question) {
      ValidateError.question = "question is required";
    }
    // if (!ans) {
    //   console.log("fdsgdfgdfgdfgdfg");
    //   ValidateError.answer = "answer is required";
    // }
    // if (!formData.file && !formData?.img) {
    //   ValidateError.file = "file is required";
    // }

    SetValidateError(ValidateError)
    return ValidateError
  }

  const handleSubmit = async () => {
    var errors = {};
    var value = Validation();

    if (!isEmpty(value)) {
      console.log("value", value);
      SetValidateError(value);
      toast.error("fix all validation");
    }
    else {
      if (Object.keys(errors).length == 0) {

        console.log("erro length", Object.keys(errors).length, formData)

        // var payload = {
        //   id: formData?._id,
        //   question: formData.question,

        //   answer: ans,

        // }

        const formDatas = new FormData();

        formDatas.append("question", formData.question)
        formDatas.append("answer", ans);
        // formDatas.append("img", formData.file)
        formDatas.append("id", formData._id)

        console.log("payload", formDatas)

        var resp = await Registeraccountedit(formDatas);
        if (resp?.data?.status) {
          toast.success("suucessfully updated")
          setTimeout(() => {
            history.push("/listRegisteraccount")
          }, 1000);

        }
        else return toast.error(resp.data.data.msg)

        //   else {
        //     // const formDatas = new FormData();

        //     // formDatas.append("heading",Encryptdata(formData.heading))
        //     // formDatas.append("id",Encryptdata(formData._id))

        //     // formDatas.append("content",Encryptdata(ans));
        //     // formDatas.append("img",formData.file)
        //     // formDatas.append("action",Encryptdata("edit"))

        //     // console.log("payload",formDatas.get('heading'),formDatas.get('img'))
        //     var datas = {
        //       "heading": formData.heading,
        //       "id": formData._id,
        //       "content": ans,
        //       "img": formData.file,

        //       "action": "edit"
        //     }
        //     var resp = await addpolynyx(datas);
        //     console.log("updataedsfasdfasdfa", resp)
        //     if (resp?.status) {
        //       toast.success("suucessfully updated")
        //       setTimeout(() => {

        //         history.push("/listpolynyx")
        //       }, 1000);

        //     }
        //     else return toast.error(resp.msg)
        //   }



      }
    }


  }


  return (
    <>
      <Container fluid className='common_bg position-relative'>
        <div className='liner'></div>
        <Row>
          <Col xl={2} lg={0} className='p-0 d-none d-xl-block'>
            <Sidebar />
          </Col>
          <Col xxl={7} xl={8} lg={12}>
            <Header title={'Add Fee'} />
            <div className='mt-5 profile_holder ps-lg-3'>
            <Button className='orange_small_primary mb-4' onClick={()=>history.goBack()}> <span>Back</span> </Button>

              <Row>
                <Col lg={7} className='mb-4'>
                  <div className='rp_singleinput_holder'>
                    <p className='rp_label mb-2'>Title</p>
                    <div className='rp_input_holder rounded-2 py-2 px-3 d-flex justify-content-start align-items-center gap-2'>
                      <input type='text' placeholder='Fee Name' className='rp_singleInput flex-grow-1' id='question'
                        value={formData?.question} onChange={(e) => handlechange(e)} />

                    </div>
                    <span className="text-danger f-12 d-block text-left">{ValidateError?.question}</span><br />
                    <p className='rp_label mb-2'>Description</p>
                    {<CKEditor
                      initData={ans}
                      onChange={(e) => onEditorChange(e)}
                    // onChange={onEditorChange}

                    />}
                  </div>
                  {/* {formData?.img && <img className='mb-2' src={status ? URL.createObjectURL(formData?.file) : `${IMG_URL}/RegisterAcc/${formData?.img}`} height={100} width={100} />}
                  <br />
                  <input type="file" id="file" onChange={(e) => handlechange(e)} /> */}
                </Col>


              </Row>
              <button className='orange_small_primary' onClick={() => handleSubmit()}>Submit</button>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  )
}

export default EditRegisteraccount