import React, { Component, useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import { Col, Container, Row,Button } from 'react-bootstrap'
import Header from '../../Components/Header'
import Sidebar from '../../Components/Sidebar'

import toast, { Toaster } from 'react-hot-toast';
import { useHistory } from "react-router-dom";

import { addsociallinks } from '../../Actions/cms.js';


export function Addsocial() {

    const history = useHistory();




    const History = useHistory();
    const initData = {
        "website": "",
        "img": "",
        "link": ""
    }

    const [formData, setFormData] = useState(initData)
    const [status, setStatus] = useState(false)
    const [dataurl, setDataurl] = useState("")

    const {
        website,
        link

    } = formData





    const handlechange = async (e) => {

        e.preventDefault();
        const { id } = e.target;
        if (id == "file") {
            // const reader = new FileReader();
            var files = e.target.files[0]
            console.log("files in cms", files)
            // var filess=formData.files
            // var indexx=formData.indexx
            // indexx.push(index)
            // filess.push(files)
            let formdata = { ...formData, ...{ "img": files } }
            setFormData(formdata)
            setStatus(true)
        } else {
            const { id, value } = e.target
            console.log("value", value)
            let formdata = { ...formData, ...{ [id]: value } }
            setFormData(formdata)
            console.log("formval", formData)
        }

    }

    const handleSubmit = async () => {




        var errors = {};

        if (!formData.img) {
            errors.img = "logo cannot be empty"
            return toast.error("logo cannot be empty")
        }
        if (!formData.website) {
            errors.answer = "website name cannot be empty"
            return toast.error("website name cannot be empty")
        }

        if (!formData.link) {
            errors.answer = "website link cannot be empty"
            return toast.error("website link cannot be empty")
        }

        if (Object.keys(errors).length == 0) {

            console.log("erro length", Object.keys(errors).length, formData)

            var FormDatas = new FormData()
            FormDatas.append("website", formData?.website)
            FormDatas.append("link", formData?.link)
            FormDatas.append("img", formData?.img)
            FormDatas.append("action", "add")


            var resp = await addsociallinks(FormDatas);
            console.log("resp", resp)

            if (resp?.data?.data?.status) {
                toast.success(resp?.data?.data?.msg)
                setTimeout(function () {
                    history.push("/listSocial")
                }, 1000);

            }
            else return toast.error(resp?.data?.data?.msg)

        }
    }






    return (

        <Container fluid className='common_bg position-relative'>
            <div className='liner'></div>
            <Row>
                <Col xl={2} lg={0} className='p-0 d-none d-xl-block'>
                    <Sidebar />
                </Col>
                <Col xxl={7} xl={8} lg={12}>
                    <Header title={'Add Fee'} />
                    <div className='mt-5 profile_holder ps-lg-3'>
            <Button className='orange_small_primary mb-4' onClick={()=>history.goBack()}> <span>Back</span> </Button>

                        <Row>
                            <Col lg={7} className='mb-4'>
                                <div className='rp_singleinput_holder'>
                                    <p className='rp_label mb-2'>Title</p>
                                    <div className='rp_input_holder rounded-2 py-2 px-3 d-flex justify-content-start align-items-center gap-2'>
                                        <input type='text' className='rp_singleInput flex-grow-1'
                                            id="website" placeholder="topic" value={formData.website} onChange={(e) => handlechange(e)} />

                                    </div><br/>
                                    <div className='rp_input_holder rounded-2 py-2 px-3 d-flex justify-content-start align-items-center gap-2'>
                                        <input type='text' className='rp_singleInput flex-grow-1'
                                            id="link" placeholder="content" value={formData.link} onChange={(e) => handlechange(e)} />

                                    </div>
                                </div>
                                <br />
                                <input type="file" id="file" onChange={(e) => handlechange(e)} />
                                <br />
                                {formData?.img && <img className='mb-2' src={URL.createObjectURL(formData?.img)} width={90} />}

                            </Col>


                        </Row>
                        <button className='orange_small_primary' onClick={() => handleSubmit()}>Submit</button>
                    </div>
                </Col>
            </Row>
        </Container>
    )

}

export default Addsocial
