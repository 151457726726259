import React, { useEffect, useState } from 'react';
import { Col, Container, Row, InputGroup, FormControl, Form, Button } from 'react-bootstrap';
import Header from '../Components/Header';
import Sidebar from '../Components/Sidebar';
import { Approvetoken, GetAdminfee, UseTokenInfo, createPresale } from '../hooks/useContract';
import toast, { Toaster } from 'react-hot-toast';
import { useHistory } from "react-router-dom";
import { tokennrequestApprove } from '../Actions/cms';
// import toast, { Toaster } from 'react-hot-toast';
import { getWalletAddress } from '../Lib/localStorage';
import { CURRENT_CHAIN_ID, CURRENT_RPC_URL, GetChainIndex, getChainId } from '../hooks/useWeb3';
import { CHAINS, ONEDAYINSECONDS, ZEROTH_ADDRESS } from '../Config/env';
import { toFixedNumber } from '../Lib/FixedNumber';
import { getFees } from '../Actions/FeeSettingsActions';
import { GetAllocationHooks } from '../hooks/Usebackendaxios';


function TokenreqDetail(props) {
    const history = useHistory()
    console.log("property", props)
    let records = props?.location?.state
    console.log("rtecordss", records);
    const [approved, setApproved] = useState([])
    const [approvebutton, setApprovebutton] = useState(false);
    const [withtokenaddress, setWithtokenaddress] = useState("");

    const [LaunchpadType, setLaunchpadType] = useState(false)
    const [isWithoutToken, setIsWithoutToken] = useState(false)
    const [tokenFee, setTokenFee] = useState(0)
    const [currencyFee, setCurrencyFee] = useState(0)
    const [userFee, setUserFee] = useState(5);
    const [getFee, setGetFee] = useState([]);
    const [createdSale, setCreatedSale] = useState('');
    const [allocationlist, setAllocationlist] = useState([]);
    const [selectedallocation, setSelectedallocation] = useState();
    const [selectedtire, setSelectedtire] = useState();


    // const 

    console.log("approvebutton", approvebutton);


    const getFeeSettings = async () => {
        let get = await getFees();
        console.log("getFee", get);
        if (get?.data?.data?.status == true) {
            let data = get?.data?.data?.data
            console.log("DATA", data)
            setCurrencyFee(data[0]?.currencyvalue)
            setTokenFee(data[0]?.tokenvalue)
            setGetFee(get?.data?.data?.data);
            console.log("getFee_getFee", get?.data?.data?.data)
        }
    }


    useEffect(() => {
        // approvetoken()
        // approve()
        fetchdata();
    }, [])

    // const approvetoken = async () => {
    //     try {
    //         let result = await Approvetoken(records?.tokencontractaddress);
    //         console.log("result", result)
    //         if (result) {
    //             setApprovebutton(true)
    //         }
    //     } catch (error) {
    //         console.log("error", error);

    //     }

    // }


    const approve = async (data) => {
        console.log("datadata", data);

        fetchdata();
        approvetoken(data)
    }

    const fetchdata = async () => {
        const Proxydata = await GetAllocationHooks();
        console.log("Proxydata", Proxydata);
        setAllocationlist(Proxydata?.data?.record);
        setSelectedallocation(Proxydata?.data?.record[0]?.name)
        setSelectedtire(Proxydata?.data?.record[0]?.min)
    }

    const approvetoken = async (data) => {
        try {
            // setApprovebutton(true)
            console.log("datasss", data);
            let payload = {
                id: records?._id,
                status: data
            }
            let resp = await tokennrequestApprove(payload)
            console.log("resp", resp.data.data.message);
            if (resp.data.data.status == true) {
                setApproved(resp.data.data.data)
                toast.success(resp.data.data.message)

            }
            else return toast.error(resp.data.data.message)
        } catch (error) {
            console.log("error", error);
        }
    }

    // const disapprove = async () => {
    //     try {
    //         setApprovebutton(false)
    //         if (approvebutton == false) {
    //             toast.success("Approve is cancelled")
    //         }
    //     } catch (error) {
    //         console.log("error", error);
    //     }

    // }

    const GetTokenAddress = async (value) => {
        // debugger
        var Withtokenaddress;
        if (records?.incubation === "BNB") {
            Withtokenaddress = ZEROTH_ADDRESS
            return ZEROTH_ADDRESS;
        } else if (records?.incubation === "DAI") {
            Withtokenaddress = CHAINS[getChainId()].DAI
            return CHAINS[getChainId()].DAI;
        } else if (records?.incubation === "USDC") {
            Withtokenaddress = CHAINS[getChainId()].USDC
            return CHAINS[getChainId()].USDC;
        } else if (records?.incubation === "USDT") {
            Withtokenaddress = CHAINS[getChainId()].USDT
            return CHAINS[getChainId()].USDT;
        }

        console.log("WithTokenaddress", Withtokenaddress);
        // setWithTokenaddress(Withtokenaddress);
        // return withtokenaddress;
    }

    // const useWithtokenInfo = async () => {
    //     // console.log("address", WithTokenaddress, WithTokenaddress != ZEROTH_ADDRESS)
    //     if (records?.incubation != "BNB") {
    //         var usewithToken = await UseTokenInfo(records?.tokencontractaddress)
    //         console.log("usewithToken", usewithToken)
    //         setTokenDecimal(usewithToken?.decimals);
    //     }
    //     else {
    //         setTokenDecimal(18)
    //     }
    // }

    const getStakingAddress = () => {
        try {
            const index = GetChainIndex();
            const ContractAddress = CHAINS[index].LOCKEDSTAKING;
            console.log("ContractAddress", ContractAddress);
            return ContractAddress;
        } catch (e) {
            console.log("error", e);
        }
    }

    const CreateSale = async (data) => {
        // debugger
        // await useWithtokenInfo()
        // window.onbeforeunload = () => window.confirm("You have'nt deposited tokens to start presale !")
        // window.onpopstate = () => window.confirm("You have'nt deposited tokens to start presale !")
        console.log("call");
        let Addresses = [];
        let Values = [];
        let Details = [];
        let isSetter = [];
        const tokeninfo = await UseTokenInfo(records?.tokencontractaddress)
        const wtknaddress = await GetTokenAddress()

        const account = getWalletAddress()
        console.log("account1", account, wtknaddress);

        const startTime = records?.starttime;
        const endTime = records?.endtime;


        const CHAIN_ID = CURRENT_CHAIN_ID();
        const CURRENT_RPC = CURRENT_RPC_URL();
        let ROUTER;
        CHAINS.map((data) => {
            { console.log("datas", data) }
            if ((((data.CHAIN_ID == CHAIN_ID) && (data.RPC_URL == CURRENT_RPC)) && data.ROUTER) != false)
                ROUTER = ((data.CHAIN_ID == CHAIN_ID) && (data.RPC_URL == CURRENT_RPC)) && data.ROUTER;

        })

        const startDiff = parseInt(parseInt((new Date(startTime).getTime())) / 1000);
        const endDiff = parseInt(parseInt((new Date(endTime).getTime())) / 1000);

        const fee = tokenFee
        const bnbfee = currencyFee


        const publicUserAmt = parseFloat(records?.sellingamountpublicusers);
        const whitelistUserAmt = parseFloat(records?.sellingamountwhitelistusers);
        // const XLaunchAddress = getXLaunchAddress()
        // _token 0
        //_router 1
        //owner 2
        //UsewithToken 3
        Addresses.push(records?.tokencontractaddress);
        Addresses.push(ROUTER);
        Addresses.push(records?.tokenowneraddress);
        Addresses.push(wtknaddress)
        Addresses.push(getStakingAddress())
        // Addresses.push(XLaunchAddress)
        // Addresses.push(launchReceipt)
        console.log("addresses", Addresses);

        //_min 0 
        //_max 1
        //_rate 2
        // _soft  3
        // _hard 4
        //_pancakeRate  5
        //_unlockon  6
        // _percent 7
        // _start 8
        //_end 9
        //_vestPercent 10
        //_vestInterval 11
        //_useFee 12
        //_affliateFee 13
        //_minAff 14
        //_maxAff 15
        //_fee (token fee )16
        //_bnbfee 17
        Values.push(toFixedNumber(0 * 10 ** 18).toString());
        Values.push(toFixedNumber(0 * 10 ** 18).toString());
        Values.push(toFixedNumber(parseFloat(records?.tokenprice) * 10 ** 18).toString());
        Values.push(toFixedNumber(parseFloat(records?.softcap) * 10 ** 18).toString());
        Values.push(toFixedNumber(parseFloat(records?.hardcap) * 10 ** 18).toString());
        Values.push(toFixedNumber(parseFloat(0) * 10 ** 18).toString());
        Values.push(records?.lockingdays.toString());
        Values.push(records?.explorelaunchpad?.toString());
        Values.push(startDiff.toString());
        Values.push(endDiff.toString());
        Values.push((parseFloat(records?.vestingperioddays) * ONEDAYINSECONDS).toString());

        Values.push((parseFloat(records?.rewardsvestingperiod) * 100).toString());
        Values.push((userFee).toString())
        Values.push((0 * 10 ** 18).toString())
        Values.push((0).toString())
        Values.push("115792089237316195423570985008687907853269984665640564039457584007913129639935")
        Values.push((fee * 10 ** 18).toString())
        Values.push((bnbfee * 10 ** 18).toString())
        Values.push(toFixedNumber(publicUserAmt * 10 ** 18).toString());
        Values.push(toFixedNumber(whitelistUserAmt * 10 ** 18).toString());
        // Values.push(parseFloat(selectedtire)*100);

        // Values.push(feeind.toString())
        Values.push(((parseFloat(allocationlist?.[1]?.min)) * 100).toString())
        Values.push(((parseFloat(allocationlist?.[0]?.min)) * 100).toString())
        console.log("Values", Values);


        // isAuto 0
        //_isvested 1
        // isWithoutToken 2
        // isWhitelisted 3
        // buyType isBNB or not 4
        // isToken isToken or not 5
        // launchpadtype isnormal or fair
        isSetter.push(true);
        isSetter.push(records?.isvested ? records?.isvested : false);
        isSetter.push(isWithoutToken);
        isSetter.push(false);
        isSetter.push(records?.incubation === "BNB" ? true : false)
        isSetter.push(false)
        isSetter.push(true)
        // isSetter.push(this.state.isAffiliate);
        console.log("isSetter", isSetter);

        // description 0 
        // website,twitter,telegram 1,2,3
        //logo 4
        //name 5
        //symbol 6
        // githup 7
        // instagram 8
        // whatsapp 9
        // linkedin 10
        // facebook 11
        //tier type 12
        Details.push(records?.Description)
        Details.push(records?.website);
        Details.push(records?.Twitter);
        Details.push(records?.Telegram);
        Details.push(records?.logourl);
        Details.push(tokeninfo?.name);
        Details.push(tokeninfo?.symbol);
        Details.push(records?.Github);
        Details.push(records?.Instagram);
        Details.push(records?.Discord);
        Details.push(records?.Reddit);
        Details.push(records?.youtube);
        Details.push(records?.bannerurl);
        // Details.push(selectedallocation);
        // console.log("Details", Details);


        //  const data = this.validate()
        //  if(data)
        //      return false;


        console.log("DATA : ", Addresses, Values, isSetter, Details,"data",data,records?._id)
        const sale = await createPresale(Addresses, Values, isSetter, Details, account,data,records?._id)
        console.log("creating____sale", sale)
        console.log("saleDATA : ", Addresses, Values, isSetter, Details)

        setCreatedSale(sale);

    }


    return (

        <>
            <Container fluid className='common_bg position-relative'>
                <div className='liner'></div>
                <Row>
                    <Col xl={2} lg={0} className='p-0 d-none d-xl-block'>
                        <Sidebar />
                    </Col>
                    <Col xl={10} lg={12}>
                        <Header title={'Token request Detail'} />
                        <div className='mt-5 profile_holder ps-lg-3'>
                            <div className="px-1 containers detail_page">
                                <div className="row mt-5">
                                    <div className="col-12 col-md-12 col-lg-10 col-xl-10 col-xxl-9 mb-4">
                                        <Button className='orange_small_primary mb-4' onClick={() => history.goBack()}> <span>Back</span> </Button>
                                        <div className="card_bg card  mb-4">
                                            <div className="card-body token_list">
                                                <div className='d-block d-sm-flex'><p className='fw-600'> IDO or/and incubation : </p> {records?.ido?.map((data) => 
                                                <p className='ms-0 ms-sm-2'> {data}</p>
                                            )}</div>
                                                <div className='d-block d-sm-flex'><p className='fw-600'> Name : </p> <p className='ms-0 ms-sm-2'> {records?.name}</p></div>
                                                <div className='d-block d-sm-flex'><p className='fw-600'> Website : </p> <p className='ms-0 ms-sm-2'> {records?.website}</p></div>
                                                <div className='d-block d-sm-flex'><p className='fw-600'> Project Description : </p> <p className='ms-0 ms-sm-2'> {records?.Description}</p></div>
                                                
                                                <div className='d-block d-sm-flex'><p className='fw-600'> Social Media : </p> <p className='ms-0 ms-sm-2'> {records?.socialmedia}</p></div>
                                                <div className='d-block d-sm-flex'><p className='fw-600'> Token CreateSale Address : </p> <p className='ms-0 ms-sm-2'> {records?.tokencontractaddress}</p></div>

                                                <div className='d-block d-sm-flex'><p className='fw-600'> Token Name : </p> <p className='ms-0 ms-sm-2'> {records?.tokenname}</p></div>
                                                <div className='d-block d-sm-flex'><p className='fw-600'> Token Symbol : </p> <p className='ms-0 ms-sm-2'> {records?.tokensymbol}</p></div>
                                                {/* <div className='d-block d-sm-flex'><p className='fw-600'> Token Decimal : </p> <p className='ms-0 ms-sm-2'> {records?.tokendecimal}</p></div> */}

                                                {/* <div className='d-block d-sm-flex'><p className='fw-600'> Token Price per BNB : </p> <p className='ms-0 ms-sm-2'> {records?.tokenprice}</p></div> */}

                                                <div className='d-block d-sm-flex'><p className='fw-600'> Token Utility : </p> <p className='ms-0 ms-sm-2'> {records?.tokenutility}</p></div>
                                                <div className='d-block d-sm-flex'><p className='fw-600'> Tokenomics : </p> <p className='ms-0 ms-sm-2'> {records?.tokenomics}</p></div>
                                                <div className='d-block d-sm-flex'><p className='fw-600'> Ido Date : </p> <p className='ms-0 ms-sm-2'> {new Date(records?.starttime)?.toLocaleDateString()}</p></div>
                                                {/* <div className='d-block d-sm-flex'><p className='fw-600'> startTime : </p> <p className='ms-0 ms-sm-2'> {records?.starttime}</p></div>
                                                <div className='d-block d-sm-flex'><p className='fw-600'> EndTime : </p> <p className='ms-0 ms-sm-2'> {records?.endtime}</p></div>
                                                <div className='d-block d-sm-flex'><p className='fw-600'> Hardcap : </p> <p className='ms-0 ms-sm-2'> {records?.hardcap}</p></div>
                                                <div className='d-block d-sm-flex'><p className='fw-600'> Softcap : </p> <p className='ms-0 ms-sm-2'> {records?.softcap}</p></div>
                                                <div className='d-block d-sm-flex'><p className='fw-600'> Locking Days : </p> <p className='ms-0 ms-sm-2'> {records?.lockingdays}</p></div>
                                                <div className='d-block d-sm-flex'><p className='fw-600'> Total selling amount : </p> <p className='ms-0 ms-sm-2'> {records?.totalsellingammount}</p></div>
                                                <div className='d-block d-sm-flex'><p className='fw-600'> Selling amount public users : </p> <p className='ms-0 ms-sm-2'> {records?.sellingamountpublicusers}</p></div>
                                                <div className='d-block d-sm-flex'><p className='fw-600'> Selling amount whitelist users : </p> <p className='ms-0 ms-sm-2'> {records?.sellingamountwhitelistusers}</p></div>
                                                <div className='d-block d-sm-flex'><p className='fw-600'> Locking days : </p> <p className='ms-0 ms-sm-2'> {records?.lockingdays}</p></div>
                                                <div className='d-block d-sm-flex'><p className='fw-600'> Explore launchpad : </p> <p className='ms-0 ms-sm-2'> {records?.explorelaunchpad}</p></div>


                                                {records?.vestingperioddays !== 0 && <div className='d-block d-sm-flex'><p className='fw-600'> Vesting period days : </p> <p className='ms-0 ms-sm-2'> {records?.vestingperioddays}</p></div>}
                                                {records?.rewardsvestingperiod !== 0 && <div className='d-block d-sm-flex'><p className='fw-600'> Rewards vesting period : </p> <p className='ms-0 ms-sm-2'> {records?.rewardsvestingperiod}</p></div>}

                                                <div className='d-block d-sm-flex'><p className='fw-600'> Logourl : </p> <p className='ms-0 ms-sm-2'> {records?.logourl}</p></div>

                                                <div className='d-block d-sm-flex'><p className='fw-600'> Bannerurl : </p> <p className='ms-0 ms-sm-2'> {records?.bannerurl}</p></div>

                                                <div className='d-block d-sm-flex'><p className='fw-600'> Website : </p> <p className='ms-0 ms-sm-2'> {records?.website}</p></div>
                                                <div className='d-block d-sm-flex'><p className='fw-600'> Twitter : </p> <p className='ms-0 ms-sm-2'> {records?.Twitter}</p></div>
                                                <div className='d-block d-sm-flex'><p className='fw-600'> Telegram : </p> <p className='ms-0 ms-sm-2'> {records?.Telegram}</p></div>

                                                <div className='d-block d-sm-flex'><p className='fw-600'> Instagram : </p> <p className='ms-0 ms-sm-2'> {records?.Instagram}</p></div>

                                                <div className='d-block d-sm-flex'><p className='fw-600'> Facebook : </p> <p className='ms-0 ms-sm-2'> {records?.Facebook}</p></div>
                                                <div className='d-block d-sm-flex'><p className='fw-600'> Github : </p> <p className='ms-0 ms-sm-2'> {records?.Github}</p></div>
                                                <div className='d-block d-sm-flex'><p className='fw-600'> Discord : </p> <p className='ms-0 ms-sm-2'> {records?.Discord}</p></div>
                                                <div className='d-block d-sm-flex'><p className='fw-600'> Reddit : </p> <p className='ms-0 ms-sm-2'> {records?.Reddit}</p></div>
                                                <div className='d-block d-sm-flex'><p className='fw-600'> youtube : </p> <p className='ms-0 ms-sm-2'> {records?.youtube}</p></div>
                                                <div className='d-block d-sm-flex'><p className='fw-600'> Description : </p> <p className='ms-0 ms-sm-2'> {records?.Description}</p></div> */}
<div className='d-block d-sm-flex'><p className='fw-600'> Funding Goals : </p> <p className='ms-0 ms-sm-2'> {records?.fundinggoal}</p></div>
<div className='d-block d-sm-flex'><p className='fw-600'> Project Teams : </p> <p className='ms-0 ms-sm-2'> {records?.projectteam}</p></div>
<div className='d-block d-sm-flex'><p className='fw-600'> Project Advisor : </p> <p className='ms-0 ms-sm-2'> {records?.projectadvisor}</p></div>
<div className='d-block d-sm-flex'><p className='fw-600'> Investors : </p> <p className='ms-0 ms-sm-2'> {records?.investors}</p></div>

<div className='d-block d-sm-flex'><p className='fw-600'> Fundraised : </p> <p className='ms-0 ms-sm-2'> {records?.fundraised}</p></div>
{/* <div className='d-block d-sm-flex'><p className='fw-600'> Fundraised : </p> <p className='ms-0 ms-sm-2'> {records?.fundraised}</p></div> */}

                                                <div className='d-block d-sm-flex'><p className='fw-600'> Project Roadmap : </p> <p className='ms-0 ms-sm-2'> {records?.projectroadmap}</p></div>
                                                <div className='d-block d-sm-flex'><p className='fw-600'> Other Launchpads : </p> <p className='ms-0 ms-sm-2'> {records?.otherlaunchpads}</p></div>
                                                <div className='d-block d-sm-flex'><p className='fw-600'> Market Maker : </p> <p className='ms-0 ms-sm-2'> {records?.marketmaker}</p></div>
                                                <div className='d-block d-sm-flex'><p className='fw-600'> Token Listing : </p> <p className='ms-0 ms-sm-2'> {records?.tokenlisting}</p></div>
                                                <div className='d-block d-sm-flex'><p className='fw-600'> Additional Support : </p> {records?.additionalsupport?.map((data) => 
                                                <p className='ms-0 ms-sm-2'> {data}</p>
                                            )}</div>
                                                <div className='d-block d-sm-flex'><p className='fw-600'> Contact Information : </p> <p className='ms-0 ms-sm-2'> {records?.contactinformation}</p></div>
                                                <div className='d-block d-sm-flex'><p className='fw-600'> Additional Information : </p> <p className='ms-0 ms-sm-2'> {records?.additionalinformation}</p></div>

                                                {/* <div className='pt-4 pb-4'>
                                                    {approvebutton !== true ?
                                                        <Button className='orange_small_primary me-3' onClick={() => CreateSale("Approve")}> <span>Approve</span> </Button>
                                                        :
                                                        <Button className='orange_small_primary me-3'> <span>Approved</span> </Button>
                                                    }

                                                    <div className='pt-4 pb-4'>


                                                        <Button className='orange_small_primary' onClick={() => approve("DisApprove")}> <span>{approved?.status == "DisApprove" ? "DisApproved" : "DisApprove"}</span> </Button>

                                                    </div>

                                                </div> */}
                                            </div>
                                            <br />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>

        </>
    )
}

export default TokenreqDetail;
