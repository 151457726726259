import {isEmpty} from '../Lib/isEmpty';

var errors = {};
var emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,6}))$/;
var letters = /^[A-Za-z\s]*$/;
var numbers = /^[0-9]+$/;
var regex_no = /^[ A-Za-z0-9_@./#&+-]*$/
var url = /^https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_\+.~#?&\/=]*)$/


export const validatelogin = (data) => {
    try {

        let errors = {};
        let isValid = true;
        if (isEmpty(data?.email)) {
            isValid = false;
            errors.email = "Email field is required";

        }
        else if (!(emailRegex.test(data?.email))) {
            isValid = false;
            errors.email = "Invalid email"
        }
        if (isEmpty(data.password)) {
            isValid = false;
            errors.password = "Password field is required";
        }

        return {
            errors,
            isValid: isEmpty(errors)

        };
    }
    catch (err) {
        console.log("validatelogin", err)
    }
};

export const validateemail = (data) => {
    try {
        let errors = {};
        let isValid = true;
        
        if (isEmpty(data?.email)) {
            isValid = false;
            errors.email = "Email field is required";

        }
        else if (!(emailRegex.test(data?.email))) {
            isValid = false;
            errors.email = "Invalid email"
        }

        return {
            errors,
            isValid: isEmpty(errors)
        };
    }
    catch (err) {
        console.log("validateemail", err)
    }
};


export const validatepassword = (data) => {
    try {
        let errors = {};
        let isValid = true;

        if (isEmpty(data?.password)) {
            isValid = false;
            errors.password = "Password field is required";
        }
        if (isEmpty(data?.confirmPass)) {
            isValid = false;
            errors.confirmPass = "Confirm password field is required";
        }
        else if (data?.password !== data?.confirmPass) {
            isValid = false;
            errors.confirmPass = "Passwords must match";
        }
        return {
            errors,
            isValid: isEmpty(errors)
        };
    }
    catch (err) {
        console.log("validatepassword", err)
    }
}


export const validateProfileEmail = (data) => {

    try {
        let errors = {};
        let isValid = true;
        if (isEmpty(data?.newEmail)) {
            isValid = false;
            errors.newEmail = "Email field is required";

        }
        else if (!(emailRegex.test(data?.newEmail))) {
            isValid = false;
            errors.newEmail = "Invalid email"
        }
        return {
            errors,
            isValid: isEmpty(errors)

        };
    }
    catch (err) {
        console.log("validateProfileEmail", err)
    }
};


export const validateProfilePass = (data) => {
    try {
        let errors = {};
        let isValid = true;

        if (isEmpty(data?.oldPassword)) {
            isValid = false;
            errors.oldPassword = "Password field is required";
        }
        if (isEmpty(data?.newPassword)) {
            isValid = false;
            errors.newPassword = "New password field is required";
        }
        else if (data?.oldPassword == data?.newPassword) {
            isValid = false;
            errors.newPassword = "New password should differ from old password";
        }


        return {
            errors,
            isValid: isEmpty(errors)

        };
    }
    catch (err) {
        console.log("validateProfilePass", err)
    }
};