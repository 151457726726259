import React, { useRef, useState, useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Link,useParams } from "react-router-dom";
import Sidebar from "../../Components/Sidebar";
import Header from "../../Components/Header";
import Select from "react-select";
import ReactDatatable from "@ashvin27/react-datatable";
import toast, { Toaster } from 'react-hot-toast';
import { Faqtablist } from "../../Actions/cms";

//hooks
// import { getTotalSalesInfo, getlaunchpaddatalimit, isSaleLive, isUpcoming, DeletePresale } from '../hooks/useContract'


function Faqtable() {
  const { data } = useParams()
console.log("datassss",data);

const [showEdit, setShowEdit] = useState(false);

const handleCloseEdit = () => setShowEdit(false);
const handleShowEdit = () => setShowEdit(true);

//   delete modal

const [showDelete, setShowDelete] = useState(false);

const [getFee, setGetFee] = useState([])
console.log("getfeeeee",getFee);
const [deleteId, setDeleteId] = useState('')
const [editData, setEditData] = useState({})
const [hidestatus, setHidestatus] = useState('')

const handleCloseDelete = () => setShowDelete(false);
const handleShowDelete = () => setShowDelete(true);
  const state = [
    {
      address: "yu676869669869",
      salestype: "PrivateSale",
      symbol: "Test",
      status: "Sale Ended",
      softcap: "2",
      hardcap: "3",
      percentage: "5%",
      startdate: "09/01/2024 13:00:00",
      enddate: "03/02/2024 13:00:00",
      action: "Enable",
      view: "/launchpadDetail"
    },
    {
      address: "yu676869669869",
      salestype: "PrivateSale",
      symbol: "Test",
      status: "Sale Ended",
      softcap: "4",
      hardcap: "5",
      percentage: "10%",
      startdate: "09/01/2024 13:00:00",
      enddate: "03/02/2024 13:00:00",
      action: "Disable",
      view: "/launchpadDetail"

    },
    {
      address: "yu676869669869",
      salestype: "PrivateSale",
      symbol: "Test",
      status: "Sale Ended",
      softcap: "2",
      hardcap: "3",
      percentage: "5%",
      startdate: "09/01/2024 13:00:00",
      enddate: "03/02/2024 13:00:00",
      action: "Enable",
      view: "/launchpadDetail"

    },

  ];


  // console.log(state.records[0].status.props,"asdfasfasdfasdf");

  const columns = [
    {
      key: "symbol",
      text: "S.No",
      className: "symbol",
      align: "center",
      sortable: false,
      cell:(record,index) => index+1
    },
    {
      key: "symbol",
      text: "Faq question",
      className: "symbol",
      align: "center",
      sortable: false,
      cell:(record) => record?.question
    },
    {
      key: "symbol",
      text: "Faq Answer",
      className: "symbol",
      align: "center",
      sortable: false,
      cell: records => records.answer.length > 50 ? <div dangerouslySetInnerHTML={{ __html: records.answer.slice(0, 45)+'...' }}></div> : <div dangerouslySetInnerHTML={{ __html: records.answer }}></div>


    },
    {
      key: "view",
      text: "edit",
      className: "view",
      align: "center",
      sortable: false,
      cell: (record) => {
       
        return (
          <div>
            {/* {console.log(style, "style")} */}
            {console.log("recordsdfsdf",record)}
            {/* to={{ pathname: '/editaboutuser', state: rec }}> */}
            <Link to={{pathname:`/editfaq/${data}`,state: record }} className="table_btn_theme_inverse">Edit</Link>
          </div>
        )
      }
    },
  ];

  const config = {
    page_size: 3,
    length_menu: [10, 20, 50],
    filename: "Emailtemplates",

    language: {
      length_menu: "Show _MENU_ result per page",
      filter: "Filter in records...",
      info: "Showing _START_ to _END_ of _TOTAL_ records",
      pagination: {
        first: "First",
        previous: "Previous",
        next: "Next",
        last: "Last",
      },
      // loading:true,
      // no_data_text: loading ? 'Loading...' : "No data found!",
      // loading_text: loading ? 'Loading...': 
    },
    show_length_menu: false,
    show_filter: false,
    show_pagination: true,
    show_info: false,
  };

  const extraButtons = [
    {
      className: "btn btn-primary buttons-pdf",
      title: "Export TEst",
      children: [
        <span>
          <i
            className="glyphicon glyphicon-print fa fa-print"
            aria-hidden="true"
          ></i>
        </span>,
      ],
      onClick: (event) => {
        console.log(event);
      },
    },
    {
      className: "btn btn-primary buttons-pdf",
      title: "Export TEst",
      children: [
        <span>
          <i
            className="glyphicon glyphicon-print fa fa-print"
            aria-hidden="true"
          ></i>
        </span>,
      ],
      onClick: (event) => {
        console.log(event);
      },
      onDoubleClick: (event) => {
        console.log("doubleClick");
      },
    },
  ];



  useEffect(() => {
    // getFeeSettings()
    getFaq()
}, [])



const getFaq = async () => {

    let get = await Faqtablist({data});
    console.log("getFeespro", get,get?.data?.data?.data);
    if (get?.data?.data?.status == true) {
        setGetFee(get?.data?.data?.data);
        console.log("getFee_getFee", get?.data?.data?.data)
    }
}
// const hideShowCategory = async (data, _id) => {
//     console.log("datasddtaat", data, _id);
//     let payload = { hideshow: data, id: _id }
//     let resp = await sprofeaturehide(payload);
//     console.log("respresp", resp);
//     if (resp?.data?.status) {
//         toast.success(resp.data.message)
//         setTimeout(() => {
//             window.location.reload();
//         }, 2000);
//     }
//     else toast.error(resp.data.message)

// }

  







  return (
    <>
      <Container fluid className="common_bg position-relative">
        <div className="liner"></div>
        <Row>
          <Col xl={2} lg={0} className='p-0 d-none d-xl-block'>
            <Sidebar />
          </Col>
          <Col xl={10} lg={12}>
            <Header title={'Faq List'} />

   

            <div className="page_position">


              <div className="common_page_scroller mt-5 pe-2">
              <div><Link to={`/addfaq/${data}`}><button className='orange_small_primary mb-4' type='button'>Add Faq</button></Link></div>
                <div className="exchange_table_holder launchpad_table dashboard_box rounded-3 mt-4">
                  <ReactDatatable
                    config={config}
                    records={getFee}
                    columns={columns}
                    extraButtons={extraButtons}
                  
                  />
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default Faqtable;
