import React, { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap';
import toast, { Toaster } from 'react-hot-toast';


//Actions
import { editFee } from '../Actions/FeeSettingsActions';

//Validations
import { editFeevalidation } from '../Validations/SettingsValidate';

function EditFeeModal({ show, handleClose, editData, getSettings }) {

    const [currencyvalue, setCurrencyvalue] = useState('')
    const [tokenvalue, setTokenvalue] = useState('')
    const [errors, setErrors] = useState('')


    useEffect(() => {
        console.log("this.props.", editData)
        if (!currencyvalue && !tokenvalue) {
            setCurrencyvalue(editData?.currencyvalue);
            setTokenvalue(editData?.tokenvalue)
        }
    },[])


    const handleedit = async () => {
        let payload = {
            currencyvalue: currencyvalue,
            tokenvalue: tokenvalue
        }
        let validate = editFeevalidation(payload);
        if (validate?.isValid) {
            let data = {
                id: editData?._id,
                currencyvalue: currencyvalue,
                tokenvalue: tokenvalue
            }
            let result = await editFee(data);
            console.log("result", result)
            toast.success(result?.data?.data?.message)
            handleClose();
            getSettings()
        }
        else {
            setErrors(validate.errors)
        }
    }


    return (
        <>
            <Modal centered className='cmn_modal'
                show={show}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
            >

                <Modal.Body>
                    <div className='cmn_modal_header d-flex justify-content-between align-items-center'>
                        <p className='cmn_modal_title'>Edit Fee</p>
                        <button className='cmn_modal_closer rounded-5' onClick={handleClose}><i class="fa-solid fa-xmark" /></button>
                    </div>

                    <div className='mt-4'>
                        <div className='rp_singleinput_holder mb-3'>
                            <p className='rp_label mb-2'>Fee Name</p>
                            <div className='rp_input_holder rounded-2 py-2 px-3 d-flex justify-content-start align-items-center gap-2'>
                                <input type='text' placeholder='Fee Name' className='rp_singleInput flex-grow-1' name='feeName'
                                    value={editData?.feeName}  />
                            </div>
                        </div>

                        <div className='rp_singleinput_holder mb-3'>
                            <p className='rp_label mb-2'>Currency Value</p>
                            <div className='rp_input_holder rounded-2 py-2 px-3 d-flex justify-content-start align-items-center gap-2'>
                                <input type='text' placeholder='Currency Value' className='rp_singleInput flex-grow-1' value={currencyvalue}
                                    onChange={(e) => {
                                        setCurrencyvalue(e?.target?.value)
                                    }} />
                            </div>
                            <span className="text-danger f-12 d-block text-left">{errors?.currencyvalue}</span>

                        </div>

                        <div className='rp_singleinput_holder mb-3'>
                            <p className='rp_label mb-2'>Token Value</p>
                            <div className='rp_input_holder rounded-2 py-2 px-3 d-flex justify-content-start align-items-center gap-2'>
                                <input type='text' placeholder='Token Value' className='rp_singleInput flex-grow-1' name='tokenvalue'
                                    value={tokenvalue} onChange={(e) => { setTokenvalue(e?.target?.value) }} />
                            </div>
                            <span className="text-danger f-12 d-block text-left">{errors?.tokenvalue}</span>

                        </div>

                        <div className='text-end mt-4 pb-3'>
                            <button className='orange_small_primary' onClick={()=>{handleedit()}}>Submit</button>
                        </div>
                    </div>

                </Modal.Body>

            </Modal>
        </>
    )
}

export default EditFeeModal