import axios from 'axios';
import { API_URL } from '../Config/env';
import * as admin from "../Routes/AdminRoutes";



export const addFee = async (data) => {
    console.log("addIpAddress", data);
    try {

        let respdata = await axios({
          'method': 'POST',
          'url': API_URL + admin.addFee,
          data,
        });
        console.log("response", respdata);
        return {
          data: respdata
        }
   
    }
    catch (e) {
      console.log("error", e);
    }
  }
  
  export const editFee = async (data) => {
    console.log("addIpAddress", data);
    try {
     
        let respdata = await axios({
          'method': 'POST',
          'url': API_URL + admin.editFee,
          data,
        });
        console.log("response", respdata);
        return {
          data: respdata
        }
    }
    catch (e) {
      console.log("error", e);
    }
  } 
  
  export const deleteFee = async (data) => {
    console.log("deleteIpAddress", data);
    try {
        let respdata = await axios({
          'method': 'POST',
          'url': API_URL + admin.deleteFee,
          data,
        });
        console.log("response", respdata);
        return {
          data: respdata
        }
    }
    catch (e) {
      console.log("error", e);
    }
  }
  
  export const getFees = async (data) => {
    // console.log("data" , data);
    try {
      // console.log("headers" , headers);
      let respData = await axios.get(API_URL + admin.getFee)
  
      console.log("respdata", respData);
      return {
        data: respData
      }
    }
    catch (err) {
      return {
        error: err
      }
    }
  }