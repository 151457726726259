import React, { Component, useState, useEffect } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { Col, Container, Row } from 'react-bootstrap'
import Sidebar from '../../Components/Sidebar'
import Header from '../../Components/Header'
import ReactDatatable from '@ashvin27/react-datatable';
// import EditFeeModal from '../Modals/EditFeeModal';
// import DeleteFeeModal from '../Modals/DeleteFeeModal';
import { Link, NavLink } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import toast, { Toaster } from 'react-hot-toast';

import { IMG_URL } from '../../Config/env.js';
// import { addSocial, changesocialstate, getSocialData } from '../../axioscalls/admin';
import { addsociallinks, changesocialstate, getsociallinks } from '../../Actions/cms.js';



export default function Sociallist(props) {

    var history = useHistory();

    var location = useLocation();
    const { pathname, state } = location;
    const path = pathname.split("/")[1]
    console.log("pathname,stae", pathname, state, path)


    const Wallet_Details = useSelector((state) => state.wallet_detail)
    const [socialList, setSocialList] = useState([])
    const [allcheck, setAllcheck] = useState(false);



    const columns = [

        {
            key: "S.no",
            text: "S.NO",
            className: "NFT NAME",
            align: "left",
            sortable: true,
            cell: (record, index) => index + 1


        },
        {
            key: "website",
            text: "Website",
            className: "NFT NAME",
            align: "left",
            sortable: true,


        },

        {
            key: "link",
            text: "Website Link",
            className: "NFT NAME",
            align: "left",
            sortable: true,



        },
        {
            key: "img",
            text: "ICON",
            className: "NFT NAME",
            align: "left",
            sortable: false,
            cell: record =>
                <div><img src={`${IMG_URL}/socialimg/${record.img}`} width={50} /></div>
        },

        {
            key: "edit",
            text: "Edit",
            className: "NFT NAME",
            align: "left",
            sortable: false,
            cell: record =>
                <div><Link to={{ pathname: `/editsocial`, state: record }}><button className='orange_small_primary'>Edit</button></Link></div>


        },
        {
            key: "delete",
            text: "Delete",
            className: "NFT NAME",
            align: "left",
            sortable: false,
            cell: record =>
                <div><button className='orange_small_primary' onClick={() => deleteSocial(record, "delete")} >DELETE</button></div>


        },
        {
            key: "selected",
            text: "Select Social Links",
            className: "NFT NAME",
            align: "left",
            // sortable: true,
            cell: record =>
                <div><input className='view_checkbox' type="checkbox" checked={record.selected} onClick={() => setMaySent(record)} /></div>


        },





    ]


    const config = {
        page_size: 10,
        length_menu: [10, 20, 50],
        filename: "Emailtemplates",
        no_data_text: "No Email Templates found!",
        language: {
            length_menu: "Show _MENU_ result per page",
            filter: "Filter in records...",
            info: "Showing _START_ to _END_ of _TOTAL_ records",
            pagination: {
                first: "First",
                previous: "Previous",
                next: "Next",
                last: "Last",
            },
        },
        show_length_menu: false,
        show_filter: false,
        show_pagination: false,
        show_info: false,
    };

    const extraButtons = [
        {
            className: "btn btn-primary buttons-pdf",
            title: "Export TEst",
            children: [
                <span>
                    <i className="glyphicon glyphicon-print fa fa-print" aria-hidden="true"></i>
                </span>
            ],
            onClick: (event) => {
                console.log(event);
            },
        },
        {
            className: "btn btn-primary buttons-pdf",
            title: "Export TEst",
            children: [
                <span>
                    <i className="glyphicon glyphicon-print fa fa-print" aria-hidden="true"></i>
                </span>
            ],
            onClick: (event) => {
                console.log(event);
            },
            onDoubleClick: (event) => {
                console.log("doubleClick")
            }
        },
    ]
    useEffect(() => {
        getSocialList();
    }, [])


    const getSocialList = async () => {
        var resp = await getsociallinks();
        console.log("respsoclist", resp?.data?.data?.data);

        if (resp?.data?.data?.status) {
            setSocialList(resp?.data?.data?.data)
        }
    }
    const deleteSocial = async (record, filter) => {
        console.log("daaata", record, filter)
        var payload = {
            website: record.website,
            link: record.link,
            action: filter
        }
        var resp = await addsociallinks(payload);
        console.log("respsocsdsdsdsd", resp?.data?.data?.msg);
        if (resp?.data?.data?.status) {
            toast.success(resp?.data?.data?.msg)
            setTimeout(function () {
                getSocialList();
            }, 2000);

        }
        else return toast.error(resp.msg)
    }



    const setMaySent = async (data) => {
        if (data?.target) {
            if (socialList.length > 0) {
                console.log('kdhhgkdjkgd', data.target, data.target.checked)

                var resp = await changesocialstate({ all: data.target.checked });
                console.log("maysend", resp);
                if (resp?.data?.data?.status) {
                    setAllcheck(!allcheck)
                    console.log("resp success")
                    toast.success(resp?.data?.data?.msg)
                    setTimeout(() => {
                        getSocialList();
                    }, 1000);
                }
                else
                    toast.error(resp.msg)
            } else {
                toast.error("Subscribers are not yet")
            }
        } else {
            var payload = {
                website: data.website,
                selected: !data.selected
            }

            var resp = await changesocialstate(payload);
            console.log("maysend", resp);
            if (resp?.data?.data?.status) {
                // setAllcheck(false)
                console.log("resp success")
                toast.success(resp?.data?.data?.msg)
                setTimeout(() => {
                    getSocialList();
                }, 1000);
            }
            else
                toast.error(resp.msg)
        }

    }


    return (


        <>
            <Container fluid className='common_bg position-relative'>
                <div className='liner'></div>
                <Row>
                    <Col xl={2} lg={0} className='p-0 d-none d-xl-block'>
                        <Sidebar />
                    </Col>
                    <Col xl={10} lg={12}>
                        <Header title={'SOCIAL LINKS'} />
                        <div className='setting_tab_holder mt-5 pe-2'>
                            <Row>
                                <Col sm={6} className='d-flex gap-3 mb-3 mb-sm-0'>

                                </Col>
                                <Col sm={6} className='text-end'>
                                    <NavLink to='/addSocial' className='sidebar_links'>
                                        <button type='button' className='orange_small_primary'>Add</button>
                                    </NavLink>
                                </Col>
                            </Row>
                        </div>

                        <div className='common_page_scroller mt-4'>
                            <div className='exchange_table_holder dashboard_box rounded-3'>
                                <div className='rp_label mb-2 mt-3 ps-3'>
                                <div class="form-check">
  <input class="form-check-input" checked={allcheck} onChange={(e) => setMaySent(e)}  type="checkbox" value="" id="flexCheckDefault"/>
  <label class="form-check-label" for="flexCheckDefault">
   Select All
  </label></div>
                                    
                                    {/* <input type="checkbox" className='selectall ml-2' checked={allcheck} onChange={(e) => setMaySent(e)} /> */}
                                    </div>
                                    
                                <ReactDatatable
                                    config={config}
                                    records={socialList}
                                    columns={columns}
                                    extraButtons={extraButtons}


                                />
                            </div>
                        </div>
                    </Col>
                </Row>

            </Container>


        </>

    )

}


