import React, { useState } from 'react'
import { Col, Container, Row ,Button} from 'react-bootstrap'
import Header from '../../Components/Header'
import Sidebar from '../../Components/Sidebar'
import toast, { Toaster } from 'react-hot-toast';
import { useLocation, useHistory } from 'react-router-dom'
import { CKEditor } from 'ckeditor4-react';
import { isEmpty } from '../../common/common';
import { useEffect } from 'react';
import { addRegisteraccount } from '../../Actions/cms';

//Actions
// import { addFee } from '../Actions/FeeSettingsActions';

//validations
// import { addFeevalidation } from '../Validations/SettingsValidate';

function AddRegisteraccount() {
  const history = useHistory();

  // useEffect(() => {
  //   bsCustomFileInput.init()

  // }, [])

  const [ans, setAns] = useState("")
  console.log("gdfhdfbghh", ans)
  const initData = {
    "heading": "",
    "content": "",

  }

  const [formData, setFormData] = useState({});
  console.log("sdgsfrhb", formData);
  const [ValidateError, SetValidateError] = useState('')


  // var form=formData

  const Validation = () => {

    let ValidateError = {}


    if (!formData.heading) {
      ValidateError.heading = "Heading is required";
    }
    // if (!ans) {
    //   console.log("fdsgdfgdfgdfgdfg");
    //   ValidateError.answer = "answer is required";
    // }
    // if (!formData.file) {
    //   ValidateError.file = "file is required";
    // }

    SetValidateError(ValidateError)
    return ValidateError
  }

  const handlechange = async (e) => {

    e.preventDefault();
    const { id } = e.target
    console.log('dkfjkgjkdjjg', id)
    if (id === "file") {
      var files = e.target.files[0]
      var formdata = formData
      formdata.file = files
      console.log("files", files)
      setFormData({ ...formdata })
    } else {
      const { value, id } = e.target;
      var formdata = formData
      formdata[id] = value
      setFormData({ ...formdata })

      console.log("formdata", formData)
    }

  }

  const onEditorChange = (e) => {
    var content_text = e.editor.getData()
    // console.log("content_text",content_text =="");

      setAns(content_text)


    // setFormData({...formdata})
    // setFormData({...formData,...{'content' : content_text}})
    //   console.log("fff",formData,{...formData,...{'content' : content_text}})
  }

  const handleSubmit = async () => {
    //  var form = formData;
    //   form.faqcontent = faqcontent;
    //  console.log("handle form : ",form)
    //   setFormData(form)
    //   console.log("Form Data : ",formData)
    console.log("formdarta", formData)
    var errors = {};
    let value = Validation();

    if (!isEmpty(value)) {
      console.log("value", value);
      SetValidateError(value);
      toast.error("fix all validation");
    }
    else {
      if (Object.keys(errors).length == 0) {

        console.log("erro length", Object.keys(errors).length, formData)
           const formDatas = new FormData();

           formDatas.append("question",formData.heading)
           formDatas.append("answer",ans);
          //  formDatas.append("img",formData.file)

         console.log("payload",formDatas.get('heading'),formDatas.get('img'))
        // let formDatas = {
        //   "question": formData.heading,
        //   // "id":formData?._id,
        //   "answer": ans,
        //   "img": formData.file,

        // }
        let resp = await addRegisteraccount(formDatas);
        console.log("reereee", resp);
        if (resp?.data?.status) {
          toast.success(resp.data.data.message)

          history.push("/listRegisteraccount")


        }
        else return toast.error(resp.data.data.message)

      }
    }


  }


  return (
    <>
      <Container fluid className='common_bg position-relative'>
        <div className='liner'></div>
        <Row>
          <Col xl={2} lg={0} className='p-0 d-none d-xl-block'>
            <Sidebar />
          </Col>
          <Col xxl={7} xl={8} lg={12}>
            <Header title={'Add Fee'} />
            <div className='mt-5 profile_holder ps-lg-3'>
            <Button className='orange_small_primary mb-4' onClick={()=>history.goBack()}> <span>Back</span> </Button>

              <Row>
                <Col lg={7} className='mb-4'>
                  <div className='rp_singleinput_holder'>
                    <p className='rp_label mb-2'>Title</p>
                    <div className='rp_input_holder rounded-2 py-2 px-3 d-flex justify-content-start align-items-center gap-2'>
                      <input type='text' placeholder='Fee Name' className='rp_singleInput flex-grow-1' id='heading'
                        value={formData?.heading} onChange={(e) => handlechange(e)} />

                    </div>
                    <span className="text-danger f-12 d-block text-left">{ValidateError?.heading}</span><br />
                    <p className='rp_label mb-2'>Description</p>
                    {<CKEditor
                      initData={ans}
                      onChange={(e) => onEditorChange(e)}
                    // onChange={onEditorChange}
                    />}
                  </div>
                  <br/>
                  {/* <input type="file" id="file" onChange={(e) => handlechange(e)} />
                  <br/><br/>
                  {formData?.file && <img className='mb-2' src={URL.createObjectURL(formData?.file)} width={90} />} */}
                  
                </Col>


              </Row>
              <button className='orange_small_primary' onClick={() => handleSubmit()}>Submit</button>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  )
}

export default AddRegisteraccount